import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ProductProps } from "../../types/Types";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import WishlistButton from "../../components/wishlist button/WishlistButton";
import WishlistTotal from "../../components/wishlist button/WishlistTotal";
import Stars from '../../components/stars/Stars';
import LoaderCopy from "../../components/loader/LoaderCopy";


// styles and icons
import "./product.css"
import Icon from "../icon/Icon";

const Products = () => {
    const [products, setProducts] = useState<ProductProps[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // calling the products active api
        axios
        .get(`${process.env.REACT_APP_Base_url}/products/active`)
        .then((res) => {
            //console.log(res.data);
            if (res.data.status === 200) {
            setProducts(res.data.product);
            setIsLoading(false);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }, []);

    // Function to format price
    const formatPrice = (price: number) => {
        // Format the number to two decimal places
        const formattedPrice = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(price);

        // Prepend the cedi symbol
        return `¢${formattedPrice}`;
    };

    return (
        <>
            <section className="featured spad">
                <div className="container">
                    {isLoading ? (
                        <LoaderCopy loading={isLoading} />
                    ) : (
                        <div className="row row-product">
                            <div className="col-lg-12">
                                <div className="section-title">
                                    <h2>Featured Products</h2>
                                </div>
                            </div>
                            {products.length === 0 ? (
                                <div className="col-lg-12">
                                    <p>No products found!</p>
                                </div>
                            ) : (
                                products.map((product: ProductProps) => {
                                    return (
                                        <div className="col-6 col-sm-4 col-md-3 col-mds-5 col-product" key={product.id}>
                                            <div className="product-item">
                                                <div className="row-custom product-multiple-image">
                                                    <div className="img-product-container">
                                                        <Link to={`/details`} state={{ data: product.id }}>
                                                            <img
                                                                src={product.main_image}
                                                                alt={product.product_name}
                                                                className="img-fluid img-product ls-is-cached lazyloaded"
                                                                loading="lazy"
                                                            />
                                                            <div className="thumbnail hover">
                                                                <img
                                                                    src={product.product_images[0]}
                                                                    alt={product.product_name}
                                                                    className="img-fluid img-product img-second ls-is-cached lazyloaded"
                                                                    loading="lazy"
                                                                />
                                                            </div>
                                                        </Link>
                                                        <div className="actions">
                                                            <ul>
                                                                <li>
                                                                    <WishlistButton productId={product.id} />
                                                                </li>
                                                                <li>
                                                                    <a href="/cart" className="tooltip-container">
                                                                        <Icon name="IoCartOutline" size="1.5em" /> 
                                                                        <div className="tooltip">
                                                                            <span className="tooltip-text">Add to Cart</span>
                                                                            <span className="tooltip-arrow"></span>
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                                <Link to={`/details`} state={{ data: product.id }}>
                                                                    <li>
                                                                        <a href="/details" className="tooltip-container">
                                                                            <Icon name="FaEye" size="1.5em" /> 
                                                                            <div className="tooltip">
                                                                                <span className="tooltip-text">View Details</span>
                                                                                <span className="tooltip-arrow"></span>
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                </Link>
                                                            </ul>
                                                        </div>
                                                        <div className="sub-actions">
                                                            <ul>
                                                                <li>
                                                                    <a className="item-option btn-add-remove-wishlist">
                                                                        <WishlistButton productId={product.id} />
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="/cart" className="item-option tooltip-container">
                                                                        <Icon name="IoCartOutline" size="1.5em" /> 
                                                                        <div className="tooltip">
                                                                            <span className="tooltip-text">Add to Cart</span>
                                                                            <span className="tooltip-arrow"></span>
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row-custom item-details">
                                                    <h3 className="product-title">
                                                        <Link to={`/details`} state={{ data: product.id }}>
                                                            {product.product_name}
                                                        </Link>
                                                    </h3>
                                                    <p className="product-user text-truncate">
                                                        <a href="/profile">{product.company_name}</a>
                                                    </p>
                                                    <div className="product-item-rating">
                                                        <div className="rating">
                                                            <Stars productId={product.id} />
                                                        </div>
                                                        <WishlistTotal productId={product.id} />
                                                    </div>
                                                    <Link to={`/details`} state={{ data: product.id }}>
                                                        <div className="item-meta">
                                                            <span className="price">{formatPrice(product.product_price)}</span>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    )}
                </div>
            </section>
        </>
    );
};

export default Products;