import React, { useState, useRef, useEffect } from 'react';
import './vendor.css';

const ScrollUpButton = () => {
    const progressPathRef = useRef(null);
    const [scrollPercent, setScrollPercent] = useState(0);
    const [isVisible, setIsVisible] = useState(false);

    // handles scroll to top
    useEffect(() => {
            const handleScroll = () => {
                const totalScroll = document.documentElement.scrollHeight - window.innerHeight;
                const scrollPosition = window.scrollY;
                const scrollPercentage = (scrollPosition / totalScroll) * 100;
                setScrollPercent(scrollPercentage);

            if (window.pageYOffset > 300) { // Adjust the scroll threshold as needed
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
            };

            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
    }, []);

    useEffect(() => {
        const pathLength = progressPathRef.current.getTotalLength();
        progressPathRef.current.style.strokeDasharray = pathLength;
        progressPathRef.current.style.strokeDashoffset = pathLength - (scrollPercent / 100) * pathLength;
    }, [scrollPercent]);

    const scrollToTop = () => {
        window.scrollTo({
        top: 0,
        behavior: 'smooth'
        });
    };

    return (
        <a
            href="javascript:void(0)"
            className={`scrollup ${isVisible ? 'visible' : 'hidden'}`}
            onClick={scrollToTop}
            style={{ display: 'inline' }}
        >
            <div className="progress-section">
                <div className="progress-page has-tooltip is-active is-visible">
                    <div className="scrolltotop is-active is-visible">
                        <div className="arrow-top"></div>
                        <div className="arrow-top-line"></div>
                            <span className="tooltip">Back to Top</span>
                        </div>
                        <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                            <path
                                ref={progressPathRef}
                                className="progress-path"
                                d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                            ></path>
                        </svg>
                    </div>
                </div>
        </a>
    );
};

export default ScrollUpButton;