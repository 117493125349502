import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";

const ProfileCompletion = () => {
    const [companyName, setCompanyName] = useState('');
    const [businessCategory, setBusinessCategory] = useState('');
    const [shopDescription, setShopDescription] = useState('');
    const [location, setLocation] = useState('');
    const [contact, setContact] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const navigate = useNavigate();
    const token = sessionStorage.getItem("authToken");
    console.log("Token:", token);

    

    // Handle form submission to update shop details
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage(''); // Reset error message
        setSuccessMessage(''); // Reset success message

        // Validate form fields
        if (!companyName || !location || !shopDescription || !businessCategory || !contact) {
            setErrorMessage("All fields are required");
            setLoading(false);
            return;
        }

        try {
            // Send relevant shop fields to the backend
            const response = await axios.post(
            `${process.env.REACT_APP_Base_url}/sellers/update_shop`, 
            {
                company_name: companyName,
                location: location,
                shop_description: shopDescription,
                business_category: businessCategory,
                contact: contact,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                setSuccessMessage('Seller profile updated successfully!');
                navigate("/seller-dashboard");
            } 
        } catch (error) {
            console.error('Error updating seller profile:', error);
            setErrorMessage("Failed to update profile. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Header />

            <div id="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="nav-breadcrumb" aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Complete Your Profile</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="auth-container">
                        <div className="auth-box">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="title">Complete Your Profile</h1>
                                    <form onSubmit={handleSubmit} id="form_validate" className="validate_terms">
                                        
                                    <div id="result-register">
                                        <div className="m-b-15">
                                            {/* Show alert only if there is a success or error message */}
                                            {(errorMessage || successMessage) && (
                                                <div className={`alert ${errorMessage ? "alert-danger" : "alert-success"} alert-message`}>
                                                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                                                    {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input 
                                            type="text" 
                                            name="companyName" 
                                            className="form-control auth-form-input" 
                                            placeholder="What's the name of your business?" 
                                            value={companyName}
                                            onChange={(e) => setCompanyName(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input 
                                            type="text" 
                                            name="location" 
                                            className="form-control auth-form-input" 
                                            placeholder="Where is your business located?" 
                                            value={location} 
                                            onChange={(e) => setLocation(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input 
                                            type="contact" 
                                            name="contact"
                                            pattern="^0\d{9}$" 
                                            className="form-control auth-form-input" 
                                            placeholder="Enter your mobile number."
                                            value={contact} 
                                            onChange={(e) => setContact(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">                                             
                                        <select
                                            name="businessCategory"
                                            className="form-control auth-form-input"
                                            placeholder="Which category does your business fall?" 
                                            required
                                            value={businessCategory}
                                            onChange={(e) => setBusinessCategory(e.target.value)}
                                        >
                                            <option value="">Choose...</option>
                                            <option value="General Goods">General Goods</option>
                                            <option value="Consumer Electronics">Consumer Electronics</option>
                                            <option value="Fashion & Apparel">Fashion & Apparel</option>
                                            <option value="Beauty & Personal Care">Beauty & Personal Care</option>
                                            <option value="Entertainment">Entertainment</option>
                                            <option value="Household Goods">Household Goods</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <textarea
                                            type="text" 
                                            name="shopDescription" 
                                            className="form-control auth-form-input"
                                            placeholder="Tell us about your business."
                                            value={shopDescription}
                                            onChange={(e) => setShopDescription(e.target.value)}
                                            required
                                        />
                                    </div>
                                        
                                        <div className="form-group">
                                            <button className="btn btn-custom btn-block" type="submit" disabled={loading}>
                                                {loading ? "Updating..." : "Complete Profile"}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default ProfileCompletion;