import React, { useState } from "react";
import axios from "axios";
import Footer from "../../components/footer/Footer";
import HeaderCopy from "../../components/header/HeaderCopy";
import Icon from "../../components/icon/Icon";

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        terms: false,
    });
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    // Handle form field changes
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage('');
        setSuccessMessage('');

        // Validate required fields
        if (!formData.name || !formData.email || !formData.message || !formData.terms) {
            setErrorMessage('Please fill in all required fields and agree to the terms.');
            setLoading(false);
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_Base_url}/admin/contact_admin`, formData);
            if (response.status === 200) {
                setSuccessMessage('Your message has been sent successfully!');
                // Clear form fields
                setFormData({
                    name: '',
                    email: '',
                    message: '',
                    terms: false,
                });
            }
        } catch (error) {
            console.error('Error sending message:', error);
            setErrorMessage('There was an error sending your message. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <HeaderCopy />

            <div id="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="nav-breadcrumb" aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Contact</li>
                                </ol>
                            </nav>
                            <h1 className="page-title">Contact</h1>
                        </div>
                        <div className="col-12">
                            <div className="page-contact">
                                <div className="row contact-text">
                                    <div className="col-12"></div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <h2 className="contact-leave-message"></h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-12 order-1 order-lg-0">
                                        <form id="form_validate" className="validate_terms" onSubmit={handleSubmit}>                                
                                            <div className="form-group">
                                                <input 
                                                    type="text" 
                                                    className="form-control form-input" 
                                                    name="name" 
                                                    placeholder="Name" 
                                                    maxLength={199} 
                                                    minLength={1} 
                                                    pattern=".*\S+.*" 
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required 
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input 
                                                    type="email" 
                                                    className="form-control form-input" 
                                                    name="email" 
                                                    maxLength={199} 
                                                    placeholder="Email Address" 
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required 
                                                />
                                            </div>
                                            <div className="form-group">
                                                <textarea 
                                                    className="form-control form-input form-textarea" 
                                                    name="message" 
                                                    placeholder="Message" 
                                                    maxLength={4970} 
                                                    minLength={5} 
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                    required
                                                ></textarea>
                                            </div>
                                            <div className="form-group">
                                                <div className="custom-control custom-checkbox custom-control-validate-input">
                                                    <input 
                                                        type="checkbox" 
                                                        className="custom-control-input" 
                                                        name="terms" 
                                                        id="checkbox_terms"
                                                        checked={formData.terms}
                                                        onChange={handleChange}
                                                        required 
                                                    />
                                                    <label htmlFor="checkbox_terms" className="custom-control-label" style={{ color: 'black' }}>
                                                        I have read and agree to the&nbsp;
                                                        <a href="/terms" className="link-terms" target="_blank"><strong>Terms &amp; Conditions</strong></a>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-group"></div>
                                            <div className="form-group">
                                                <button 
                                                    type="submit" 
                                                    className="btn btn-md btn-custom" 
                                                    disabled={loading}
                                                >
                                                    {loading ? 'Submitting...' : 'Submit'}
                                                </button>
                                            </div>
                                            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                                            {successMessage && <div className="alert alert-success">{successMessage}</div>}
                                        </form>
                                    </div>

                                    <div className="col-md-6 col-12 order-0 order-lg-1 contact-right">
                                        <div className="col-12 contact-item">
                                            <Icon name="FaPhone" color="#777" style={{ textAlign: 'left', width: '40px' }} aria-hidden="true" />
                                            +233 (542) 059-074  / +233 (506) 583-175
                                        </div>
                                        <div className="col-12 contact-item">
                                            <Icon name="MdEmail" color="#777" style={{ textAlign: 'left', width: '40px' }} aria-hidden="true" />
                                            fgyamfi15@gmail.com
                                        </div>
                                        <div className="col-12 contact-item">
                                            <Icon name="IoLocation" color="#777" style={{ textAlign: 'left', width: '40px' }} aria-hidden="true" />
                                            fgyamfi15@gmail.com
                                        </div>
                                        <div className="col-sm-12 contact-social">
                                            <ul>
                                                <li>
                                                    <a href="#" className="twitter" target="_blank" rel="noopener noreferrer">
                                                        <Icon name="FaXTwitter" className="contact-icon-x" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="instagram" target="_blank" rel="noopener noreferrer">
                                                        <Icon name="FaInstagram" className="contact-icon-ig" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="facebook" target="_blank" rel="noopener noreferrer">
                                                        <Icon name="FaFacebookF" className="contact-icon-fb" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://wa.me/233542059074?text=Hello%20Vincee" className="whatsapp" target="_blank" rel="noopener noreferrer">
                                                        <Icon name="FaWhatsapp" className="contact-icon-wa" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="contact-map-container">
                            <iframe
                                id="contact_iframe"
                                src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=fgyamfi15@gmail.com&amp;ie=UTF8&amp;t=&amp;z=8&amp;iwloc=B&amp;output=embed&amp;disableDefaultUI=true"
                                frameborder="0"
                                scrolling="no"
                                marginheight="0"
                                marginwidth="0"
                                allowfullscreen=""
                                loading="lazy"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
      
            <Footer />
        </>
    );
};

export default Contact;