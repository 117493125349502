import React, { useState, useEffect } from "react";
import { CategoryProps } from "../../types/Types";
import { Link } from 'react-router-dom';
import axios from "axios";
import payment from "../../assets/img/payment-item.png";
import Icon from "../icon/Icon";
import NewsletterFooter from "../newsletter/NewsletterFooter";

const Footer = () => {
    const [categories, setCategories] = useState<[]>([]);

    // calling the categories api
    useEffect(() => {
        axios
        .get(`${process.env.REACT_APP_Base_url}/utils/get_product_categories`)
        .then((res) => {
            if (res.data.status === 200) {
            setCategories(res.data.product_categories);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }, []);

    return (
        <>
            <footer id="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="footer-top">
                                <div className="row">
                                    <div className="col-12 col-lg-4 footer-widget">
                                        <div className="row-custom">
                                            <div className="footer-logo">
                                                <a href="/">
                                                    <img 
                                                        src="https://res.cloudinary.com/dfexgcu3h/image/upload/v1729003974/Vincee/logo/new_1_adjed1.png" 
                                                        alt="logo" 
                                                        className="zoomable"
                                                        width={160}
                                                        height={60}
                                                        style={{ transition: "transform .3s ease-in-out" }}
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="row-custom">
                                            <div className="footer-about">
                                                <p>NEW MARKET is an online store which gives you variety of brands to shop quality products at very affordable prices.&nbsp;</p>
                                            </div>
                                            <div className="footer-social-links">
                                                <ul>
                                                    <li>
                                                        <a href="#" target="_blank" title="Facebook">
                                                            <Icon name="FaFacebookF" size="1em" color="#365899" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" target="_blank" title="Twitter">
                                                            <Icon name="FaXTwitter" size="1em" color="#000000" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" target="_blank" title="Instagram">
                                                            <Icon name="FaInstagram" size="1em" color="#F06292" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://chat.whatsapp.com/CLtGudvPCh4HajcG5uRpQR" target="_blank" title="Whatsapp">
                                                            <Icon name="FaWhatsapp" size="1em" color="#008b8b" />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-8">
                                        <div className="row">
                                            <div className="col-12 col-lg-7">
                                                <div className="row">
                                                    <div className="col-12 col-sm-6 col-lg-6 footer-widget">
                                                        <div className="nav-footer">
                                                            <div className="row-custom">
                                                                <h4 className="footer-title">Categories</h4>
                                                            </div>
                                                            <div className="row-custom">
                                                                <ul>                            
                                                                    {categories &&
                                                                        categories.map((category: CategoryProps) => (
                                                                            <li key={category.id}>
                                                                                <Link to="/product-category" state={{ data: category.product_category_name }}>
                                                                                    {category.product_category_name}
                                                                                </Link>
                                                                            </li>
                                                                        ))
                                                                    }  
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-6 footer-widget">
                                                        <div className="nav-footer">
                                                            <div className="row-custom">
                                                                <h4 className="footer-title">Quick Links</h4>
                                                            </div>
                                                            <div className="row-custom">
                                                                <ul>
                                                                    <li>
                                                                        <a href="/">
                                                                            Home
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="/about">
                                                                            About Us
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="/shops">
                                                                            Shops
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="/help">
                                                                            Help Center
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="nav-footer">
                                                            <div className="row-custom m-t-15">
                                                                <h4 className="footer-title">Information</h4>
                                                            </div>
                                                            <div className="row-custom">
                                                                <ul>
                                                                    <li>
                                                                        <a href="/terms">
                                                                            Terms &amp; Conditions
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="/privacy">
                                                                            Privacy Policy
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-5">
                                                <NewsletterFooter />
                                                <div className="footer-payment-icons">
                                                    <img 
                                                        src={payment} 
                                                        alt="payment" 
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="footer-bottom">
                                <div className="row">
                                    <div className="col-lg-4 col-md-12">
                                        <div className="copyright">
                                            Copyright 2024 New Market - All Rights Reserved.
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-12">
                                        <ul className="nav-footer-bottom"></ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;