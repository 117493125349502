import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { SectionProps, ProductProps } from "../../types/Types"; // importing types
import WishlistButton from "../../components/wishlist button/WishlistButton"; // add/remove wishlist button
import Stars from '../../components/stars/Stars'; // star rating
import WishlistTotal from "../../components/wishlist button/WishlistTotal"; // total items in wishlist
import LoaderCopy from "../../components/loader/LoaderCopy"; // loading spinner

// styles and icons
import Icon from "../icon/Icon";
import "./category.css";


const Categories = () => {
    const [sections, setSections] = useState<SectionProps[]>([]);
    const [selectedSection, setSelectedSection] = useState<string | null>("Savings Spotlight"); // default on savings spotlight
    const [itemSection, setItemSection] = useState<string | null>("Savings Spotlight");
    const [products, setProducts] = useState<ProductProps[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    // calling the product section api
    useEffect(() => {
        
        axios
        .get(`${process.env.REACT_APP_Base_url}/utils/get_product_sections`)
        .then((res) => {
            //console.log(res.data);
            if (res.data.status === 200) {
            setSections(res.data.product_sections);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }, []);

    // calling the  active products by section api
    useEffect(() => {
        if (itemSection) {
            setIsLoading(true);
            axios
                .post(
                    `${process.env.REACT_APP_Base_url}/products/get_product_by_section`,
                    {
                        product_section: itemSection,
                    }
                )
                .then((res) => {
                    //console.log(res.data);
                    if (res.data.status === 200) {
                        setProducts(res.data.product);
                    } else {
                    // Set products to an empty array when no products are available
                    setProducts([]);
                    }
                })
                .catch((err) => {
                    console.log("Error fetching products by section:", err);
                })
                .finally(() => {
                    setIsLoading(false); // Hide loading indicator after fetching
                });
        } else {
            // Set products to an empty array when no category is selected
            setProducts([]);
            setIsLoading(false);
        }
    }, [itemSection]);

    // handle section dropdown and select
    const handleSectionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedSection(event.target.value);
        setItemSection(event.target.value);
    };

    // Function to format price
    const formatPrice = (price: number) => {
        // Format the number to two decimal places
        const formattedPrice = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(price);

        // Prepend the cedi symbol
        return `¢${formattedPrice}`;
    };

    return (
        <>
            <div className="bycats">
                <div className="container">
                    <div className="wrap">
                        <div className="heading sort-list tabs">
                            <span className="grey-color">in</span>
                            <div className="wrap">
                                <div className="custom-arrow">
                                    <select 
                                        id="sectionsDropdown" 
                                        className="custom-dropdown"
                                        onChange={handleSectionChange} 
                                        value={itemSection || ""}
                                    >
                                        {sections.map((section: SectionProps) => (
                                            <option key={section.id} value={section.product_section_name}>
                                                {section.product_section_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="product spad">
                <div className="container">
                    {isLoading ? (
                        <LoaderCopy loading={isLoading} />
                    ) : (
                        <div className="row row-product">
                            {products.length === 0 ? (
                                <div className="col-lg-12">
                                    <p>No products found!</p>
                                </div>
                            ) : (
                                products.map((product: ProductProps) => {
                                    return (
                                        <div className="col-6 col-sm-4 col-md-3 col-mds-5 col-product" key={product.id}>
                                            <div className="product-item">
                                                <div className="row-custom product-multiple-image">
                                                    <div className="img-product-container">
                                                        <Link to={`/details`} state={{ data: product.id }}>
                                                            <img
                                                                src={product.main_image}
                                                                alt={product.product_name}
                                                                className="img-fluid img-product ls-is-cached lazyloaded"
                                                                loading="lazy"
                                                            />
                                                            <div className="thumbnail hover">
                                                                <img
                                                                    src={product.product_images[0]}
                                                                    alt={product.product_name}
                                                                    className="img-fluid img-product img-second ls-is-cached lazyloaded"
                                                                    loading="lazy"
                                                                />
                                                            </div>
                                                        </Link>
                                                        <div className="actions">
                                                            <ul>
                                                                <li>
                                                                    <WishlistButton productId={product.id} />
                                                                </li>
                                                                <li>
                                                                    <a href="/cart" className="tooltip-container">
                                                                        <Icon name="IoCartOutline" size="1.5em" />
                                                                        <div className="tooltip">
                                                                            <span className="tooltip-text">Add to Cart</span>
                                                                            <span className="tooltip-arrow"></span>
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                                <Link to={`/details`} state={{ data: product.id }}>
                                                                    <li>
                                                                        <a href="/details" className="tooltip-container">
                                                                            <Icon name="FaEye" size="1.5em" />
                                                                            <div className="tooltip">
                                                                                <span className="tooltip-text">View Details</span>
                                                                                <span className="tooltip-arrow"></span>
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                </Link>
                                                            </ul>
                                                        </div>
                                                        <div className="sub-actions">
                                                            <ul>
                                                                <li>
                                                                    <a className="item-option btn-add-remove-wishlist">
                                                                        <WishlistButton productId={product.id} />
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="/cart" className="item-option tooltip-container">
                                                                        <Icon name="IoCartOutline" size="1.5em" />
                                                                        <div className="tooltip">
                                                                            <span className="tooltip-text">Add to Cart</span>
                                                                            <span className="tooltip-arrow"></span>
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row-custom item-details">
                                                    <h3 className="product-title">
                                                        <Link to={`/details`} state={{ data: product.id }}>
                                                            {product.product_name}
                                                        </Link>
                                                    </h3>
                                                    <p className="product-user text-truncate">
                                                        <a href="#">{product.company_name}</a>
                                                    </p>
                                                    <div className="product-item-rating">
                                                        <div className="rating">
                                                            <Stars productId={product.id} />
                                                        </div>
                                                        <WishlistTotal productId={product.id} />
                                                    </div>
                                                    <Link to={`/details`} state={{ data: product.id }}>
                                                        <div className="item-meta">
                                                            <span className="price">{formatPrice(product.product_price)}</span>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div> 
                                    );
                                })
                            )}
                        </div>
                    )}
                </div>
            </section>
        </>
    );
};

export default Categories;