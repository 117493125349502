import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import 'overlayscrollbars/styles/overlayscrollbars.css';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { SectionProps } from "../../types/Types";

const ProductSectionFilter = () => {
    const [sections, setSections] = useState<SectionProps[]>([]);

    // calling the sections api
    useEffect(() => {
        axios
        .get(`${process.env.REACT_APP_Base_url}/utils/get_product_sections`)
        .then((res) => {
            //console.log(res.data);
            if (res.data.status === 200) {
            setSections(res.data.product_sections);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }, []);

    return (
        <div className="filter-item">
            <h4 className="title">Section</h4>
            <div className="filter-list-container">
                <OverlayScrollbarsComponent
                    defer
                    options={{
                        scrollbars: { autoHide: "leave", theme: "os-theme-dark" },
                    }}
                    style={{ height: '210px' }}
                >
                    <ul className="filter-list filter-custom-scrollbar filter-list-categories">
                        {sections &&
                            sections.map((section: SectionProps) => (
                                <li key={section.id}>
                                    <Link to="/product-section" state={{ data: section.product_section_name }}>
                                        {section.product_section_name}
                                    </Link>
                                </li>
                            ))
                        }                                                 
                    </ul>
                </OverlayScrollbarsComponent>
            </div>
        </div>                    
    )
};

export default ProductSectionFilter;