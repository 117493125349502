import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import HeaderCopy from "../../components/header/HeaderCopy";
import Footer from "../../components/footer/Footer";
import { CategoryProps, ProductProps } from "../../types/Types";
import WishlistButton from "../../components/wishlist button/WishlistButton";
import WishlistTotal from "../../components/wishlist button/WishlistTotal";
import Stars from '../../components/stars/Stars';
import PriceFilter, { PriceFilterRef } from '../../components/product filter/PriceFilter';


const Search = () => {
  const location = useLocation();
  const initialProducts: ProductProps[] = location.state?.data || [];
  const searchKeyword = location.state?.keyword || '';
  const [products, setProducts] = useState<ProductProps[]>(initialProducts);
  const [sections, setSections] = useState<CategoryProps[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [sortCriteria, setSortCriteria] = useState<string>('most_recent');
  const [minPrice, setMinPrice] = useState<number | null>(null);
  const [maxPrice, setMaxPrice] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const priceFilterRef = useRef<PriceFilterRef>(null);
  

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_Base_url}/utils/get_product_sections`)
      .then((res) => {
        if (res.data.status === 200) {
          setSections(res.data.product_sections);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    // Fetch products for the current page and sort criteria
    const fetchProducts = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_Base_url}/products/search_and_filter`, {
        product_name: searchKeyword,
        page: currentPage,
        sort: sortCriteria,
        minPrice: minPrice !== null ? minPrice : undefined,
        maxPrice: maxPrice !== null ? maxPrice : undefined,
      });
      if (response.data.status === 200) {
          setProducts(response.data.product);
          setTotalPages(response.data.total_pages);
        } else {
          setProducts([]); // Set products to empty array if no products found
          setTotalPages(1); // Reset total pages to 1
        }
      } catch (err) {
        console.error(err);
      } finally {
      setIsLoading(false);
      }
    };

    fetchProducts();
  }, [currentPage, searchKeyword, sortCriteria, minPrice, maxPrice]);

  // Function to handle pagination
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  // Function to sort products by highest and lowest prices, most recent and rating
  const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSortCriteria(e.target.value);
    setCurrentPage(1); // Reset to first page on sort change
  };

  // Function to handle the filtering of products by price range
  const handleFilter = (min: number, max: number) => {
    setMinPrice(min);
    setMaxPrice(max);
    setCurrentPage(1); // Reset to first page after filtering
  };

  // Function to clear the minimum price input
  const handleResetMinPrice = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault(); // Prevent default behavior
    setMinPrice(null); // Reset minPrice to null
    priceFilterRef.current?.resetMinPrice(); // Call prop function to reset in PriceFilter component
    setCurrentPage(1); // Reset page to 1 to fetch products again
  };

  // Function to clear the maximum price input
  const handleResetMaxPrice = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault(); // Prevent default behavior
    setMaxPrice(null); // Reset maxPrice to null
    priceFilterRef.current?.resetMaxPrice(); // Call prop function to reset in PriceFilter component
    setCurrentPage(1); // Reset page to 1 to fetch products again
  };

  // Function to format price
  const formatPrice = (price: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'GHS', // Change to 'GHS' for Ghanaian cedis
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price);
  };

  return (
    <>
      <HeaderCopy />

      {/* Searched Product Section Start */}
      <div id="wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="nav-breadcrumb" aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-products">
                  <li className="breadcrumb-item"><a href="/">Home</a></li>
                  <li className="breadcrumb-item active">Products</li>
                </ol>
              </nav>
            </div>
          </div>
          <input type="hidden" name="search" value="" />
          <div className="row">
            <div className="col-12 product-list-header">
              <h1 className="page-title product-list-title">Products</h1>
              <div className="product-sort-by">
                <span className="span-sort-by">Sort By:</span>
                <div className="sort-select">
                  <select 
                    id="select_sort_items" 
                    className="custom-select"
                    value={sortCriteria}
                    onChange={handleSortChange}
                    >
                    <option value="most_recent">Most Recent</option>
                    <option value="lowest_price">Lowest Price</option>
                    <option value="highest_price">Highest Price</option>
                    <option value="rating">Highest Rating</option>
                  </select>
                </div>
              </div>
              <button 
                className="btn btn-filter-products-mobile" 
                type="button" 
                data-toggle="collapse" 
                data-target="#collapseFilters" 
                aria-expanded="false" 
                aria-controls="collapseFilters"
                >
                <i className="mdi mdi-filter"></i>&nbsp;Filter Products                
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-3 col-sidebar-products">
              <div id="collapseFilters" className="product-filters">
                <div className="filter-item">
                  <h4 className="title">Section</h4>
                  <div className="filter-list-container">
                    <ul className="filter-list filter-custom-scrollbar filter-list-categories os-host os-theme-dark os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-scrollbar-vertical-hidden os-host-transition">
                      <div className="os-resize-observer-host">
                        <div className="os-resize-observer observed" style={{ left: '0px', right: 'auto' }}></div>
                      </div>
                      <div className="os-size-auto-observer" style={{ height: 'calc(100% + 1px)', float: 'left' }}>
                        <div className="os-resize-observer observed"></div>
                      </div>
                      <div className="os-content-glue" style={{ margin: '0px 0px -10px', height: 'auto', width: 'auto' }}></div>
                      <div className="os-padding">
                        <div className="os-viewport os-viewport-native-scrollbars-invisible" style={{ right: '0px', bottom: '0px' }}>
                          <div className="os-content" style={{ padding: '0px 0px 10px', height: 'auto', width: '100%' }}>
                            <li>
                              <a href="/men?search=berk">MEN</a>
                            </li>
                            <li>
                              <a href="/women?search=berk">WOMEN</a>
                            </li>
                            <li>
                              <a href="/brands?search=berk">BRANDS</a>
                            </li>
                            <li>
                              <a href="/unisex?search=berk">UNISEX</a>
                            </li>
                            <li>
                              <a href="/electronic-wearables?search=berk">ELECTRONIC WEARABLES</a>
                            </li>
                          </div>
                        </div>
                      </div>
                      <div className="os-scrollbar os-scrollbar-horizontal os-scrollbar-unusable">
                        <div className="os-scrollbar-track os-scrollbar-track-off">
                          <div className="os-scrollbar-handle" style={{ width: '100%', transform: 'translate(0px, 0px)' }}></div>
                        </div>
                      </div>
                      <div className="os-scrollbar os-scrollbar-vertical os-scrollbar-unusable">
                        <div className="os-scrollbar-track os-scrollbar-track-off">
                          <div className="os-scrollbar-handle" style={{ height: '100%', transform: 'translate(0px, 0px)' }}></div>
                        </div>
                      </div>
                      <div className="os-scrollbar-corner"></div>
                    </ul>
                  </div>
                </div>
                {/* Render PriceFilter component and pass handleFilter function */}
                <PriceFilter ref={priceFilterRef} isLoading={isLoading} onFilter={handleFilter} />
              </div>
              <div className="row-custom"></div>
            </div>
                
            <div className="col-12 col-md-9 col-content-products">
              
              <div className="filter-reset-tag-container">
                <div className="filter-reset-tag">
                  <div className="left">
                    <a href="/shop" rel="nofollow"><i className="mdi mdi-close"></i></a>
                  </div>
                  <div className="right">
                    <span className="reset-tag-title">Search</span>
                    <span>{searchKeyword}</span>
                  </div>
                </div>
                {minPrice !== null && (
                  <div className="filter-reset-tag">
                    <div className="left">
                      <a href="#" onClick={handleResetMinPrice}><i className="mdi mdi-close"></i></a>
                    </div>
                    <div className="right">
                      <span className="reset-tag-title">Price(¢)</span>
                      <span>Min: {minPrice}</span>
                    </div>
                  </div>
                )}
                {maxPrice !== null && (
                  <div className="filter-reset-tag">
                    <div className="left">
                      <a href="#" onClick={handleResetMaxPrice}><i className="mdi mdi-close"></i></a>
                    </div>
                    <div className="right">
                      <span className="reset-tag-title">Price(¢)</span>
                      <span>Max: {maxPrice}</span>
                    </div>
                  </div>
                )}
                <a href="/shop" className="link-reset-filters" rel="nofollow">Reset Filters</a>
              </div>
              
            <div className="product-list-content">
              <div className="row row-product">
              {products.length === 0 ? (
                <div className="col-12">
                  <p className="no-records-found">No products found!</p>
                </div>
              ) : (
              products.map((product: ProductProps) => {
                return (
                  <div className="col-6 col-sm-4 col-md-4 col-lg-3 col-product" key={product.id}>
                    <div className="product-item">        
                        <div className="row-custom product-multiple-image">
                          <div className="img-product-container">
                            <Link to={`/details`} state={{ data: product.id }}>
                            <img
                              src={product.product_image}
                              alt={product.product_name}
                              className="img-fluid img-product ls-is-cached lazyloaded"
                              loading="lazy"
                            />
                            <div className="thumbnail hover">
                            <img
                              src={product.product_image}
                              alt={product.product_name}
                              className="img-fluid img-product img-second ls-is-cached lazyloaded"
                              loading="lazy"
                            />
                            </div>
                            </Link>
                            <div className="actions">
                              <ul>
                                <li>
                                  <WishlistButton productId={product.id} />
                                </li>
                                <li>
                                  <a href="/cart" className="tooltip-container">
                                   <i className="mdi mdi-cart-outline"></i> 
                                    <div className="tooltip">
                                      <span className="tooltip-text">Add to Cart</span>
                                      <span className="tooltip-arrow"></span>
                                    </div>
                                  </a>
                                </li>
                                <Link to={`/details`} state={{ data: product.id }}>
                                <li>
                                  <a href="/details" className="tooltip-container">
                                   <i className="mdi mdi-eye-outline"></i> 
                                    <div className="tooltip">
                                      <span className="tooltip-text">View Details</span>
                                      <span className="tooltip-arrow"></span>
                                    </div>
                                  </a>
                                </li>
                                </Link>
                              </ul>
                            </div>
                            <div className="sub-actions">
                              <ul>
                                <li>
                                  <a className="item-option btn-add-remove-wishlist">
                                    <WishlistButton productId={product.id} />
                                  </a>
                                </li>
                                <li>
                                  <a href="/cart" className="item-option tooltip-container">
                                    <i className="mdi mdi-cart-outline"></i> 
                                    <div className="tooltip">
                                      <span className="tooltip-text">Add to Cart</span>
                                      <span className="tooltip-arrow"></span>
                                    </div>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="row-custom item-details">
                          <h3 className="product-title">
                            <Link to={`/details`} state={{ data: product.id }}>
                              {product.product_name}
                            </Link>
                          </h3>
                          <p className="product-user text-truncate">
                            <a href="#">{product.company_name}</a>
                          </p>
                          <div className="product-item-rating">
                            <div className="rating">
                              <Stars productId={product.id} />
                            </div>
                            <WishlistTotal productId={product.id} />
                          </div>
                          <Link to={`/details`} state={{ data: product.id }}>
                            <div className="item-meta">
                              <span className="price">{formatPrice(product.product_price)}</span>
                            </div>
                          </Link>
                        </div>            
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div className="product-list-pagination">
          <div className="float-right">
            <nav aria-label="Page navigation">
              <ul className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                  <li
                      key={index + 1}
                      className={`page-item ${
                      currentPage === index + 1 ? 'active' : ''
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>           
{/* Searched Product Section End */}
        
    <Footer />
    </>
  );
};

export default Search;
