import React from 'react';
import './RadioButtonGroup.css'; // Custom styling for the radio buttons

interface ProductFilterOptionsProps {
    selectedFilter: string;
    onFilterChange: (filter: string) => void;
}

const ProductFilterOptions: React.FC<ProductFilterOptionsProps> = ({ selectedFilter, onFilterChange }) => {
    const filterOptions = {
        ALL: 'all',
        ACTIVATED: 'activated',
        NOT_ACTIVATED: 'not_activated',
    };

    return (
        <div className="filter-item">
            <h4 className="title">Category</h4>
            <div className="filter-list-container">
                <label className="radio">
                    <input
                        type="radio"
                        value={filterOptions.ALL}
                        checked={selectedFilter === filterOptions.ALL}
                        onChange={() => onFilterChange(filterOptions.ALL)}
                    />
                    <span className="radio-label">All Products</span>
                </label>
                <label className="radio">
                    <input
                        type="radio"
                        value={filterOptions.ACTIVATED}
                        checked={selectedFilter === filterOptions.ACTIVATED}
                        onChange={() => onFilterChange(filterOptions.ACTIVATED)}
                    />
                    <span className="radio-label">Activated</span>
                </label>
                <label className="radio">
                    <input
                        type="radio"
                        value={filterOptions.NOT_ACTIVATED}
                        checked={selectedFilter === filterOptions.NOT_ACTIVATED}
                        onChange={() => onFilterChange(filterOptions.NOT_ACTIVATED)}
                    />
                    <span className="radio-label">Not Activated</span>
                </label>
            </div>
        </div>
    );
};

export default ProductFilterOptions;
