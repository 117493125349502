import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

// Components
import MobileNavigation from "../navigation/MobileNavigation";
import DesktopMenuBar from "../navigation/DesktopMenuBar";
import DesktopSearchbar from "../search/DesktopSearchbar";
import MobileSearchbar from "../search/MobileSearchbar";
import SellerNavDesktop from '../../components/seller dashboard/SellerNavDesktop';
import CartMobile from "../cart/CartMobile";
import CartDesktop from "../cart/CartDesktop";
import Icon from "../icon/Icon";
import DesktopHeartIcon from "../icon/DesktopHeartIcon";
import LoginModal from "../../components/modals/LoginModal";

const SESSION_KEYS = {
    SHOPPER_NAME: 'shopper_name',
    SELLER_NAME: 'seller_name',
};

const Header = () => {
    const navigate = useNavigate();
    const [isSearchBarVisible, setSearchBarVisible] = useState(false);
    const [user, setUser] = useState({
        name: sessionStorage.getItem(SESSION_KEYS.SHOPPER_NAME) || sessionStorage.getItem(SESSION_KEYS.SELLER_NAME),
        role: sessionStorage.getItem(SESSION_KEYS.SELLER_NAME) ? 'seller' : 'shopper',
    });
    const [loginType, setLoginType] = useState<"shoppers" | "sellers">("shoppers");
    const [isMenuBarVisible, setMenuBarVisible] = useState<boolean>(false);
    
    // Pass the login type as a prop to the LoginModal
    const openLoginModal = (type: "shoppers" | "sellers") => {
        setLoginType(type); // Set the type (shoppers or sellers)
    };
  
    // Effect to update user state based on session storage
    useEffect(() => {
        const checkLogin = () => {
            const shopperName = sessionStorage.getItem(SESSION_KEYS.SHOPPER_NAME);
            const sellerName = sessionStorage.getItem(SESSION_KEYS.SELLER_NAME);
            setUser({
                name: shopperName || sellerName,
                role: sellerName ? 'seller' : 'shopper',
            });
        };

        checkLogin();
        window.addEventListener('storage', checkLogin);
        return () => window.removeEventListener('storage', checkLogin);
    }, []);

    // Toggling the mobile side bar visibility
    const handleMenuClick = () => {
        setMenuBarVisible(!isMenuBarVisible);
    };
    
    // Function to close the menubar when overlay is clicked
    const closeMenuBar = () => {
        setMenuBarVisible(false);
    };

    // Toggling the search bar visibility
    const handleSearchClick = () => {
        setSearchBarVisible(prev => !prev); // Toggle the search bar visibility
    };

    // logout function to clear session storage
    const logOut = () => {
        sessionStorage.clear();
        navigate("/");
    };

    return (
        <>
            <header id="header">
                {/* Start of top bar of header (larger devices) */}
                <div className="top-bar">
                    <div className="container">
                        <div className="row">   
                            <div className="col-6 col-left">
                                <div className="header_seperator">
                                    <ul className="navbar-nav">
                                        <li className="nav-item"><a href="/contact"  className="nav-link">Contact</a></li>
                                        <li className="nav-item"><a href="/products" className="nav-link">Products</a></li>
                                        <li className="nav-item"><a href="/about" className="nav-link">About</a></li>
                                        <li className="nav-item"><a href="/donate" className="nav-link">Donate</a></li>
                                    </ul>
                                </div>
                            </div>        
                            <div className="col-6 col-right">
                                <div className="second_header_seperator">
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <a className="nav-link" href="#"> 
                                                <Icon name="FaFacebookF" size="1em" color="#365899" />
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#">
                                                <Icon name="FaXTwitter" size="1em" color="#000000" />
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#">
                                                <Icon name="FaInstagram" size="1em" color="#F06292" />
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            {user.role === 'seller' ? (
                                                <SellerNavDesktop /> 
                                            ) : sessionStorage.getItem(SESSION_KEYS.SHOPPER_NAME) ? (
                                                    <a href="/" className="nav-link" onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        logOut();
                                                    }}>
                                                        <span>Welcome, {sessionStorage.getItem(SESSION_KEYS.SHOPPER_NAME)}</span>&nbsp;|&nbsp;Logout
                                                    </a>
                                            ) : (
                                                <>
                                                    <a href="javascript:void(0)" className="nav-link" data-toggle="modal" data-target="#loginModal" onClick={() => openLoginModal("shoppers")}>
                                                        Login
                                                    </a>
                                                    <span className="auth-sep">/</span>
                                                    <Link to="/register?role=shopper">
                                                        Register
                                                    </Link>
                                                </>
                                            )}
                                        </li>
                                    </ul>
                                </div>
                            </div>              
                        </div>
                    </div>
                </div>
                {/* End of top bar */}
        
                {/* Start of header items (larger devices) */}
                <div className="main-menu">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="nav-top">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-md-8 nav-top-left">
                                            <div className="row-align-items-center">
                                                <div className="logo">
                                                    <a href="/">
                                                        <img 
                                                            src="https://res.cloudinary.com/dfexgcu3h/image/upload/v1729003974/Vincee/logo/new_1_adjed1.png"
                                                            alt="logo" 
                                                            className="zoomable" 
                                                            style={{ transition: "transform .3s ease-in-out" }} 
                                                        />
                                                    </a>
                                                </div>
                                                <DesktopSearchbar />
                                            </div>  
                                        </div> 
                                        <div className="col-md-4 nav-top-right">
                                            <ul className="nav align-items-center">
                                                <CartDesktop />
                                                <li className="nav-item li-main-nav-right">
                                                    <a href="/wishlist">
                                                        <DesktopHeartIcon />
                                                        <span className="label-nav-icon">Wishlist</span>
                                                    </a>
                                                </li>
                                                <li className="nav-item m-r-0">
                                                    {sessionStorage.getItem("seller_token") ? (
                                                        <Link to="/seller-dashboard" className="btn btn-md btn-custom btn-sell-now m-r-0">
                                                            Sell Now
                                                        </Link>
                                                        ) : (
                                                            <a href="javascript:void(0)" className="btn btn-md btn-custom btn-sell-now m-r-0" data-toggle="modal" data-target="#loginModal" onClick={() => openLoginModal("sellers")}>
                                                                Sell Now
                                                            </a>
                                                        )
                                                    }
                                                </li>
                                            </ul>
                                        </div>                   
                                    </div>
                                </div>
                            </div>             
                            <DesktopMenuBar />
                        </div>
                    </div>
                </div>
                {/* End of header items (larger devices) */}
              
                {/* Container containing nav button, logo and cart on smaller devices */}
                <div className="mobile-nav-container">
                    <div className="nav-mobile-header">
                        <div className="container-fluid">
            
                            <div className="row">                
                                <div className="nav-mobile-header-container">    
                                    <div className="d-flex justify-content-between">
                            
                                        <div className="flex-item item-menu-icon justify-content-start">
                                            <a href="javascript:void(0)" className="btn-open-mobile-nav" onClick={handleMenuClick}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none" viewBox="0 0 24 24" id="menu-fries"><path fill="url(#paint0_linear_1_11499)" d="M5 5.25C4.58579 5.25 4.25 5.58579 4.25 6C4.25 6.41421 4.58579 6.75 5 6.75H19C19.4142 6.75 19.75 6.41421 19.75 6C19.75 5.58579 19.4142 5.25 19 5.25H5Z"></path><path fill="url(#paint1_linear_1_11499)" d="M5 11.25C4.58579 11.25 4.25 11.5858 4.25 12C4.25 12.4142 4.58579 12.75 5 12.75H11C11.4142 12.75 11.75 12.4142 11.75 12C11.75 11.5858 11.4142 11.25 11 11.25H5Z"></path><path fill="url(#paint2_linear_1_11499)" d="M5 17.25C4.58579 17.25 4.25 17.5858 4.25 18C4.25 18.4142 4.58579 18.75 5 18.75H19C19.4142 18.75 19.75 18.4142 19.75 18C19.75 17.5858 19.4142 17.25 19 17.25H5Z"></path><defs><linearGradient id="paint0_linear_1_11499" x1="12" x2="12" y1="5.25" y2="18.75" gradientUnits="userSpaceOnUse"><stop stop-color="#834D9B"></stop><stop offset="1" stop-color="#D04ED6"></stop></linearGradient><linearGradient id="paint1_linear_1_11499" x1="12" x2="12" y1="5.25" y2="18.75" gradientUnits="userSpaceOnUse"><stop stop-color="#834D9B"></stop><stop offset="1" stop-color="#D04ED6"></stop></linearGradient><linearGradient id="paint2_linear_1_11499" x1="12" x2="12" y1="5.25" y2="18.75" gradientUnits="userSpaceOnUse"><stop stop-color="#834D9B"></stop><stop offset="1" stop-color="#D04ED6"></stop></linearGradient></defs></svg>
                                            </a>
                                        </div>
                    
                                        <div className="flex-item justify-content-center">
                                            <div className="mobile-logo">
                                                <a href="/">
                                                    <img 
                                                        src="https://res.cloudinary.com/dfexgcu3h/image/upload/v1729003974/Vincee/logo/new_1_adjed1.png"
                                                        alt="logo" 
                                                        className="logo zoomable" 
                                                        style={{ transition: "transform .3s ease-in-out" }} 
                                                    />
                                                </a>
                                            </div>
                                        </div>

                                        <div className="flex-item justify-content-end">
                                            <a href="javascript:void(0)" className="a-search-icon" onClick={handleSearchClick}>
                                                <Icon name="CiSearch" size="1.825rem" />
                                            </a>
                                            <CartMobile />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <MobileSearchbar isSearchVisible={isSearchBarVisible} />

                        </div>
                    </div>
                </div>  
                {/* End of container containing nav button, logo and cart on smaller devices */}
                
                {/* Shopper login modal on smaller devices*/}
                <div className="col-lg-6 d-flex justify-content-center" id="loginSection">
                    {/* Check if first_name exists before using it */}
                    {user.role === 'seller' ? (
                        // If seller_name exists, do not show the login button
                        null
                    ) : sessionStorage.getItem(SESSION_KEYS.SHOPPER_NAME) ? (
                        // Render logout option if shopper is logged in
                        <a href="/" onClick={(e) => {e.preventDefault(); e.stopPropagation(); logOut(); }}>
                            <span>Welcome, {sessionStorage.getItem(SESSION_KEYS.SHOPPER_NAME)}</span> |{" "}
                            <Icon name="BiLogOutCircle" size="1em" style={{ top: '-1px', position: 'relative' }} /> Logout
                        </a>
                    ) : (
                        // If shopper_name doesn't exist, display the login button
                        // <a href="javascript:void(0)" data-toggle="modal" data-target="#loginModal" onClick={() => openLoginModal("shoppers")}>
                        //    <Icon name="BiLogInCircle" size="1em" style={{ top: '-1px', position: 'relative' }} /> Login
                        // </a>
                        null
                    )}
                </div>
                {/* End of shopper login modal on smaller devices */}
            </header>

            <div id="overlay_bg" className="overlay-bg" onClick={closeMenuBar} style={{ display: isMenuBarVisible ? 'block' : 'none' }}></div>
            <MobileNavigation isMenuBarVisible={isMenuBarVisible} openLoginModal={openLoginModal} handleMenuClick={handleMenuClick} logOut={logOut} />
            <LoginModal loginType={loginType} />
        </>
    );
};

export default Header;
