import "./product.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useRef, useState } from 'react';
import { Link } from "react-router-dom";
import 'swiper/css';
import 'swiper/css/navigation';
import SwiperCore, { Navigation } from 'swiper';
import Icon from "../icon/Icon";
import product_01 from "../../assets/img/categories/products/product_01.jpg";
import product_01b from "../../assets/img/categories/products/product_01b.jpg";
import product_02 from "../../assets/img/categories/products/product_02.jpg";
import product_02b from "../../assets/img/categories/products/product_02b.jpg";
import product_03 from "../../assets/img/categories/products/product_03.jpg";
import product_03b from "../../assets/img/categories/products/product_03b.jpg";
import product_04 from "../../assets/img/categories/products/product_04.jpg";
import product_04b from "../../assets/img/categories/products/product_04b.jpg";
import product_05 from "../../assets/img/categories/products/product_05.jpg";
import product_05b from "../../assets/img/categories/products/product_05b.jpg";
import product_06 from "../../assets/img/categories/products/product_06.jpg";
import product_06b from "../../assets/img/categories/products/product_06b.jpg";
import product_07 from "../../assets/img/categories/products/product_07.jpg";
import product_07b from "../../assets/img/categories/products/product_07b.jpg";
import product_08 from "../../assets/img/categories/products/product_08.jpg";
import product_08b from "../../assets/img/categories/products/product_08b.jpg";

SwiperCore.use([Navigation]);

const Products = () => {

  return (
    <>
      <div className="carousel">
        <div className="container">
          <div className="wrap">
            <div className="section-title">
                <h2>Thrift Section</h2>
              </div>
            <div className="inner-wrapper">
              <div className="dotgrid carouselbox swiper swiper-container">
                <div className="wrapper swiper-wrapper">
                  <Swiper
  spaceBetween={30}
  autoHeight={true}
  slidesPerView={'auto'} // 'auto' should be a string
  centeredSlides={true} // Boolean values should not be followed by commas
  //onSlideChange={() => console.log('slide change')}
  //onSwiper={(swiper) => console.log(swiper)}
  navigation={true} 
  className="mySwiper"
  navigation={{ // Correct placement of the navigation object
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  }}
  breakpoints={{
    481: { slidesPerView: 2, slidesPerGroup: 1, centeredSlides: false },
    640: { slidesPerView: 3, slidesPerGroup: 3, centeredSlides: false },
    992: { slidesPerView: 4, slidesPerGroup: 4, centeredSlides: false },
  }}
>
                  <SwiperSlide>
                  <div className="item swiper-slide">
                    <div className="dot-image">
                      <a href="#" className="product-permalink" onClick={(e) => { e.preventDefault()}}></a>
                      <div className="thumbnail">
                        <img src={product_01} alt="product_01" />
                      </div>
                      <div className="thumbnail hover">
                        <img src={product_01b} alt="product_01" />
                      </div>
                      <div className="actions">
                        <ul>
                          <li>
                            <a href="/cart" className="tooltip-container">
                              <i className="mdi mdi-heart-outline"></i>
                                <div className="tooltip">
                                  <span className="tooltip-text">Wishlist</span>
                                  <span className="tooltip-arrow"></span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="/cart" className="tooltip-container">
                                <i className="mdi mdi-cart-outline"></i> 
                                  <div className="tooltip">
                                    <span className="tooltip-text">Add to Cart</span>
                                    <span className="tooltip-arrow"></span>
                                  </div>
                                </a>
                              </li>
                          {/*<Link to={`/details`} state={{ data: product.id }}>*/}
                              <li>
                                <a href="/details" className="tooltip-container">
                                  <i className="mdi mdi-eye-outline"></i> 
                                    <div className="tooltip">
                                      <span className="tooltip-text">View Details</span>
                                      <span className="tooltip-arrow"></span>
                                    </div>
                                </a>
                              </li>
                          {/*</Link>*/}
                        </ul>
                      </div>
                      <div className="label"><span>-25%</span></div>
                    </div>
                    <div className="dot-info">
                      <h2 className="dot-title"><a href="#">The Sweater in Tosca</a></h2>
                      <div className="product-price">
                        <span className="before">GHS 62.00</span>
                        <span className="current">GHS 45.00</span>
                      </div>
                    </div>
                  </div>
                  </SwiperSlide>
                    <SwiperSlide>
                  <div className="item swiper-slide">
                    <div className="dot-image">
                      <a href="#" className="product-permalink"></a>
                      <div className="thumbnail">
                        <img src={product_02} alt="product_01" />
                      </div>
                      <div className="thumbnail hover">
                        <img src={product_02b} alt="product_01" />
                      </div>
                      <div className="actions">
                        <ul>
                          <li>
                            <a href="/wishlist">
                              <i className="mdi mdi-heart-outline" data-tooltip="Wishlist"></i>
                            </a>
                          </li>
                          <li>
                            <a href="/cart">
                              <i className="mdi mdi-heart-outline" data-tooltip="Add to Cart"></i>                             
                            </a>
                          </li>
                          {/*<Link to={`/details`} state={{ data: product.id }}>*/}
                            <li>
                              <a href="/details">
                                <i className="mdi mdi-heart-outline" data-tooltip="View Details"></i>
                              </a>
                            </li>
                          
                        </ul>
                      </div>
                      <div className="label"><span>-25%</span></div>
                    </div>
                    <div className="dot-info">
                      <h2 className="dot-title"><a href="#">The Sweater in Tosca</a></h2>
                      <div className="product-price">
                        <span className="before">GHS 62.00</span>
                        <span className="current">GHS 45.00</span>
                      </div>
                    </div>
                  </div>
                    </SwiperSlide>
                    <SwiperSlide>
                  <div className="item swiper-slide">
                    <div className="dot-image">
                      <a href="#" className="product-permalink"></a>
                      <div className="thumbnail">
                        <img src={product_03} alt="product_01" />
                      </div>
                      <div className="thumbnail hover">
                        <img src={product_03b} alt="product_01" />
                      </div>
                      <div className="actions">
                        <ul>
                          <li>
                            <a href="/wishlist">
                              <i className="mdi mdi-heart-outline" data-tooltip="Wishlist"></i>
                            </a>
                          </li>
                          <li>
                            <a href="/cart">
                              <i className="mdi mdi-heart-outline" data-tooltip="Add to Cart"></i>                             
                            </a>
                          </li>
                          {/*<Link to={`/details`} state={{ data: product.id }}>*/}
                            <li>
                              <a href="/details">
                                <i className="mdi mdi-heart-outline" data-tooltip="View Details"></i>
                              </a>
                            </li>
                          
                        </ul>
                      </div>
                      <div className="label"><span>-25%</span></div>
                    </div>
                    <div className="dot-info">
                      <h2 className="dot-title"><a href="#">The Sweater in Tosca</a></h2>
                      <div className="product-price">
                        <span className="before">GHS 62.00</span>
                        <span className="current">GHS 45.00</span>
                      </div>
                    </div>
                  </div>
                    </SwiperSlide>
                    <SwiperSlide>
                  <div className="item swiper-slide">
                    <div className="dot-image">
                      <a href="#" className="product-permalink"></a>
                      <div className="thumbnail">
                        <img src={product_04} alt="product_01" />
                      </div>
                      <div className="thumbnail hover">
                        <img src={product_04b} alt="product_01" />
                      </div>
                      <div className="actions">
                        <ul>
                          <li>
                            <a href="/wishlist">
                              <i className="mdi mdi-heart-outline" data-tooltip="Wishlist"></i>
                            </a>
                          </li>
                          <li>
                            <a href="/cart">
                              <i className="mdi mdi-heart-outline" data-tooltip="Add to Cart"></i>                             
                            </a>
                          </li>
                          {/*<Link to={`/details`} state={{ data: product.id }}>*/}
                            <li>
                              <a href="/details">
                                <i className="mdi mdi-heart-outline" data-tooltip="View Details"></i>
                              </a>
                            </li>
                          
                        </ul>
                      </div>
                      <div className="label"><span>-25%</span></div>
                    </div>
                    <div className="dot-info">
                      <h2 className="dot-title"><a href="#">The Sweater in Tosca</a></h2>
                      <div className="product-price">
                        <span className="before">GHS 62.00</span>
                        <span className="current">GHS 45.00</span>
                      </div>
                    </div>
                  </div>
                    </SwiperSlide>
                    <SwiperSlide>
                  <div className="item swiper-slide">
                    <div className="dot-image">
                      <a href="#" className="product-permalink"></a>
                      <div className="thumbnail">
                        <img src={product_05} alt="product_01" />
                      </div>
                      <div className="thumbnail hover">
                        <img src={product_05b} alt="product_01" />
                      </div>
                      <div className="actions">
                        <ul>
                          <li>
                            <a href="/wishlist">
                              <i className="mdi mdi-heart-outline" data-tooltip="Wishlist"></i>
                            </a>
                          </li>
                          <li>
                            <a href="/cart">
                              <i className="mdi mdi-heart-outline" data-tooltip="Add to Cart"></i>                             
                            </a>
                          </li>
                          {/*<Link to={`/details`} state={{ data: product.id }}>*/}
                            <li>
                              <a href="/details">
                                <i className="mdi mdi-heart-outline" data-tooltip="View Details"></i>
                              </a>
                            </li>
                          
                        </ul>
                      </div>
                      <div className="label"><span>-25%</span></div>
                    </div>
                    <div className="dot-info">
                      <h2 className="dot-title"><a href="#">The Sweater in Tosca</a></h2>
                      <div className="product-price">
                        <span className="before">GHS 62.00</span>
                        <span className="current">GHS 45.00</span>
                      </div>
                    </div>
                  </div>
                    </SwiperSlide>
                    <SwiperSlide>
                  <div className="item swiper-slide">
                    <div className="dot-image">
                      <a href="#" className="product-permalink"></a>
                      <div className="thumbnail">
                        <img src={product_06} alt="product_01" />
                      </div>
                      <div className="thumbnail hover">
                        <img src={product_06b} alt="product_01" />
                      </div>
                      <div className="actions">
                        <ul>
                          <li>
                            <a href="/wishlist">
                              <i className="mdi mdi-heart-outline" data-tooltip="Wishlist"></i>
                            </a>
                          </li>
                          <li>
                            <a href="/cart">
                              <i className="mdi mdi-heart-outline" data-tooltip="Add to Cart"></i>                             
                            </a>
                          </li>
                          {/*<Link to={`/details`} state={{ data: product.id }}>*/}
                            <li>
                              <a href="/details">
                                <i className="mdi mdi-heart-outline" data-tooltip="View Details"></i>
                              </a>
                            </li>
                          
                        </ul>
                      </div>
                      <div className="label"><span>-25%</span></div>
                    </div>
                    <div className="dot-info">
                      <h2 className="dot-title"><a href="#">The Sweater in Tosca</a></h2>
                      <div className="product-price">
                        <span className="before">GHS 62.00</span>
                        <span className="current">GHS 45.00</span>
                      </div>
                    </div>
                  </div>
                    </SwiperSlide>
                    <SwiperSlide>
                  <div className="item swiper-slide">
                    <div className="dot-image">
                      <a href="#" className="product-permalink"></a>
                      <div className="thumbnail">
                        <img src={product_07} alt="product_01" />
                      </div>
                      <div className="thumbnail hover">
                        <img src={product_07b} alt="product_01" />
                      </div>
                      <div className="actions">
                        <ul>
                          <li>
                            <a href="/wishlist">
                              <i className="mdi mdi-heart-outline" data-tooltip="Wishlist"></i>
                            </a>
                          </li>
                          <li>
                            <a href="/cart">
                              <i className="mdi mdi-heart-outline" data-tooltip="Add to Cart"></i>                             
                            </a>
                          </li>
                          {/*<Link to={`/details`} state={{ data: product.id }}>*/}
                            <li>
                              <a href="/details">
                                <i className="mdi mdi-heart-outline" data-tooltip="View Details"></i>
                              </a>
                            </li>
                          
                        </ul>
                      </div>
                      <div className="label"><span>-25%</span></div>
                    </div>
                    <div className="dot-info">
                      <h2 className="dot-title"><a href="#">The Sweater in Tosca</a></h2>
                      <div className="product-price">
                        <span className="before">GHS 62.00</span>
                        <span className="current">GHS 45.00</span>
                      </div>
                    </div>
                  </div>
                    </SwiperSlide>
                    <SwiperSlide>
                  <div className="item swiper-slide">
                    <div className="dot-image">
                      <a href="#" className="product-permalink"></a>
                      <div className="thumbnail">
                        <img src={product_08} alt="product_01" />
                      </div>
                      <div className="thumbnail hover">
                        <img src={product_08b} alt="product_01" />
                      </div>
                      <div className="actions">
                        <ul>
                          <li>
                            <a href="/wishlist">
                              <i className="mdi mdi-heart-outline" data-tooltip="Wishlist"></i>
                            </a>
                          </li>
                          <li>
                            <a href="/cart">
                              <i className="mdi mdi-heart-outline" data-tooltip="Add to Cart"></i>                             
                            </a>
                          </li>
                          {/*<Link to={`/details`} state={{ data: product.id }}>*/}
                            <li>
                              <a href="/details">
                                <i className="mdi mdi-heart-outline" data-tooltip="View Details"></i>
                              </a>
                            </li>
                          
                        </ul>
                      </div>
                      <div className="label"><span>-25%</span></div>
                    </div>
                    <div className="dot-info">
                      <h2 className="dot-title"><a href="#">The Sweater in Tosca</a></h2>
                      <div className="product-price">
                        <span className="before">GHS 62.00</span>
                        <span className="current">GHS 45.00</span>
                      </div>
                    </div>
                  </div>
                      </SwiperSlide>
                    </Swiper>
                </div>
                  
              </div>
              </div>
            
            <div className="p-nav">
              <div className="swiper-button-next">
                <Icon name="FaArrowRight" style={{ position: 'relative', top: '-1px' }} />
              </div>
              <div className="swiper-button-prev">
                <Icon name="FaArrowLeft" style={{ position: 'relative', top: '-1px' }} />
              </div>
            </div>

            </div>
          </div>
        </div>
    </>
  );
};

export default Products;
