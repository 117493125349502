import React, { useState } from 'react';

const ProductSizes = () => {
  const [selectedSize, setSelectedSize] = useState('');

  const handleSizeChange = (value) => {
    setSelectedSize(value);
    // Add any additional logic you need here
  };

  {/*import React, { useState } from 'react';

const ProductSizes = ({ product }) => {
  const [selectedSize, setSelectedSize] = useState('');

  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };

  return (
    <div className="col-12 col-product-variation">
      {product.sizes.map((size) => (
        <div key={size.id || size.name} className="custom-control custom-control-variation custom-control-validate-input">
          <input
            type="radio"
            name="size" // Ensure all radio buttons have the same name for single selection
            value={size.name}
            id={`radio_${size.id || size.name}`} // Create unique IDs if using IDs
            className="custom-control-input"
            checked={selectedSize === size.name}
            onChange={handleSizeChange}
            required
          />
          <label htmlFor={`radio_${size.id || size.name}`} className="custom-control-label label-variation252">
            {size.name}
          </label>
        </div>
      ))}
    </div>
  );
};

export default ProductSizes;
import React, { useState } from 'react';

const ProductColors = ({ product }) => {
  const [selectedColor, setSelectedColor] = useState('');

  const handleColorChange = (event) => {
    setSelectedColor(event.target.value);
  };

  return (
    <div className="col-12 col-product-variation">
      {product.colors.map((color) => (
        <div key={color.value} className="custom-control custom-control-variation custom-control-validate-input">
          <input
            type="radio"
            name="color" // Ensure all radio buttons have the same name for single selection
            value={color.value}
            id={`radio_${color.value}`}
            className="custom-control-input"
            checked={selectedColor === color.value}
            onChange={handleColorChange}
            required
          />
          <label
            htmlFor={`radio_${color.value}`}
            className="custom-control-label label-variation-color"
            data-toggle="tooltip" // Remove if not using tooltips
            data-placement="top" // Remove if not using tooltips
            title={color.name} // Set tooltip text (optional)
          >
            <span className="color-box" style={{ backgroundColor: color.colorCode || 'transparent' }} />
            {color.name} // Display color name (optional)
          </label>
        </div>
      ))}
    </div>
  );
};

export default ProductColors;

*/}
  return (
    <div className="col-12 col-product-variation">
      <div className="custom-control custom-control-variation custom-control-validate-input">
        <input
          type="radio"
          name="variation252"
          data-name="variation252"
          value="850"
          id="radio850"
          className="custom-control-input"
          checked={selectedSize === '850'}
          onChange={() => handleSizeChange('850')}
          required
        />
        <label htmlFor="radio850" className="custom-control-label label-variation252">M</label>
      </div>
      <div className="custom-control custom-control-variation custom-control-validate-input">
        <input
          type="radio"
          name="variation252"
          data-name="variation252"
          value="851"
          id="radio851"
          className="custom-control-input"
          checked={selectedSize === '851'}
          onChange={() => handleSizeChange('851')}
          required
        />
        <label htmlFor="radio851" className="custom-control-label label-variation252">L</label>
      </div>
      <div className="custom-control custom-control-variation custom-control-validate-input">
        <input
          type="radio"
          name="variation252"
          data-name="variation252"
          value="852"
          id="radio852"
          className="custom-control-input"
          checked={selectedSize === '852'}
          onChange={() => handleSizeChange('852')}
          required
        />
        <label htmlFor="radio852" className="custom-control-label label-variation252">XL</label>
      </div>
      <div className="custom-control custom-control-variation custom-control-validate-input">
        <input
          type="radio"
          name="variation252"
          data-name="variation252"
          value="853"
          id="radio853"
          className="custom-control-input"
          checked={selectedSize === '853'}
          onChange={() => handleSizeChange('853')}
          required
        />
        <label htmlFor="radio853" className="custom-control-label label-variation252">S</label>
      </div>
    </div>
  );
};

export default ProductSizes;
