import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SellerNavMobile from '../../components/seller dashboard/SellerNavMobile'; // navigation for sellers (when logged in) on mobile devices
import Icon from "../icon/Icon";

interface MobileNavigationProps {
    isMenuBarVisible: boolean;
    openLoginModal: (role: "shoppers" | "sellers") => void;
    handleMenuClick: () => void;
    logOut: () => void;
}

const MobileNavigation: React.FC<MobileNavigationProps> = ({ isMenuBarVisible, openLoginModal, handleMenuClick, logOut }) => {
    const ShopperName: string | null = sessionStorage.getItem("shopper_name");
    const SellerName: string | null = sessionStorage.getItem("seller_name");
    const [activeMenu, setActiveMenu] = useState<number | null>(null);
    const [currentMenuLabel, setCurrentMenuLabel] = useState<string>('');
    const [activeSubMenu, setActiveSubMenu] = useState<number | null>(null);
    const [currentSubMenuLabel, setCurrentSubMenuLabel] = useState<string>('');
    const [animationClass, setAnimationClass] = useState<string>('');

    // handle menu navigation animation
    useEffect(() => {
        if (activeMenu === null && activeSubMenu === null) {
        setAnimationClass('');
        } else {
        setAnimationClass('slide-in-right');
        }
    }, [activeMenu, activeSubMenu]);

    const handleSubMenuClick = (id: number, label: string) => {
        setAnimationClass('slide-in-right');
        setActiveMenu(activeMenu === id ? null : id);
        setCurrentMenuLabel(label);
        setActiveSubMenu(null);
        setCurrentSubMenuLabel('');
    };

    const handleSubSubMenuClick = (id: number, label: string) => {
        setAnimationClass('slide-in-right');
        setActiveSubMenu(activeSubMenu === id ? null : id);
        setCurrentSubMenuLabel(label);
    };

    const handleBackClick = () => {
        if (activeSubMenu) {
        setActiveSubMenu(null);
        setCurrentSubMenuLabel('');
        } else if (activeMenu) {
        setAnimationClass('slide-out');
        setActiveMenu(null);
        setCurrentMenuLabel('');
        }
    };
  
    return (
        <>  
            {/* Mobile navigation menu */} 
            <div id="navMobile" className={`nav-mobile ${isMenuBarVisible ? 'nav-mobile-open' : ''}`}>
                <div className="nav-mobile-sc">
                    <div className="nav-mobile-inner">
                        
                        {/* Sell now button on mobile devices */}
                        <div className="row">
                            <div className="col-sm-12 mobile-nav-buttons">
                                <a 
                                    href="javascript:void(0)" 
                                    className="btn btn-md btn-custom btn-block close-menu-click" 
                                    data-toggle="modal" 
                                    data-target="#loginModal"
                                    onClick={() => {
                                        handleMenuClick(); // Close the mobile navigation menu
                                        openLoginModal("sellers"); // Open the login modal for sellers
                                    }}
                                >
                                    Sell Now
                                </a>
                            </div>
                        </div>
                        {/* Sell now button on mobile devices */}

                        {/* Menu bar on mobile devices */}
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="nav nav-tabs nav-tabs-mobile-menu" id="nav-tab">
                                    <button className="nav-link active" data-toggle="tab" data-target="#tabMobileMainMenu" type="button" aria-label="button-open-main-menu">Main Menu</button>
                                    <button className="nav-link" id="nav-profile-tab" data-toggle="tab" data-target="#tabMobileCategories" type="button" aria-label="button-open-categories">Sections</button>
                                </div>
                                
                                <div className="tab-content tab-content-mobile-menu nav-mobile-links">
                                    <div className="tab-pane fade active show" id="tabMobileMainMenu" role="tabpanel">
                                        <ul id="navbar_mobile_links" className="navbar-nav">
                                            {/* If Seller is logged in, SellerNavMobile is the first element */}
                                            {SellerName && <SellerNavMobile />}
                                            <li className="nav-item"><a href="/" className="nav-link">Home</a></li>
                                            <li className="nav-item"><a href="/wishlist" className="nav-link">Wishlist</a></li>
                                            <li className="nav-item"><a href="/contact" className="nav-link">Contact</a></li>
                                            <li className="nav-item"><a href="/shops" className="nav-link">Shops</a></li>
                                            <li className="nav-item"><a href="/products" className="nav-link">Products</a></li>                                          
                                            <li className="nav-item"><a href="/about" className="nav-link">About</a></li>
                                            <li className="nav-item"><a href="/donate" className="nav-link">Donate</a></li>
                                            {/* If neither Seller nor Shopper is logged in, show login and register options */}
                                            {!SellerName && (
                                                <>
                                                    {ShopperName ? (
                                                        <li className="nav-item">
                                                            <a href="/" className="nav-link" onClick={logOut}>
                                                                Logout
                                                            </a>
                                                        </li>
                                                    ) : (
                                                        <>
                                                            <li className="nav-item">
                                                                <a 
                                                                    href="javascript:void(0)" 
                                                                    className="nav-link" 
                                                                    data-toggle="modal" 
                                                                    data-target="#loginModal" 
                                                                    onClick={() => {
                                                                        handleMenuClick(); // Close the mobile navigation menu
                                                                        openLoginModal("shoppers"); // Open the login modal for shoppers
                                                                    }}
                                                                >
                                                                    Login
                                                                </a>
                                                            </li>
                                                            <li className="nav-item">
                                                                <a href="/register" className="nav-link">
                                                                    Register
                                                                </a>
                                                            </li>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                    <div className="tab-pane fade" id="tabMobileCategories" role="tabpanel">
                                        {(activeMenu || activeSubMenu) && (
                                            <div id="navbar_mobile_back_button" onClick={handleBackClick}>
                                                <a href="javascript:void(0)" className="nav-link" data-id="1" data-category-name={activeSubMenu ? currentSubMenuLabel : currentMenuLabel}>
                                                    <strong>
                                                        <Icon name="BiChevronLeft" size="1em" style={{ position: 'relative', float: 'left', marginRight: '6px' }} />
                                                        {activeSubMenu ? currentSubMenuLabel : currentMenuLabel}
                                                    </strong>
                                                </a>
                                            </div>
                                        )}  
                                        {!activeMenu && !activeSubMenu && (
                                            <>
                                                <ul id="navbar_mobile_categories" className="navbar-nav navbar-mobile-categories">
                                                    <li className="nav-item"><a href="javascript:void(0)" className="nav-link" data-id="1" data-parent-id="0" onClick={() => handleSubMenuClick(1, 'MEN')}>MEN<Icon name="BiChevronRight" size="1em" style={{ float: 'right', position: 'relative' }} /></a></li>
                                                    <li className="nav-item"><a href="javascript:void(0)" className="nav-link" data-id="2" data-parent-id="0" onClick={() => handleSubMenuClick(2, 'WOMEN')}>WOMEN<Icon name="BiChevronRight" size="1em" style={{ float: 'right', position: 'relative' }} /></a></li>
                                                    <li className="nav-item"><a href="javascript:void(0)" className="nav-link" data-id="8" data-parent-id="0" onClick={() => handleSubMenuClick(8, 'BRANDS')}>BRANDS<Icon name="BiChevronRight" size="1em" style={{ float: 'right', position: 'relative' }} /></a></li>
                                                    <li className="nav-item"><a href="javascript:void(0)" className="nav-link" data-id="42" data-parent-id="0" onClick={() => handleSubMenuClick(42, 'HOME APPLIANCES')}>HOME APPLIANCES<Icon name="BiChevronRight" size="1em" style={{ float: 'right', position: 'relative' }} /></a></li>
                                                    <li className="nav-item"><a href="javascript:void(0)" className="nav-link" data-id="41" data-parent-id="0" onClick={() => handleSubMenuClick(41, 'ELECTRONIC WEARABLES')}>ELECTRONIC WEARABLES<Icon name="BiChevronRight" size="1em" style={{ float: 'right', position: 'relative' }} /></a></li>
                                                    <li className="nav-item"><a href="javascript:void(0)" className="nav-link" data-id="47" data-parent-id="0" onClick={() => handleSubMenuClick(47, 'KIDS ARENA')}>KIDS ARENA<Icon name="BiChevronRight" size="1em" style={{ float: 'right', position: 'relative' }} /></a></li>
                                                </ul>
                                            </>
                                        )}
                                        {activeMenu === 1 && !activeSubMenu && (
                                            <ul id="navbar_mobile_categories" className={`navbar-nav navbar-mobile-categories ${animationClass}`}>
                                                <li className="nav-item"><Link to="/category?sub_category=MEN" className="nav-link">All</Link></li>
                                                <li className="nav-item"><a href="javascript:void(0)" className="nav-link" data-id="9" data-parent-id="1" onClick={() => handleSubSubMenuClick(9, 'ACCESSORIES')}>ACCESSORIES<Icon name="BiChevronRight" size="1em" style={{ float: 'right', position: 'relative' }} /></a></li>
                                                <li className="nav-item"><a href="javascript:void(0)" className="nav-link" data-id="4" data-parent-id="1" onClick={() => handleSubSubMenuClick(4, 'CLOTHING')}>CLOTHING<Icon name="BiChevronRight" size="1em" style={{ float: 'right', position: 'relative' }} /></a></li>
                                                <li className="nav-item"><a href="javascript:void(0)" className="nav-link" data-id="5" data-parent-id="1" onClick={() => handleSubSubMenuClick(5, 'FOOTWEAR')}>FOOTWEAR<Icon name="BiChevronRight" size="1em" style={{ float: 'right', position: 'relative' }} /></a></li>
                                            </ul>
                                        )}
                                        {activeMenu === 2 && !activeSubMenu && (
                                            <ul id="navbar_mobile_categories" className={`navbar-nav navbar-mobile-categories ${animationClass}`}>
                                                <li className="nav-item"><Link to="/category?sub_category=WOMEN" className="nav-link">All</Link></li>
                                                <li className="nav-item"><a href="javascript:void(0)" className="nav-link" data-id="10" data-parent-id="2" onClick={() => handleSubSubMenuClick(10, 'ACCESSORIES')}>ACCESSORIES<Icon name="BiChevronRight" size="1em" style={{ float: 'right', position: 'relative' }} /></a></li>
                                                <li className="nav-item"><a href="javascript:void(0)" className="nav-link" data-id="6" data-parent-id="2" onClick={() => handleSubSubMenuClick(6, 'CLOTHING')}>CLOTHING<Icon name="BiChevronRight" size="1em" style={{ float: 'right', position: 'relative' }} /></a></li>
                                                <li className="nav-item"><a href="javascript:void(0)" className="nav-link" data-id="7" data-parent-id="2" onClick={() => handleSubSubMenuClick(7, 'FOOTWEAR')}>FOOTWEAR<Icon name="BiChevronRight" size="1em" style={{ float: 'right', position: 'relative' }} /></a></li>
                                            </ul>
                                        )}
                                        {activeMenu === 8 && !activeSubMenu && (
                                            <ul id="navbar_mobile_categories" className={`navbar-nav navbar-mobile-categories ${animationClass}`}>
                                                <li className="nav-item"><Link to="/category?sub_category=BRANDS" className="nav-link">All</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=BRANDS&search=Hermès" className="nav-link" data-id="36" data-parent-id="8">Hermès</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=BRANDS&search=BIRKENSTOCK" className="nav-link" data-id="32" data-parent-id="8">BIRKENSTOCK</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=BRANDS&search=BINATONE" className="nav-link" data-id="39" data-parent-id="8">BINATONE</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=BRANDS&search=NIKE" className="nav-link" data-id="34" data-parent-id="8">NIKE</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=BRANDS&search=BURBERRY" className="nav-link" data-id="38" data-parent-id="8">BURBERRY</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=BRANDS&search=SILVERCREST" className="nav-link" data-id="40" data-parent-id="8">SILVERCREST</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=BRANDS&search=ADIDAS" className="nav-link" data-id="33" data-parent-id="8">ADIDAS</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=BRANDS&search=BALENCIAGA" className="nav-link" data-id="37" data-parent-id="8">BALENCIAGA</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=BRANDS&search=KENWOOD" className="nav-link" data-id="41" data-parent-id="8">KENWOOD</Link></li>
                                            </ul>
                                        )}
                                        {activeMenu === 42 && !activeSubMenu && (
                                            <ul id="navbar_mobile_categories" className={`navbar-nav navbar-mobile-categories ${animationClass}`}>
                                                <li className="nav-item"><Link to="/category?sub_category=HOME%20APPLIANCES" className="nav-link">All</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=HOME%20APPLIANCES&search=BLENDERS" className="nav-link" data-id="45" data-parent-id="42">BLENDERS</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=HOME%20APPLIANCES&search=KITCHENWARES" className="nav-link" data-id="46" data-parent-id="42">KITCHENWARES</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=HOME%20APPLIANCES&search=COOKWARE%20SETS" className="nav-link" data-id="44" data-parent-id="42">COOKWARE SETS</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=HOME%20APPLIANCES&search=HOME%20DECOR" className="nav-link" data-id="43" data-parent-id="42">HOME DECOR</Link></li>
                                            </ul>
                                        )}
                                        {activeMenu === 41 && !activeSubMenu && (
                                            <ul id="navbar_mobile_categories" className={`navbar-nav navbar-mobile-categories ${animationClass}`}>
                                                <li className="nav-item"><Link to="/category?sub_category=ELECTRONIC%20WEARABLES" className="nav-link">All</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=ELECTRONIC%20WEARABLES&search=SMARTWATCHES" className="nav-link" data-id="55" data-parent-id="41">SMARTWATCHES</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=ELECTRONIC%20WEARABLES&search=CAMERAS" className="nav-link" data-id="56" data-parent-id="41">CAMERAS</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=ELECTRONIC%20WEARABLES&search=AIRPODS" className="nav-link" data-id="54" data-parent-id="41">AIRPODS</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=ELECTRONIC%20WEARABLES&search=HEADSETS" className="nav-link" data-id="53" data-parent-id="41">HEADSETS</Link></li>
                                            </ul>
                                        )}
                                        {activeMenu === 47 && !activeSubMenu && (
                                            <ul id="navbar_mobile_categories" className={`navbar-nav navbar-mobile-categories ${animationClass}`}>
                                                <li className="nav-item"><Link to="/category?sub_category=KIDS%20ARENA" className="nav-link">All</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=KIDS%20ARENA&search=ART%20SETS" className="nav-link" data-id="65" data-parent-id="47">ART SETS</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=KIDS%20ARENA&search=KIDS%20CLOTHING" className="nav-link" data-id="66" data-parent-id="47">KIDS CLOTHING</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=KIDS%20ARENA&search=TOYS%20%26%20GAMES" className="nav-link" data-id="64" data-parent-id="47">TOYS & GAMES</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=KIDS%20ARENA&search=KIDS%20SHOES" className="nav-link" data-id="63" data-parent-id="47">KIDS SHOES</Link></li>
                                            </ul>
                                        )}
                                        {/* SUB-SUBMENU ITEMS- MEN/ACCESSORIES */} 
                                        {activeSubMenu === 9 && (
                                            <ul id="navbar_mobile_categories" className={`navbar-nav navbar-mobile-categories ${animationClass}`}>
                                                <li className="nav-item"><Link to="/category?sub_category=MEN&search=ACCESSORIES" className="nav-link">All</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=MEN&subSubCategory=ACCESSORIES&search=COLOGNE" className="nav-link">COLOGNE</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=MEN&subSubCategory=ACCESSORIES&search=HATS" className="nav-link">HATS</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=MEN&subSubCategory=ACCESSORIES&search=JEWELRY" className="nav-link">JEWELRY</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=MEN&subSubCategory=ACCESSORIES&search=TIES" className="nav-link">TIES</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=MEN&subSubCategory=ACCESSORIES&search=OTHERS" className="nav-link">OTHERS</Link></li>
                                            </ul>
                                        )}
                                        {/* SUB-SUBMENU ITEMS- MEN/CLOTHING */} 
                                        {activeSubMenu === 4 && (
                                            <ul id="navbar_mobile_categories" className={`navbar-nav navbar-mobile-categories ${animationClass}`}>
                                                <li className="nav-item"><Link to="/category?sub_category=MEN&search=CLOTHING" className="nav-link">All</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=MEN&subSubCategory=CLOTHING&search=JACKETS" className="nav-link">JACKETS</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=MEN&subSubCategory=CLOTHING&search=TOPS" className="nav-link">TOPS</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=MEN&subSubCategory=CLOTHING&search=SHORTS%2FTROUSERS" className="nav-link">SHORTS/TROUSERS</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=MEN&subSubCategory=CLOTHING&search=OTHERS" className="nav-link">OTHERS</Link></li>
                                            </ul>
                                        )}
                                        {/* SUB-SUBMENU ITEMS- MEN/FOOTWEAR */} 
                                        {activeSubMenu === 5 && (
                                            <ul id="navbar_mobile_categories" className={`navbar-nav navbar-mobile-categories ${animationClass}`}>
                                                <li className="nav-item"><Link to="/category?sub_category=MEN&search=FOOTWEAR" className="nav-link">All</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=MEN&subSubCategory=FOOTWEAR&search=SNEAKERS" className="nav-link">SNEAKERS</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=MEN&subSubCategory=FOOTWEAR&search=SHOES" className="nav-link">SHOES</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=MEN&subSubCategory=FOOTWEAR&search=SLIPPERS" className="nav-link">SLIPPERS</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=MEN&subSubCategory=FOOTWEAR&search=OTHERS" className="nav-link">OTHERS</Link></li>
                                            </ul>
                                        )}
                                        {/* SUB-SUBMENU ITEMS- WOMEN/ACCESSORIES */} 
                                        {activeSubMenu === 10 && (
                                            <ul id="navbar_mobile_categories" className={`navbar-nav navbar-mobile-categories ${animationClass}`}>
                                                <li className="nav-item"><Link to="/category?sub_category=WOMEN&search=ACCESSORIES" className="nav-link">All</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=WOMEN&subSubCategory=ACCESSORIES&search=HAIR%20WEARS%20%26%20BONNETS" className="nav-link">HAIR WEARS & BONNETS</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=WOMEN&subSubCategory=ACCESSORIES&search=NECKLACES%20%26%20ANKLETS" className="nav-link">NECKLACES & ANKLETS</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=WOMEN&subSubCategory=ACCESSORIES&search=PERFUMES%20%26%20BEAUTY" className="nav-link">PERFUMES & BEAUTY</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=WOMEN&subSubCategory=ACCESSORIES&search=BAGS" className="nav-link">BAGS</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=WOMEN&subSubCategory=ACCESSORIES&search=EYEWEAR" className="nav-link">EYEWEAR</Link></li>
                                            </ul>
                                        )}
                                        {/* SUB-SUBMENU ITEMS- WOMEN/CLOTHING */} 
                                        {activeSubMenu === 6 && (
                                            <ul id="navbar_mobile_categories" className={`navbar-nav navbar-mobile-categories ${animationClass}`}>
                                                <li className="nav-item"><Link to="/category?sub_category=WOMEN&search=CLOTHING" className="nav-link">All</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=WOMEN&subSubCategory=CLOTHING&search=LADIES%20DRESSES" className="nav-link">LADIES DRESSES</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=WOMEN&subSubCategory=CLOTHING&search=FEMALE%20TOPS" className="nav-link">FEMALE TOPS</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=WOMEN&subSubCategory=CLOTHING&search=FEMALE%20TROUSERS%20%26%20SHORTS" className="nav-link">FEMALE TROUSERS & SHORTS</Link></li>
                                            </ul>
                                        )}
                                        {/* SUB-SUBMENU ITEMS- WOMEN/FOOTWEAR */} 
                                        {activeSubMenu === 7 && (
                                            <ul id="navbar_mobile_categories" className={`navbar-nav navbar-mobile-categories ${animationClass}`}>
                                                <li className="nav-item"><Link to="/category?sub_category=WOMEN&search=FOOTWEAR" className="nav-link">All</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=WOMEN&subSubCategory=FOOTWEAR&search=SHOES%20%26%20HEELS" className="nav-link">SHOES & HEELS</Link></li>
                                                <li className="nav-item"><Link to="/category?sub_category=WOMEN&subSubCategory=FOOTWEAR&search=SNEAKERS" className="nav-link">SNEAKERS</Link></li>
                                            </ul>
                                        )}
                                    </div>
                                </div> 
                            </div>
                           
                        </div>
                        {/* Menu bar on mobile devices */}
                    </div>
                </div>
                
                {/* Navigation footer 
                <div className="nav-mobile-footer">
                    <ul>
                        <li>
                            <a href="#" className="facebook" target="_blank">
                                <Icon name="FaFacebookF" size="1em" color="#365899" />
                            </a>
                        </li>
                        <li>
                            <a href="#" className="twitter" target="_blank">
                                <Icon name="FaXTwitter" size="1em" color="#000000" />
                            </a>
                        </li>
                        <li>
                            <a href="#" className="instagram" target="_blank">
                                <Icon name="FaInstagram" size="1em" color="#F06292" />
                            </a>
                        </li>
                        <li>
                            <a href="https://wa.me/233542059074?text=Hello%20Vincee" className="whatsapp" target="_blank">
                                <Icon name="FaWhatsapp" size="1em" color="#008b8b" />
                            </a>
                        </li>
                    </ul>
                </div>
                {/* Navigation footer */}
            </div>
        </>
    );
};

export default MobileNavigation;