import axios from "axios";
import React, { useEffect, useState } from "react";
import { ProductProps } from "../../types/Types";
import { Link } from "react-router-dom";
import Stars from '../../components/stars/Stars';
import WishlistButton from "../../components/wishlist button/WishlistButton";
import WishlistTotal from "../../components/wishlist button/WishlistTotal";

const RelateProducts = ({ sellerId }: any) => {
  const [products, setProducts] = useState<ProductProps[]>([]);
  const [companyName, setCompanyName] = useState("");

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_Base_url}/products/get_product/${sellerId}`);
        if (response.data.status === 200) {
          setProducts(response.data.products);

          // Assuming all products have the same company name and it is a property of the products
          if (response.data.products.length > 0) {
            setCompanyName(response.data.products[0].company_name);
          }
        } else {
          console.error(response.data.message);
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [sellerId]);

  const formatPrice = (price: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'GHS', // Change to 'GHS' for Ghanaian cedis
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price);
  };

return (
  <>
<div className="col-12 section section-related-products m-t-30">
  <h3 className="title">More from&nbsp;<a href="/profile">{companyName}</a></h3>      
  <div className="row row-product">
    {products.length === 0 ? (
      <div className="col-lg-12">
        <p>No products found!</p>
      </div>
    ) : (
      products.map((product: ProductProps) => {
        return (
          
            
          
          <div className="col-6 col-sm-4 col-md-3 col-mds-5 col-product" key={product.id}>
            <div className="product-item">
              <div className="row-custom product-multiple-image">
                <div className="img-product-container">         
                  <img
                    src={product.product_image}
                    alt={product.product_name}
                    className="img-fluid img-product ls-is-cached lazyloaded"
                    loading="lazy"
                  />
                  <div className="thumbnail hover">
                    <img
                      src={product.product_image}
                      alt={product.product_name}
                      className="img-fluid img-product img-second ls-is-cached lazyloaded"
                      loading="lazy"
                    />
                  </div>
                  <div className="actions">
                    <ul>
                      <li>
                        <WishlistButton productId={product.id} />
                      </li>
                      <li>
                        <a href="/cart" className="tooltip-container">
                          <i className="mdi mdi-cart-outline"></i> 
                            <div className="tooltip">
                              <span className="tooltip-text">Add to Cart</span>
                              <span className="tooltip-arrow"></span>
                            </div>
                        </a>
                      </li>
                      <Link to={`/details`} state={{ data: product.id }}>
                      <li>
                        <a href="/details" className="tooltip-container">
                          <i className="mdi mdi-eye-outline"></i> 
                            <div className="tooltip">
                              <span className="tooltip-text">View Details</span>
                              <span className="tooltip-arrow"></span>
                            </div>
                        </a>
                      </li>
                      </Link>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row-custom item-details">
                <h3 className="product-title">
                  <Link to={`/details`} state={{ data: product.id }}>
                    {product.product_name}
                  </Link>
                </h3>
                <p className="product-user text-truncate">
                  <a href="#">{product.company_name}</a>
                </p>
                <div className="product-item-rating">
                  <div className="rating">
                    <Stars productId={product.id} />
                  </div>
                  <WishlistTotal productId={product.id} />
                </div>
                <Link to={`/details`} state={{ data: product.id }}>
                  <div className="item-meta">
                    <span className="price">{formatPrice(product.product_price)}</span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          
          
          );
        })
      )}
    
  </div>
    <div className="row-custom text-center">
      <a href="#" className="link-see-more"><span>View All&nbsp;</span><i className="mdi mdi-chevron-right"></i></a>
    </div>
</div>
  </>
);
};

export default RelateProducts;
