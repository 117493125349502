import React, { useState, useEffect } from "react";
import { CategoryProps } from "../../types/Types";
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import 'overlayscrollbars/styles/overlayscrollbars.css';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Icon from "../icon/Icon";
import { CircleLoader } from "react-spinners";

const Searchbar = () => {
    const [categories, setCategories] = useState<[]>([]);
    const [search, setSearch] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [results, setResults] = useState<any[]>([]); // Store the search results here
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState<boolean>(false); // Control visibility of results

    // Function to highlight the searched keyword in product name
    const highlightKeyword = (productName: string, keyword?: string): string => {
        if (!keyword) return productName; // If no search term, return product name as is
        const regex = new RegExp(`(${keyword})`, 'gi'); // Create a case-insensitive regex
        return productName.replace(regex, '<strong>$1</strong>'); // Replace matched keyword with strong tags
    };        

    // calling the categories api
    useEffect(() => {
        axios
        .get(`${process.env.REACT_APP_Base_url}/utils/get_product_categories`)
        .then((res) => {
            //console.log(res.data);
            if (res.data.status === 200) {
            setCategories(res.data.product_categories);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }, []);

    const itemHeight = 90; // Height of each item in px
    const maxItems = 5; // Maximum items before scrolling

    // Set the max-height based on the number of results
    const getMaxHeight = () => {
        return Math.min(results.length * itemHeight, itemHeight * maxItems + itemHeight / 2); // Show half of the 6th item
    };

    // Debounced search function
    useEffect(() => {
        if (search.trim() === '') {
            setResults([]);
            setIsVisible(false); // Hide results if no search input
            return;
        }

        const delayDebounceFn = setTimeout(() => {
            handleSearch(search);
        }, 500); // 500ms delay to debounce

        return () => clearTimeout(delayDebounceFn);
    }, [search]);

    // Search function to call API and fetch products
    const handleSearch = (query: string): void => {
        setLoading(true);

        axios
            .post(`${process.env.REACT_APP_Base_url}/products/search_product`, {
                product_name: query,
            })
            .then((res) => {
                setLoading(false);
                if (res.data.status === 200) {
                    setResults(res.data.product);
                    setIsVisible(true); // Show results if products are found
                } else {
                    setResults([]);
                    setIsVisible(true); // Keep results visible to show "No products found"
                }
            })
            .catch((err) => {
                setLoading(false);
                console.error(err);
            });
    };

    // Handle final search when the form is submitted
    const handleSubmit = (e: any) => {
        e.preventDefault();

        if (!search.trim()) {
            alert("Please enter a search query.");
            return;
        }

        setLoading(true);

        axios
            .post(`${process.env.REACT_APP_Base_url}/products/search_product`, {
                product_name: search,
            })
            .then((res) => {
                setLoading(false);
                if (res.data.status === 200) {
                    navigate('/search', {
                        state: { data: res.data.product, keyword: search },
                    });
                    window.scrollTo(0, 0);
                } else {
                    alert('No product found');
                }
            })
        .catch((err) => {
                setLoading(false);
                console.error(err);
        });
    };

    return (
        <>
            <div className="top-search-bar">
                <form 
                    id="form_validate_search" 
                    className="form_search_main" 
                    onSubmit={handleSubmit}
                >
                    {/* Search categories dropdown*/}
                    <div className="left">
                        <div className="dropdown search-select">
                            <button 
                                type="button" 
                                className="btn dropdown-toggle" 
                                id="dropdownMenuButtonDesktop"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                All Categories
                            </button>
                            <Icon 
                                name="BiChevronDown" 
                                size="1em" 
                                style={{ pointerEvents: 'none', position: 'absolute', right: '5px', top: '10px' }} 
                            />
                            <div className="dropdown-menu search-categories" aria-labelledby="dropdownMenuButtonDesktop" role="menu">
                                {categories &&
                                    categories.map((category: CategoryProps) => (
                                        <div key={category.id}>
                                            <Link to="/product-category" className="dropdown-item" state={{ data: category.product_category_name }}>
                                                {category.product_category_name}
                                            </Link>
                                        </div>
                                    ))
                                }            
                            </div>
                        </div>        
                    </div>
                    {/* Search categories dropdown*/}

                    {/*Search bar */}
                    <div className="right">            
                        <input 
                            type="text" 
                            id="input_search" 
                            name="search" 
                            maxLength={300} 
                            pattern=".*\S+.*" 
                            className="form-control input-search" 
                            placeholder="What do you need?" 
                            required autoComplete="off"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <button 
                            className="btn btn-default btn-search"
                            type="submit" 
                            disabled={loading}
                        >
                            {loading ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-loader spinner"
                                >
                                    <line x1="12" y1="2" x2="12" y2="6"></line>
                                    <line x1="12" y1="18" x2="12" y2="22"></line>
                                    <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                                    <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                                    <line x1="2" y1="12" x2="6" y2="12"></line>
                                    <line x1="18" y1="12" x2="22" y2="12"></line>
                                    <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                                    <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
                                </svg>
                            ) : (
                                <Icon 
                                    name="CiSearch" 
                                    size="1em" 
                                />
                            )}
                        </button>
                        {/*Search bar */}

                        {/* Display real-time search results start */}
                        <div
                            id="response_search_results"
                            className={`search-results-ajax ${isVisible ? 'visible' : ''}`}
                            style={{
                                maxHeight: isVisible ? '400px' : '0', // Adjust max-height dynamically
                                opacity: isVisible ? 1 : 0,           // Fade in when visible
                                transition: 'max-height 0.3s ease, opacity 0.3s ease',
                            }}
                        >
                            <OverlayScrollbarsComponent
                                defer
                                options={{
                                    scrollbars: { autoHide: "leave", theme: "os-theme-dark" },
                                }}
                                style={{ maxHeight: '400px' }} // Control the max height of the results
                            >
                                {/* Show results list */}
                                {/*{loading && <div>Loading...</div>}*/}
                                {loading && (
                                    <div style={loaderContainerStyle}>
                                        <CircleLoader color="var(--vr-color-main)" loading={loading} />
                                    </div>
                                )}
                                {!loading && results && results.length > 0 ? (
                                    <ul>
                                        {results.map((result) => (
                                            <li key={result.id}>
                                                <Link to={`/details`} state={{ data: result.id }}>
                                                    <div className="left">
                                                        <div className="search-image">
                                                            <img
                                                                src={result.main_image || result.product_images[0]}
                                                                alt={result.product_name}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="search-product">
                                                        {/* Use 'dangerouslySetInnerHTML' to inject the highlighted HTML */}
                                                        <p className="m-0">
                                                            <span dangerouslySetInnerHTML={{ 
                                                                __html: highlightKeyword(result.product_name, search) 
                                                            }} />
                                                        </p>
                                                        <strong className="price">
                                                            <span>¢</span>
                                                            {result.product_price
                                                                ? `${parseFloat(result.product_price).toFixed(2)}`
                                                                : 'N/A'}
                                                        </strong>
                                                    </div>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    !loading && isVisible && search && results.length === 0 && (
                                        <p className="text-center text-muted">No products found.</p>
                                    )
                                )}
                            </OverlayScrollbarsComponent>
                        </div>
                        {/* Display real-time search results end */}
                    </div>
                </form>
            </div>
        </>
    );
};

const loaderContainerStyle = {
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    height: '100%', // Full viewport height
};

export default Searchbar;                      