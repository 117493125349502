import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import A from "../../assets/img/categories/cat-jewel.jpg";
import B from "../../assets/img/categories/cat-clothing.jpg";
import C from "../../assets/img/categories/cat-footwear.png";
import D from "../../assets/img/categories/cat-shoes.jpg";
import E from "../../assets/img/categories/cat-gem.jpg";
import F from "../../assets/img/categories/cat-ladies.jpg";
import G from "../../assets/img/categories/cat-birk.png";
import H from "../../assets/img/categories/cat-nike.jpg";
import I from "../../assets/img/categories/cat-brands1.jpg";
import J from "../../assets/img/categories/cat-brands2.jpg";
import K from "../../assets/img/categories/cat-blender.jpg";
import L from "../../assets/img/categories/cat-cookware.jpg";
import M from "../../assets/img/categories/cat-headset.jpg";
import N from "../../assets/img/categories/cat-airpod.jpg";
import O from "../../assets/img/categories/cat-smartwatch.jpg";
import P from "../../assets/img/categories/cat-art.jpg";
import Q from "../../assets/img/categories/cat-clothes.jpg";
import R from "../../assets/img/categories/cat-shoe.jpg";


const DesktopMenuBar = () => {
    const containerRef = useRef(null);
    const [position, setPosition] = useState({ left: 0, width: 0 });
    const [isHovering, setIsHovering] = useState(false);
    const [dropdownVisibility, setDropdownVisibility] = useState({
        men: false,
        women: false,
        brands: false,
        homeAppliances: false,
        electronicWearables: false,
        kidsArena: false,
        // add other menu items as needed
    });

    // handling magnetic mouse effect and toggling of submenus
    const handleMouseEnter = (event) => {
        setIsHovering(true);
        const target = event.currentTarget;
        const rect = target.getBoundingClientRect();
        const parentRect = containerRef.current.getBoundingClientRect();
        
        setPosition({
          left: rect.left - parentRect.left,
          width: rect.width,
        });
    };
    
    const handleMouseLeave = () => {
        setIsHovering(false);
    };
    
    const handleDropdownEnter = (menuKey) => {
        setDropdownVisibility(prev => ({
          ...prev,
          [menuKey]: true
        }));
    };
    
    const handleDropdownLeave = (menuKey) => {
        setDropdownVisibility(prev => ({
          ...prev,
          [menuKey]: false
        }));
    };

    return (
        <>
            <div className="nav-main">
                <div className="container">
                    <div className="navbar navbar-light navbar-expand">
                    
                        <ul className="nav navbar-nav mega-menu" ref={containerRef} onMouseLeave={handleMouseLeave}>
                            {/* Magnetic hover background */}
                            <div 
                                className="position-absolute rounded" 
                                style={{
                                    left: position.left,
                                    width: position.width,
                                    height: '2.5rem',
                                    top: '50%',
                                    transform: `translateY(-50%) scale(${isHovering ? 1 : 0.95})`,
                                    opacity: isHovering ? 1 : 0,
                                    backgroundColor: '#f8f9fa',
                                    transition: 'all 300ms ease-out',
                                    pointerEvents: 'none',
                                    zIndex: 0
                                }}
                            />

                            <li className="nav-item dropdown" data-category-id="1" onMouseEnter={(e) => {handleDropdownEnter('men'); handleMouseEnter(e);}} onMouseLeave={(e) => {handleDropdownLeave('men'); handleMouseLeave(e);}}>
                                <Link to="/category?sub_category=MEN" id="nav_main_category_1" className="nav-link dropdown-toggle nav-main-category" data-id="1" data-parent-id="0" data-has-sb="1">MEN</Link>
                                <div id="mega_menu_content_1" className="dropdown-menu mega-menu-content" style={{ display: dropdownVisibility.men ? "block" : "none" }}>
                                    <div className="row">
                                        <div className="col-8 menu-subcategories col-category-links">
                                            <div className="card-columns">
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Link to="/category?sub_category=MEN&search=ACCESSORIES" id="nav_main_category_9" className="second-category nav-main-category" data-id="9" data-parent-id="1" data-has-sb="1">ACCESSORIES</Link>
                                                            <ul>
                                                                <li><Link to="/category?sub_category=MEN&subSubCategory=ACCESSORIES&search=COLOGNE" id="nav_main_category_12" className="nav-main-category " data-id="12" data-parent-id="9" data-has-sb="0">COLOGNE</Link></li>
                                                                <li><Link to="/category?sub_category=MEN&subSubCategory=ACCESSORIES&search=HATS" id="nav_main_category_13" className="nav-main-category " data-id="13" data-parent-id="9" data-has-sb="0">HATS</Link></li>
                                                                <li><Link to="/category?sub_category=MEN&subSubCategory=ACCESSORIES&search=JEWELRY" id="nav_main_category_14" className="nav-main-category " data-id="14" data-parent-id="9" data-has-sb="0">JEWELRY</Link></li>
                                                                <li><Link to="/category?sub_category=MEN&subSubCategory=ACCESSORIES&search=TIES" id="nav_main_category_26" className="nav-main-category " data-id="26" data-parent-id="9" data-has-sb="0">TIES</Link></li>
                                                                <li><Link to="/category?sub_category=MEN&subSubCategory=ACCESSORIES&search=OTHERS" id="nav_main_category_11" className="nav-main-category " data-id="11" data-parent-id="9" data-has-sb="0">OTHERS</Link></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Link to="/category?sub_category=MEN&search=CLOTHING" id="nav_main_category_4" className="second-category nav-main-category" data-id="4" data-parent-id="1" data-has-sb="1">CLOTHING</Link>
                                                            <ul>
                                                                <li><Link to="/category?sub_category=MEN&subSubCategory=CLOTHING&search=JACKETS" id="nav_main_category_15" className="nav-main-category " data-id="15" data-parent-id="4" data-has-sb="0">JACKETS</Link></li>
                                                                <li><Link to="/category?sub_category=MEN&subSubCategory=CLOTHING&search=TOPS" id="nav_main_category_27" className="nav-main-category " data-id="27" data-parent-id="4" data-has-sb="0">TOPS</Link></li>
                                                                <li><Link to="/category?sub_category=MEN&subSubCategory=CLOTHING&search=SHORTS%2FTROUSERS" id="nav_main_category_21" className="nav-main-category " data-id="21" data-parent-id="4" data-has-sb="0">SHORTS/TROUSERS</Link></li>
                                                                <li><Link to="/category?sub_category=MEN&subSubCategory=CLOTHING&search=OTHERS" id="nav_main_category_19" className="nav-main-category " data-id="19" data-parent-id="4" data-has-sb="0">OTHERS</Link></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Link to="/category?sub_category=MEN&search=FOOTWEAR" id="nav_main_category_5" className="second-category nav-main-category" data-id="5" data-parent-id="1" data-has-sb="1">FOOTWEAR</Link>
                                                            <ul>
                                                                <li><Link to="/category?sub_category=MEN&subSubCategory=FOOTWEAR&search=SNEAKERS" id="nav_main_category_28" className="nav-main-category " data-id="28" data-parent-id="5" data-has-sb="0">SNEAKERS</Link></li>
                                                                <li><Link to="/category?sub_category=MEN&subSubCategory=FOOTWEAR&search=SHOES" id="nav_main_category_17" className="nav-main-category " data-id="17" data-parent-id="5" data-has-sb="0">SHOES</Link></li>
                                                                <li><Link to="/category?sub_category=MEN&subSubCategory=FOOTWEAR&search=SLIPPERS" id="nav_main_category_20" className="nav-main-category " data-id="20" data-parent-id="5" data-has-sb="0">SLIPPERS</Link></li>
                                                                <li><Link to="/category?sub_category=MEN&subSubCategory=FOOTWEAR&search=OTHERS" id="nav_main_category_18" className="nav-main-category " data-id="18" data-parent-id="5" data-has-sb="0">OTHERS</Link></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 col-category-images">
                                            <div className="nav-category-image">
                                                <Link to="/category?sub_category=MEN&search=ACCESSORIES">
                                                    <img src={A} data-src={A} alt="ACCESSORIES" className="img-fluid lazyloaded" />
                                                    <span>ACCESSORIES</span>
                                                </Link>
                                            </div>
                                            <div className="nav-category-image">
                                                <Link to="/category?sub_category=MEN&search=FOOTWEAR">
                                                    <img src={C} data-src={C} alt="FOOTWEAR" className="img-fluid lazyloaded" />
                                                    <span>FOOTWEAR</span>
                                                </Link>
                                            </div>
                                            <div className="nav-category-image">
                                                <Link to="/category?sub_category=MEN&search=CLOTHING">
                                                    <img src={B} data-src={B} alt="CLOTHING" className="img-fluid lazyloaded" />
                                                    <span>CLOTHING</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>                
                                </div>
                            </li>
                                    
                            <li className="nav-item dropdown" data-category-id="2" onMouseEnter={(e) => {handleDropdownEnter('women'); handleMouseEnter(e);}} onMouseLeave={(e) => {handleDropdownLeave('women'); handleMouseLeave(e);}}>
                                <Link to="/category?sub_category=WOMEN" id="nav_main_category_2" className="nav-link dropdown-toggle nav-main-category" data-id="2" data-parent-id="0" data-has-sb="1">WOMEN</Link>
                                <div id="mega_menu_content_2" className="dropdown-menu mega-menu-content" style={{ display: dropdownVisibility.women ? "block" : "none" }}>
                                    <div className="row">
                                        <div className="col-8 menu-subcategories col-category-links">
                                            <div className="card-columns">
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Link to="/category?sub_category=WOMEN&search=ACCESSORIES" id="nav_main_category_10" className="second-category nav-main-category" data-id="10" data-parent-id="2" data-has-sb="1">ACCESSORIES</Link>
                                                            <ul>
                                                                <li><Link to="/category?sub_category=WOMEN&subSubCategory=ACCESSORIES&search=HAIR%20WEARS%20%26%20BONNETS" id="nav_main_category_24" className="nav-main-category " data-id="24" data-parent-id="10" data-has-sb="0">HAIR WEARS &amp; BONNETS</Link></li>
                                                                <li><Link to="/category?sub_category=WOMEN&subSubCategory=ACCESSORIES&search=NECKLACES%20%26%20ANKLETS" id="nav_main_category_25" className="nav-main-category " data-id="25" data-parent-id="10" data-has-sb="0">NECKLACES AND ANKLETS</Link></li>
                                                                <li><Link to="/category?sub_category=WOMEN&subSubCategory=ACCESSORIES&search=PERFUMES%20%26%20BEAUTY" id="nav_main_category_35" className="nav-main-category " data-id="35" data-parent-id="10" data-has-sb="0">PERFUMES &amp; BEAUTY</Link></li>
                                                                <li><Link to="/category?sub_category=WOMEN&subSubCategory=ACCESSORIES&search=BAGS" id="nav_main_category_31" className="nav-main-category " data-id="31" data-parent-id="10" data-has-sb="0">BAGS</Link></li>
                                                                <li><Link to="/category?sub_category=WOMEN&subSubCategory=ACCESSORIES&search=EYEWEAR" id="nav_main_category_16" className="nav-main-category " data-id="16" data-parent-id="10" data-has-sb="0">EYEWEAR</Link></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Link to="/category?sub_category=WOMEN&search=CLOTHING" id="nav_main_category_6" className="second-category nav-main-category" data-id="6" data-parent-id="2" data-has-sb="1">CLOTHING</Link>
                                                            <ul>
                                                                <li><Link to="/category?sub_category=WOMEN&subSubCategory=CLOTHING&search=LADIES%20DRESSES" id="nav_main_category_22" className="nav-main-category " data-id="22" data-parent-id="6" data-has-sb="0">LADIES DRESSES</Link></li>
                                                                <li><Link to="/category?sub_category=WOMEN&subSubCategory=CLOTHING&search=FEMALE%20TOPS" id="nav_main_category_22" className="nav-main-category " data-id="22" data-parent-id="6" data-has-sb="0">FEMALE TOPS</Link></li>
                                                                <li><Link to="/category?sub_category=WOMEN&subSubCategory=CLOTHING&search=FEMALE%20TROUSERS%20%26%20SHORTS" id="nav_main_category_23" className="nav-main-category " data-id="23" data-parent-id="6" data-has-sb="0">FEMALE TROUSERS AND SHORTS</Link></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Link to="/category?sub_category=WOMEN&search=FOOTWEAR" id="nav_main_category_7" className="second-category nav-main-category" data-id="7" data-parent-id="2" data-has-sb="1">FOOTWEAR</Link>
                                                            <ul>
                                                                <li><Link to="/category?sub_category=WOMEN&subSubCategory=FOOTWEAR&search=SHOES%20%26%20HEELS" id="nav_main_category_29" className="nav-main-category " data-id="29" data-parent-id="7" data-has-sb="0">SHOES &amp; HEELS</Link></li>
                                                                <li><Link to="/category?sub_category=WOMEN&subSubCategory=FOOTWEAR&search=SNEAKERS" id="nav_main_category_30" className="nav-main-category " data-id="30" data-parent-id="7" data-has-sb="0">SNEAKERS</Link></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4 col-category-images">
                                            <div className="nav-category-image">
                                            <Link to="/category?sub_category=WOMEN&search=FOOTWEAR">
                                                <img src={D} data-src={D} alt="FOOTWEAR" className="img-fluid ls-is-cached lazyloaded" />
                                                <span>FOOTWEAR</span>
                                            </Link>
                                            </div>
                                            <div className="nav-category-image">
                                            <Link to="/category?sub_category=WOMEN&search=ACCESSORIES">
                                                <img src={E} data-src={E} alt="ACCESSORIES" className="img-fluid lazyloaded" />
                                                <span>ACCESSORIES</span>
                                            </Link>
                                            </div>
                                            <div className="nav-category-image">
                                            <Link to="/category?sub_category=WOMEN&search=CLOTHING">
                                                <img src={F} data-src={F} alt="CLOTHING" className="img-fluid lazyloaded" />
                                                <span>CLOTHING</span>
                                            </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                                    
                            <li className="nav-item dropdown" data-category-id="8" onMouseEnter={(e) => {handleDropdownEnter('brands'); handleMouseEnter(e);}} onMouseLeave={(e) => {handleDropdownLeave('brands'); handleMouseLeave(e);}}>
                                <Link to="/category?sub_category=BRANDS" id="nav_main_category_8" className="nav-link dropdown-toggle nav-main-category" data-id="8" data-parent-id="0" data-has-sb="1">BRANDS</Link>
                                <div id="mega_menu_content_8" className="dropdown-menu mega-menu-content" style={{ display: dropdownVisibility.brands ? "block" : "none" }}>
                                    <div className="row">
                                        <div className="col-8 menu-subcategories col-category-links">
                                            <div className="card-columns">
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Link to="/category?sub_category=BRANDS&search=Hermès" id="nav_main_category_36" className="second-category nav-main-category" data-id="36" data-parent-id="8" data-has-sb="0">Hermès</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Link to="/category?sub_category=BRANDS&search=NIKE" id="nav_main_category_34" className="second-category nav-main-category" data-id="34" data-parent-id="8" data-has-sb="0">NIKE</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Link to="/category?sub_category=BRANDS&search=ADIDAS" id="nav_main_category_33" className="second-category nav-main-category" data-id="33" data-parent-id="8" data-has-sb="0">ADIDAS</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Link to="/category?sub_category=BRANDS&search=BIRKENSTOCK" id="nav_main_category_32" className="second-category nav-main-category" data-id="32" data-parent-id="8" data-has-sb="0">BIRKENSTOCK</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Link to="/category?sub_category=BRANDS&search=BURBERRY" id="nav_main_category_38" className="second-category nav-main-category" data-id="38" data-parent-id="8" data-has-sb="0">BURBERRY</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Link to="/category?sub_category=BRANDS&search=BALENCIAGA" id="nav_main_category_37" className="second-category nav-main-category" data-id="37" data-parent-id="8" data-has-sb="0">BALENCIAGA</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Link to="/category?sub_category=BRANDS&search=BINATONE" id="nav_main_category_39" className="second-category nav-main-category" data-id="39" data-parent-id="8" data-has-sb="0">BINATONE</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Link to="/category?sub_category=BRANDS&search=SILVERCREST" id="nav_main_category_40" className="second-category nav-main-category" data-id="40" data-parent-id="8" data-has-sb="0">SILVERCREST</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Link to="/category?sub_category=BRANDS&search=KENWOOD" id="nav_main_category_41" className="second-category nav-main-category" data-id="41" data-parent-id="8" data-has-sb="0">KENWOOD</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 col-category-images">
                                            <div className="nav-category-image">
                                                <Link to="/category?sub_category=BRANDS&search=BIRKENSTOCK">
                                                    <img src={G} data-src={G} alt="BIRKENSTOCK" className="img-fluid ls-is-cached lazyloaded" />
                                                    <span>BIRKENSTOCK</span>
                                                </Link>
                                            </div>
                                            <div className="nav-category-image">
                                                <Link to="/category?sub_category=BRANDS&search=NIKE">
                                                    <img src={H} data-src={H} alt="NIKE" className="img-fluid ls-is-cached lazyloaded" />
                                                    <span>NIKE</span>
                                                </Link>
                                            </div>
                                            <div className="nav-category-image">
                                                <Link to="/category?sub_category=BRANDS&search=BINATONE">
                                                    <img src={I} data-src={I} alt="BINATONE" className="img-fluid ls-is-cached lazyloaded" />
                                                    <span>BINATONE</span>
                                                </Link>
                                            </div>
                                            <div className="nav-category-image">
                                                <Link to="/category?sub_category=BRANDS&search=SILVERCREST">
                                                    <img src={J} data-src={J} alt="SILVERCREST" className="img-fluid ls-is-cached lazyloaded" />
                                                    <span>SILVERCREST</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                                    
                            <li className="nav-item dropdown" data-category-id="42" onMouseEnter={(e) => {handleDropdownEnter('homeAppliances'); handleMouseEnter(e);}} onMouseLeave={(e) => {handleDropdownLeave('homeAppliances'); handleMouseLeave(e);}}>
                                <Link to="/category?sub_category=HOME%20APPLIANCES" id="nav_main_category_42" className="nav-link dropdown-toggle nav-main-category" data-id="42" data-parent-id="0" data-has-sb="1">HOME APPLIANCES</Link>
                                <div id="mega_menu_content_42" className="dropdown-menu mega-menu-content" style={{ display: dropdownVisibility.homeAppliances ? "block" : "none" }}>
                                    <div className="row">
                                        <div className="col-8 menu-subcategories col-category-links">
                                            <div className="card-columns">
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Link to="/category?sub_category=HOME%20APPLIANCES&search=BLENDERS" id="nav_main_category_45" className="second-category nav-main-category" data-id="45" data-parent-id="42" data-has-sb="0">BLENDERS</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Link to="/category?sub_category=HOME%20APPLIANCES&search=COOKWARE%20SETS" id="nav_main_category_44" className="second-category nav-main-category" data-id="44" data-parent-id="42" data-has-sb="0">COOKWARE SETS</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Link to="/category?sub_category=HOME%20APPLIANCES&search=KITCHENWARES" id="nav_main_category_46" className="second-category nav-main-category" data-id="46" data-parent-id="42" data-has-sb="0">KITCHENWARES</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Link to="/category?sub_category=HOME%20APPLIANCES&search=HOME%20DECOR" id="nav_main_category_43" className="second-category nav-main-category" data-id="43" data-parent-id="42" data-has-sb="0">HOME DECOR</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 col-category-images">
                                            <div className="nav-category-image">
                                                <Link to="/category?sub_category=HOME%20APPLIANCES&search=BLENDERS">
                                                    <img src={K} data-src={K} alt="BLENDERS" className="img-fluid ls-is-cached lazyloaded" />
                                                    <span>BLENDERS</span>
                                                </Link>
                                            </div>
                                            <div className="nav-category-image">
                                                <Link to="/category?sub_category=HOME%20APPLIANCES&search=NON-STICK%20COOKWARE%20SETS">
                                                    <img src={L} data-src={L} alt="NON-STICK COOKWARE SETS" className="img-fluid ls-is-cached lazyloaded" />
                                                    <span>NON-STICK COOKWARE SETS</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                                    
                            <li className="nav-item dropdown" data-category-id="41" onMouseEnter={(e) => {handleDropdownEnter('electronicWearables'); handleMouseEnter(e);}} onMouseLeave={(e) => {handleDropdownLeave('electronicWearables'); handleMouseLeave(e);}}>
                                <Link to="/category?sub_category=ELECTRONIC%20WEARABLES" id="nav_main_category_41" className="nav-link dropdown-toggle nav-main-category" data-id="41" data-parent-id="0" data-has-sb="0">ELECTRONIC WEARABLES</Link>
                                <div id="mega_menu_content_41" className="dropdown-menu mega-menu-content" style={{ display: dropdownVisibility.electronicWearables ? "block" : "none" }}>
                                    <div className="row">
                                        <div className="col-8 menu-subcategories col-category-links">
                                            <div className="card-columns">
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Link to="/category?sub_category=ELECTRONIC%20WEARABLES&search=SMARTWATCHES" id="nav_main_category_55" className="second-category nav-main-category" data-id="55" data-parent-id="41" data-has-sb="0">SMARTWATCHES</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Link to="/category?sub_category=ELECTRONIC%20WEARABLES&search=AIRPODS" id="nav_main_category_54" className="second-category nav-main-category" data-id="54" data-parent-id="41" data-has-sb="0">AIRPODS</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Link to="/category?sub_category=ELECTRONIC%20WEARABLES&search=CAMERAS" id="nav_main_category_56" className="second-category nav-main-category" data-id="56" data-parent-id="41" data-has-sb="0">CAMERAS</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Link to="/category?sub_category=ELECTRONIC%20WEARABLES&search=HEADSETS" id="nav_main_category_53" className="second-category nav-main-category" data-id="53" data-parent-id="41" data-has-sb="0">HEADSETS</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 col-category-images">
                                            <div className="nav-category-image">
                                                <Link to="/category?sub_category=ELECTRONIC%20WEARABLES&search=SMARTWATCHES">
                                                    <img src={O} data-src={O} alt="SMARTWATCHES" className="img-fluid ls-is-cached lazyloaded" />
                                                    <span>SMARTWATCHES</span>
                                                </Link>
                                            </div>
                                            <div className="nav-category-image">
                                                <Link to="/category?sub_category=ELECTRONIC%20WEARABLES&search=HEADSETS">
                                                    <img src={M} data-src={M} alt="HEADSETS" className="img-fluid ls-is-cached lazyloaded" />
                                                    <span>HEADSETS</span>
                                                </Link>
                                            </div>
                                            <div className="nav-category-image">
                                                <Link to="/category?sub_category=ELECTRONIC%20WEARABLES&search=AIRPODS">
                                                    <img src={N} data-src={N} alt="EARBUDS" className="img-fluid ls-is-cached lazyloaded" />
                                                    <span>AIRPODS</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                                    
                            <li className="nav-item dropdown" data-category-id="47" onMouseEnter={(e) => {handleDropdownEnter('kidsArena'); handleMouseEnter(e);}} onMouseLeave={(e) => {handleDropdownLeave('kidsArena'); handleMouseLeave(e);}}>
                                <Link to="/category?sub_category=KIDS%20ARENA" id="nav_main_category_47" className="nav-link dropdown-toggle nav-main-category" data-id="47" data-parent-id="0" data-has-sb="0">KIDS ARENA</Link>
                                <div id="mega_menu_content_47" className="dropdown-menu mega-menu-content" style={{ display: dropdownVisibility.kidsArena ? "block" : "none" }}>
                                    <div className="row">
                                        <div className="col-8 menu-subcategories col-category-links">
                                            <div className="card-columns">
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Link to="/category?sub_category=KIDS%20ARENA&search=ART%20SETS" id="nav_main_category_65" className="second-category nav-main-category" data-id="65" data-parent-id="47" data-has-sb="0">ART SETS</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Link to="/category?sub_category=KIDS%20ARENA&search=TOYS%20%26%20GAMES" id="nav_main_category_64" className="second-category nav-main-category" data-id="64" data-parent-id="47" data-has-sb="0">TOYS & GAMES</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Link to="/category?sub_category=KIDS%20ARENA&search=KIDS%20CLOTHING" id="nav_main_category_66" className="second-category nav-main-category" data-id="66" data-parent-id="47" data-has-sb="0">KIDS CLOTHING</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Link to="/category?sub_category=KIDS%20ARENA&search=KIDS%20SHOES" id="nav_main_category_63" className="second-category nav-main-category" data-id="63" data-parent-id="47" data-has-sb="0">KIDS SHOES</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 col-category-images">
                                            <div className="nav-category-image">
                                                <Link to="/category?sub_category=KIDS%20ARENA&search=ART%20SETS">
                                                    <img src={P} data-src={P} alt="ART SET" className="img-fluid ls-is-cached lazyloaded" />
                                                    <span>ART SETS</span>
                                                </Link>
                                            </div>
                                            <div className="nav-category-image">
                                                <Link to="/category?sub_category=KIDS%20ARENA&search=KIDS%20CLOTHING">
                                                    <img src={Q} data-src={Q} alt="KIDS CLOTHING" className="img-fluid ls-is-cached lazyloaded" />
                                                    <span>KIDS CLOTHING</span>
                                                </Link>
                                            </div>
                                            <div className="nav-category-image">
                                                <Link to="/category?sub_category=KIDS%20ARENA&search=KIDS%20SHOES">
                                                    <img src={R} data-src={R} alt="KIDS SHOES" className="img-fluid ls-is-cached lazyloaded" />
                                                    <span>KIDS SHOES</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                       
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DesktopMenuBar;