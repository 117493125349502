import React from "react";
import SyncLoader from "react-spinners/SyncLoader";
import "./Loader.css";

const Loader = ({ loading = false, color = "var(--vr-color-main)", size = 15 }) => {
    return (
        <div className="loader-container">
            {loading && (
                <SyncLoader color={color} loading={loading} size={size} />
            )}
        </div>
    );
};

export default Loader;