import React from "react";
import { Link } from "react-router-dom";
import A from "../../assets/img/logo.png";
import Footer from "../../components/footer/Footer";
import axios from "axios";
import { useEffect, useState } from "react";

const AdminDash = () => {
  const [dashboardStats, setDashboardStats] = useState(null);
  const [token, setToken] = useState(null);
  const [user_id, setUserId] = useState(null);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    const user = sessionStorage.getItem("user_id");
    setUserId(user);

    setToken(tk);
  }, []);

  const logOut = () => {
    sessionStorage.clear();
    window.location.href = "/";
  };

  const getDashboardStats = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_Base_url}/admin/dashboard_info`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (response.data.status === 200) {
        setDashboardStats(response.data);
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error('Error fetching dashboard stats:', error);
    }
  };

  
  return (
    <>
    {/* Navigation Bar*/}
      <div className="container">
        <div className="row">
           <div className="col-lg-3">
             <div className="header__logo">
               <a href="/">
                 <img src={A} alt="" width="auto" height="35px" />
               </a>
             </div>
           </div>
        </div>
      </div>
      {/*end header*/}

      {/* Start home */}
      <section className="bg-half page-next-level">
        <div className="bg-overlay" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="text-center text-white">
                <h4 className="text-uppercase title mb-4">Admin Dashboard</h4>
                <ul className="page-next d-inline-block mb-0">
                  <li>
                    <a href="/" className="text-uppercase font-weight-bold">
                      Home
                    </a>
                  </li>
                  <li>
                    <span className="text-uppercase text-white font-weight-bold">
                      Dashboard
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end home */}

      {/* Admin dashboard begins */}
      <section className="section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="rounded shadow bg-white p-4">
                <div className="col-lg-6 d-flex justify-content-center">
                  <div className="buy-button">
                    <Link to="/product-inactive" className="btn btn-primary">
                      <i className="mdi tooltip-check" /> Inactive Products
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 d-flex justify-content-center">
                  <div className="buy-button">
                    <Link to="/product-active" className="btn btn-primary">
                      <i className="mdi tooltip-check" /> Active Products
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 d-flex justify-content-center">
                  <div className="buy-button">
                    <button className="btn btn-primary" onClick={getDashboardStats}>
                      <i className="mdi tooltip-check" /> Dashboard Info
                    </button>
                    <div className="col-lg-6 d-flex justify-content-center">
                    {dashboardStats && (
                      <div>
                        <p>Total Admins: {dashboardStats.total_Admins}</p>
                        <p>Total Sellers: {dashboardStats.total_Sellers}</p>
                        <p>Total Shoppers: {dashboardStats.total_Shoppers}</p>
                        <p>Total Products: {dashboardStats.total_Products}</p>
                        <p>Total Active Products: {dashboardStats.total_ActiveProducts}</p>
                        <p>Total Inactive Products: {dashboardStats.total_InactiveProducts}</p>
                      </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 d-flex align-items-center justify-content-end">
                  <div className="ml-auto">
                  {token && (
                    <button className="btn btn-danger" onClick={logOut}>
                      Logout
                    </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Admin dashboard ends */}
      
      {/* footer start */}
       <Footer />
      {/* footer end */}
    </>
  );
};

export default AdminDash;
