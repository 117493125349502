import React, { useState, useEffect } from 'react';

const MobileResponsiveRegister = ({ submissionSuccess, currentStep, TOTAL_STEPS }) => {
    const [progressBarHeight, setProgressBarHeight] = useState(0); // Height for the progress bar
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(false); // Track if the keyboard is open on mobile devices
    const [originalViewportHeight, setOriginalViewportHeight] = useState(window.innerHeight); // Track original viewport height

    // Calculate the width percentage of the progress bar based on the current step
    const calculateProgressWidth = () => {
        const steps = TOTAL_STEPS; // Define total steps in the form
        if (currentStep === 1) {
            return '0%'; // On the first step, the progress bar width should be 0%
        }
        return `${((currentStep - 1) / (steps - 1)) * 100}%`; // Start progress from the second step
    };

    // Calculate dynamic padding based on form state and keyboard visibility
    const calculateDynamicPadding = () => {
        return submissionSuccess ? 312.375 : isKeyboardOpen ? 50 : 200; // Reduce padding when keyboard is open
    };

    // Dynamic form height (from 65px to 0px after submission)
    const calculateDynamicFormHeight = () => {
        return submissionSuccess ? 0 : 65; // 65px when form is visible, 0px when hidden
    };

    // Function to calculate dynamic height for the progress bar
    const calculateProgressBarHeight = () => {
        const totalHeight = window.innerHeight;
        const screenWidth = window.innerWidth;
        const headerHeight = screenWidth >= 992 ? 172 : 66; // Larger screens: 172px, smaller: 66px
        const formHeight = calculateDynamicFormHeight();
        const padding = calculateDynamicPadding();

        const remainingHeight = totalHeight - (headerHeight + formHeight + padding);
        // Only fill up the height before submission success, otherwise set height to 0 after submission
        // Ensure that progressBarHeight never goes below 0
        setProgressBarHeight(Math.max(remainingHeight, 0));
    };

    // Handle keyboard events on mobile devices
    const handleResize = () => { 
        const newViewportHeight = window.innerHeight; 
        if (newViewportHeight < originalViewportHeight) { 
            // Keyboard is open 
            setIsKeyboardOpen(true); 
        } else { 
            // Keyboard is closed 
            setIsKeyboardOpen(false); 
        } 
            calculateProgressBarHeight(); // Always recalculate the height 
    };

    useEffect(() => {
        // Store the initial viewport height
        setOriginalViewportHeight(window.innerHeight);

        // Track window resize events
        window.addEventListener('resize', handleResize);

        // Cleanup listener on unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Recalculate the progress bar height on form step change
    useEffect(() => {
        calculateProgressBarHeight();
    }, [currentStep, isKeyboardOpen, submissionSuccess]);

    return (
        <div>
            <div
                className="c-form__progress progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                style={{
                    height: `${progressBarHeight}px`, // Set the calculated height dynamically
                    width: submissionSuccess ? '100%' : calculateProgressWidth(),
                }}
            />
        </div>
    );
};

export default MobileResponsiveRegister;