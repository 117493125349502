import React, { useEffect, useState } from 'react';
import axios from 'axios';
import StarRating from '../../components/rating/StarRating';

const Stars = ({ productId }) => {
    const [reviews, setReviews] = useState([]);
    const [averageRating, setAverageRating] = useState(0);

    // fetches reviews from backend
    const fetchReviews = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_Base_url}/products/get_reviews?productId=${productId}`);
            setReviews(res.data.reviews);
            setAverageRating(res.data.averageRating);
        } catch (error) {
            console.error('Error fetching reviews:', error);
        }
    };

    useEffect(() => {
        fetchReviews();
    }, [productId]);

    return (
        <>
            <StarRating rating={averageRating} onRatingChange={() => {}} />
        </>
    );
};

export default Stars;