import React, { useEffect, useState } from 'react';
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import CategoriesTop from "../../components/categories/CategoriesTop";
import CategoriesBottom from "../../components/categories/CategoriesBottom";
import Banner from "../../components/banner/Banner";
import Products from "../../components/products/Products";
import ProductsThrift from "../../components/products/ProductsThrift";
import HeroPage from "../../components/hero section/HeroPage";
import Slider from "../../components/slider/Slider";


const Dashboard = () => {
    const [isSellerLoggedIn, setIsSellerLoggedIn] = useState(false);
    const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);
    const [sellerName, setSellerName] = useState('');

    useEffect(() => {
        const sellerNameFromStorage = sessionStorage.getItem("seller_name"); // Check for seller's name in session storage

        if (sellerNameFromStorage) {
            // Seller is logged in
            setIsSellerLoggedIn(true);
            setSellerName(sellerNameFromStorage);
            setShowWelcomeMessage(true);

            // Hide the welcome message after 3 seconds
            const timer = setTimeout(() => {
                setShowWelcomeMessage(false);
            }, 3000);

            // Cleanup the timer on component unmount
            return () => clearTimeout(timer);
        } else {
            // No seller is logged in
            setIsSellerLoggedIn(false);
        }
    }, []);
  
    return (
        <>
            
            <Header />

            <div id="menu-overlay" />

            <Slider />

            <CategoriesTop />

            <Banner />

            <ProductsThrift />

            <HeroPage />

            <Products />

            <CategoriesBottom />

            <Footer />
            
            {showWelcomeMessage && (
                <div className="seller-status">
                    <h2>Welcome, {sellerName}!</h2>
                </div>
            )}
        </>
    );
};

export default Dashboard;
