import React from "react";
import Footer from "../../components/footer/Footer";
import HeaderCopy from "../../components/header/HeaderCopy";

const Help = () => {

  return (
    <>
      <HeaderCopy />

      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="support">
              <nav class="nav-breadcrumb" aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Help Center</li>
                </ol>
              </nav>
              <h1 class="page-title main-title"><strong>How can we help?</strong></h1>
              <div class="row">
                <div class="col-12">
                  <div class="search-container">
                    <div class="search">
                      <form action="/#" method="get">
                        <input type="text" name="q" class="form-control form-input" placeholder="Search" required="" />
                          <button type="submit">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#222222" viewBox="0 0 16 16" class="mds-svg-icon">
                              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                            </svg>
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <a href="/payment" class="a-box-support">
                      <div class="box-support">
                        <h3 class="title">PAYMENT</h3>
                          <span>2 Articles</span>
                      </div>
                    </a>
                  </div>
                  <div class="col-sm-4">
                    <a href="/orders" class="a-box-support">
                      <div class="box-support">
                        <h3 class="title">ORDERS</h3>
                          <span>1 Articles</span>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="need-more-help">
                      <h3 class="title">Need more help?</h3>
                      <span class="text-muted">If you didn't find what you were looking for, you can submit a support request here.</span>
                        <a href="/contact" class="btn btn-lg btn-custom">
                          <svg width="18" height="18" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" fill="#fff" class="mds-svg-icon">
                            <path d="M896 0q182 0 348 71t286 191 191 286 71 348-71 348-191 286-286 191-348 71-348-71-286-191-191-286-71-348 71-348 191-286 286-191 348-71zm0 128q-190 0-361 90l194 194q82-28 167-28t167 28l194-194q-171-90-361-90zm-678 1129l194-194q-28-82-28-167t28-167l-194-194q-90 171-90 361t90 361zm678 407q190 0 361-90l-194-194q-82 28-167 28t-167-28l-194 194q171 90 361 90zm0-384q159 0 271.5-112.5t112.5-271.5-112.5-271.5-271.5-112.5-271.5 112.5-112.5 271.5 112.5 271.5 271.5 112.5zm484-217l194 194q90-171 90-361t-90-361l-194 194q28 82 28 167t-28 167z"></path>
                          </svg>
                           Contact Support
                        </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
      
    <Footer />
    </>
  );
};

export default Help;
