import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, Link } from 'react-router-dom';
import Footer from "../../components/footer/Footer";
import HeaderCopy from "../../components/header/HeaderCopy";
import { ClipLoader } from "react-spinners";

const ChangePassword = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null);
    const [errorMessage, setErrorMessage] = useState(''); // State for error messages
    const [successMessage, setSuccessMessage] = useState(''); // State for success messages
    const [formData, setFormData] = useState({
        old_password: '',
        password: '',
        password_confirm: '',
    });

    // retrieve seller's credentials from session storage
    useEffect(() => {
        const tk = sessionStorage.getItem("seller_token");
        setToken(tk);
    }, []);

    // handles changing form fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleJwtExpired = () => {
        setErrorMessage('Session Expired', 'Your session has expired. Please log in again.');
        sessionStorage.clear();
        navigate("/");
    };

    // handles form submitting
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage(''); // Reset error message
        setSuccessMessage(''); // Reset success message
    
        // Check if passwords match
        if (formData.password !== formData.password_confirm) {
            setErrorMessage('The Confirm Password field does not match the Password field.');
            setLoading(false);
            return;
        }
    
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_Base_url}/sellers/change_password`, 
                {
                    oldPassword: formData.old_password,
                    newPassword: formData.password,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            // Check if the response contains a JWT expiration message
            if (response.data && response.data.message === "jwt expired") {
                handleJwtExpired();
                return;
            }
    
            setLoading(false);
            if (response.status === 200) {
                setSuccessMessage(response.data.message); // Set success message
                // Clear form data after successful password change
                setFormData({
                    old_password: '',
                    password: '',
                    password_confirm: ''
                });
            }
        } catch (error) {
            setLoading(false);
            if (error.response) {
                // Check for JWT expiration
                if (error.response.status === 401 && error.response.data.message === "jwt expired") {
                    handleJwtExpired();
                } else {
                    setErrorMessage(error.response.data.message || 'An error occurred');
                }
            } else {
                setErrorMessage('Network error');
            }
            console.error('Error changing password:', error);
        }
    };


    return (
        <>
            <HeaderCopy />

            {/* Change Seller Password start */}
            <div id="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="nav-breadcrumb" aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/settings">Profile Settings</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Change Password</li>
                                </ol>
                            </nav>
                            <h1 className="page-title">Profile Settings</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-3">
                            <div className="row-custom">
                                <div className="sidebar-tabs">
                                    <ul className="nav">
                                        <li className="nav-item">
                                            <Link to="/settings" className="nav-link">
                                                <div class="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <g fill="none" fill-rule="evenodd">
                                                            <path d="M24 0v24H0V0h24ZM12.594 23.258l-.012.002-.071.035-.02.004-.014-.004-.071-.036c-.01-.003-.019 0-.024.006l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.016-.018Zm.264-.113-.014.002-.184.093-.01.01-.003.011.018.43.005.012.008.008.201.092c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.003-.011.018-.43-.003-.012-.01-.01-.184-.092Z"></path>
                                                            <path fill="#333333" d="M18.884 14.469a1 1 0 0 1 1.784.896l-.052.104-.335.58a3.016 3.016 0 0 1 .482.782l.066.169h.671a1 1 0 0 1 .117 1.993L21.5 19h-.671a3 3 0 0 1-.41.776l-.138.174.335.581a1 1 0 0 1-1.668 1.098l-.064-.098-.335-.58c-.293.054-.59.063-.88.03l-.217-.032-.336.582a1 1 0 0 1-1.784-.896l.052-.104.335-.581a3.026 3.026 0 0 1-.482-.78l-.066-.17H14.5a1 1 0 0 1-.117-1.993L14.5 17h.672a3 3 0 0 1 .41-.776l.137-.174-.335-.581a1 1 0 0 1 1.668-1.098l.064.098.335.58c.293-.054.59-.063.88-.03l.217.031.336-.581ZM11 13c.447 0 .887.024 1.316.07a1 1 0 0 1-.211 1.989C11.745 15.02 11.375 15 11 15c-2.023 0-3.843.59-5.136 1.379-.647.394-1.135.822-1.45 1.222-.324.41-.414.72-.414.899 0 .122.037.251.255.426.249.2.682.407 1.344.582C6.917 19.858 8.811 20 11 20l.658-.005a1 1 0 0 1 .027 2L11 22c-2.229 0-4.335-.14-5.913-.558-.785-.208-1.524-.506-2.084-.956C2.41 20.01 2 19.345 2 18.5c0-.787.358-1.523.844-2.139.494-.625 1.177-1.2 1.978-1.69C6.425 13.695 8.605 13 11 13Zm7 4a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM11 2a5 5 0 1 1 0 10 5 5 0 0 1 0-10Zm0 2a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                                Update Profile
                                            </Link>
                                        </li>
                                        <li className="nav-item active">
                                            <Link to="/change-password" className="nav-link">
                                                <div class="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <g fill="none" fill-rule="nonzero">
                                                            <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z"></path>
                                                            <path fill="#333333" d="M12 2a6 6 0 0 1 5.996 5.775L18 8h1a2 2 0 0 1 1.995 1.85L21 10v10a2 2 0 0 1-1.85 1.995L19 22H5a2 2 0 0 1-1.995-1.85L3 20V10a2 2 0 0 1 1.85-1.995L5 8h1a6 6 0 0 1 6-6Zm7 8H5v10h14V10Zm-7 2a2 2 0 0 1 1.134 3.647l-.134.085V17a1 1 0 0 1-1.993.117L11 17v-1.268A2 2 0 0 1 12 12Zm0-8a4 4 0 0 0-4 4h8a4 4 0 0 0-4-4Z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                                Change Password
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-sm-12 col-md-9">
                            <div className="row-custom">
                                <div className="profile-tab-content">
                                    {/* Display error and success messages here */}
                                    {errorMessage && (
                                        <div className="m-b-15">
                                            <div className="alert alert-danger alert-dismissable alert-messages">
                                                <ul>
                                                    <li>{errorMessage}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                    {successMessage && (
                                        <div className="m-b-15">
                                            <div className="alert alert-success alert-dismissable alert-messages">
                                                <ul>
                                                    <li>{successMessage}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                    <form onSubmit={handleSubmit}>                                                            
                                        <div className="form-group">
                                            <label className="control-label">Old Password</label>
                                            <input 
                                                type="password" 
                                                name="old_password" 
                                                className="form-control form-input" 
                                                value={formData.old_password} 
                                                placeholder="Old Password" 
                                                onChange={handleChange} 
                                                //required 
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="control-label">New Password</label>
                                            <input 
                                                type="password" 
                                                name="password" 
                                                className="form-control form-input" 
                                                value={formData.password} 
                                                placeholder="New Password" 
                                                minlength="8" maxlength="255" 
                                                onChange={handleChange} 
                                                required 
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="control-label">Confirm Password</label>
                                            <input 
                                                type="password" 
                                                name="password_confirm" 
                                                className="form-control form-input" 
                                                value={formData.password_confirm} 
                                                placeholder="Confirm Password" 
                                                maxlength="255" 
                                                onChange={handleChange} 
                                                required 
                                            />
                                        </div>
                                        <button 
                                            type="submit" 
                                            className="btn btn-md btn-custom"
                                            disabled={loading}
                                            
                                        >
                                            {loading ? ( 
                                                <ClipLoader color="#ffffff" loading={loading} size={20} />
                                            ) : (
                                                "Change Password"
                                            )}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Change Seller's Password */}

            <Footer />
        
        </>
    );
};

export default ChangePassword;