import React, { useState } from "react";
import axios, { AxiosError } from "axios";
import { TextField, FormControl, Button } from "@mui/material";
import Icon from "../icon/Icon";
import FacebookLoginComponent from "../../components/connect/FacebookLoginComponent";
import GoogleLoginComponent from "../../components/connect/GoogleLoginComponent";
import { GoogleOAuthProvider } from '@react-oauth/google';

interface LoginResponse {
    message: string; // Define other properties if necessary
}

interface LoginModalProps {
    loginType: "shoppers" | "sellers"; // Determine login type via props
}
  

const LoginModal: React.FC<LoginModalProps> = ({ loginType }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [identifier, setIdentifier] = useState("");
    const [identifierError, setIdentifierError] = useState(false);
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [showIcon, setShowIcon] = useState(false);
    const [loginMessage, setLoginMessage] = useState("");
    const [loginMessageType, setLoginMessageType] = useState(""); // "success" or "error"

    // toggles the eye icon when user starts inputting
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPassword(value);
        setShowIcon(value.length > 0); // Show icon when user starts typing
    };

    // Toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Handles form submission for Shoppers
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitting(true);
        setIdentifierError(false);
        setPasswordError(false);
    
        // Basic validation
        if (!identifier) setIdentifierError(true);
        if (!password) setPasswordError(true);
        
        // Check if the user is a shopper and clear only shopper-related items
        const shopperToken = sessionStorage.getItem("shopper_token");
        if (shopperToken) {
            // Clear shopper session storage items only
            sessionStorage.removeItem("shopper_token");
            sessionStorage.removeItem("shopper_name"); // Clear other shopper-related items
            // You can keep other items like userDeviceId intact
        }

        // Only proceed if there are no validation errors
        if (identifier && password) {
          const loginUrl = loginType === "shoppers"
            ? `${process.env.REACT_APP_Base_url}/shoppers/login`
            : `${process.env.REACT_APP_Base_url}/sellers/login`;
    
          axios
            .post(loginUrl, { identifier, password })
            .then((res) => {
              if (res.data.status === 200) {
                // Display success message
                setLoginMessage("Login successful!");
                setLoginMessageType("success");
    
                // Store token and user info (depending on type)
                if (loginType === "shoppers") {
                    sessionStorage.setItem("shopper_token", res.data.token);
                    sessionStorage.setItem("shopper_name", res.data.user.first_name);
                } else if (loginType === "sellers") {
                    sessionStorage.setItem("seller_token", res.data.token);
                    sessionStorage.setItem("company_name", res.data.user.company_name);
                    sessionStorage.setItem("profile_picture", res.data.user.profile_picture);
                    const fullName = `${res.data.user.first_name} ${res.data.user.last_name}`;
                    sessionStorage.setItem("seller_name", fullName);
                }
    
                // Reload the page after successful login
                window.location.reload(); // This will refresh the page
              } else if (res.data.needActivation) {
                setIsSubmitting(false);
                setLoginMessage(res.data.message);
                setLoginMessageType("warning");
              }
            })
            .catch((err) => {
              setIsSubmitting(false);
    
                const axiosError = err as AxiosError;
                if (axiosError.response && axiosError.response.data) {
                    const responseData = axiosError.response.data as LoginResponse;
                    setLoginMessage(responseData.message);
                    setLoginMessageType("error");
                } else {
                    setLoginMessage("An unexpected error occurred.");
                    setLoginMessageType("error");
                }
            });
        } else {
          setIsSubmitting(false);
        }
    };
    
    return (
        <>  
            {/* Modal content for shopper login session*/}
            <div className="modal fade" id="loginModal" tabIndex={-1} role="dialog" aria-labelledby="LoginModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered login-modal" role="document">
                    <div className="modal-content">
                        <div className="auth-box">
                            <button 
                                type="button" 
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <Icon name="MdClose" size="1em" />
                            </button>
                            <h4 className="title">Login</h4>
                            <React.Fragment>
                                <form id="form_login" autoComplete="off" onSubmit={handleSubmit}>
                                    <div className="social-login">
                                        <FacebookLoginComponent />
                                        <GoogleOAuthProvider clientId="478207131719-sbloltvpbpr0378ncg56hji5jo7tv9gj.apps.googleusercontent.com">
                                            <GoogleLoginComponent loginType={loginType} />
                                        </GoogleOAuthProvider>
                                        <div className="form-group m-b-0">
                                            <p className="p-social-media">Or login with email/phone</p>
                                        </div>
                                    </div>
                                    <div id="result-login" className={`font-size-13 ${loginMessageType === 'success' ? 'text-success' : loginMessageType === 'error' ? 'text-danger' : ''}`}>
                                        {loginMessage && (
                                            <div>
                                                {loginMessage}
                                            </div>
                                        )}
                                    </div>
                                    <div id="confirmation-result-login" className={`font-size-13 ${loginMessageType === 'warning' ? 'text-warning' : ''}`}>
                                        {loginMessageType === "warning" && (
                                            <div>
                                                {loginMessage}
                                            </div>
                                        )}
                                    </div>
                                    <FormControl fullWidth margin="normal">
                                        <TextField 
                                            label="Email or phone"
                                            onChange={e => setIdentifier(e.target.value)}
                                            variant="outlined"
                                            color="secondary"
                                            type="text"
                                            size="small"
                                            value={identifier}
                                            error={identifierError}
                                            helperText={identifierError ? "Email or phone is required" : ""}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth margin="normal">
                                        <TextField 
                                            label="Password"
                                            onChange={handleChange}
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            type={showPassword ? "text" : "password"}
                                            value={password}
                                            error={passwordError}
                                            helperText={passwordError ? "Password is required" : ""}
                                            InputProps={{
                                                endAdornment: showIcon && ( // Only show the icon if showIcon is true
                                                    <span onClick={togglePasswordVisibility} style={{ cursor: 'pointer', marginLeft: '8px' }}>
                                                        {showPassword ? <Icon name="VscEyeClosed" size="1.5em" /> : <Icon name="VscEye" size="1.5em"/>}
                                                    </span>
                                                ),
                                            }}
                                        />
                                    </FormControl>
                                    <div className="form-group text-right">
                                        <a href="/forgot-password" className="link-forgot-password">Forgot Password?</a>
                                    </div>
                                    <Button 
                                        variant="outlined" 
                                        color="secondary" 
                                        type="submit"
                                        fullWidth
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? (
                                            <>
                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                <span className="visually-hidden">Logging in...</span>
                                            </>
                                        ) : (
                                            "Login"
                                        )}
                                    </Button>
                                    <p className="p-social-media m-0 m-t-5">
                                        Need an account? 
                                        <a href={loginType === "shoppers" ? "/register?role=shopper" : "/register?role=seller"} className="link">Register</a>
                                    </p>  
                                </form>
                            </React.Fragment>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoginModal;
