import React from 'react';
import FacebookLogin from 'react-facebook-login';

const FacebookLoginComponent = () => {
    const handleFacebookLogin = (response) => {
        console.log('Facebook login success:', response);
    };

    let fbLogin;

    return (
        <div>
            {/* Hidden Facebook login button for programmatic login */}
            <FacebookLogin
                appId="2309904756009280"
                fields="name,email,picture"
                callback={handleFacebookLogin}
                cssClass="hidden"  // Hides the default button
                ref={(component) => (fbLogin = component)}
            />
            {/* Custom button to trigger Facebook login */}
            <a className="btn btn-social btn-social-facebook" onClick={() => fbLogin && fbLogin.onClick()}>
                <svg width="24" height="24" viewBox="0 0 14222 14222">
                    <circle cx="7111" cy="7112" r="7111" fill="#ffffff"></circle>
                    <path d="M9879 9168l315-2056H8222V5778c0-562 275-1111 1159-1111h897V2917s-814-139-1592-139c-1624 0-2686 984-2686 2767v1567H4194v2056h1806v4969c362 57 733 86 1111 86s749-30 1111-86V9168z" fill="#1877f2"></path>
                </svg>
                <span>Connect with Facebook</span>
            </a>
        </div>
    );
};

export default FacebookLoginComponent;