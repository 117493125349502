import React from "react";
import Footer from "../../components/footer/Footer";
import HeaderCopy from "../../components/header/HeaderCopy";

const Orders = () => {

  return (
    <>
      <HeaderCopy />

      <div id="wrapper">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="support">
                <nav class="nav-breadcrumb" aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item"><a href="/help">Help Center</a></li>
                    <li class="breadcrumb-item active" aria-current="page">ORDERS</li>
                  </ol>
                </nav>
                <h1 class="title-category">ORDERS</h1>
                <div class="row">
                  <div class="col-md-3 hide-mobile">
                    <div class="all-help-topics">
                      <a href="/help"><i class="icon-angle-left"></i>All Help Topics</a>
                    </div>
                    <ul class="ul-support-articles">
                      <li class="active"><a href="/orders">HOW DO I PLACE AN ORDER?</a></li>
                    </ul>
                  </div>
                  <div class="col-sm-12 col-lg-9">
                    <div class="help-center-collapse">
                      <a href="#related_help_topics" data-toggle="collapse" class="collapse-title collapsed" aria-expanded="false">
                        Related Help Topics
                        <div class="float-right">
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="mds-svg-icon" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path>
                          </svg>
                        </div>
                      </a>
                      <div id="related_help_topics" class="collapse">
                        <ul class="ul-support-articles">
                          <li class="active"><a href="/orders">HOW DO I PLACE AN ORDER?</a></li>
                        </ul>
                      </div>
                    </div>
                    <h2 class="article-title">HOW DO I PLACE AN ORDER?</h2>
                    <div class="article-content">
                      <p>To place an order, simply browse our collection of products and click on the desired item.</p>
                      <p>Select the appropriate size, color, and quantity, and then click the "Add to Cart" button.</p>
                      <p>Once you have finished selecting desired products , proceed to the checkout page to review your order details.</p>
                      <p>Fill out shipping information with accurate information and add your phone number for clarification after order has been placed.</p>
                      <p>Then proceed and complete the payment process.</p>
                      <p>Place your Order!</p></div>
                    <div class="need-more-help need-more-help-article">
                    <h3 class="title">Still have questions?</h3>
                    <span class="text-muted">If you still have a question, you can submit a support request here.</span>
                     <a href="/contact" class="btn btn-lg btn-custom">
                       <svg width="18" height="18" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" fill="#fff" class="mds-svg-icon">
                         <path d="M896 0q182 0 348 71t286 191 191 286 71 348-71 348-191 286-286 191-348 71-348-71-286-191-191-286-71-348 71-348 191-286 286-191 348-71zm0 128q-190 0-361 90l194 194q82-28 167-28t167 28l194-194q-171-90-361-90zm-678 1129l194-194q-28-82-28-167t28-167l-194-194q-90 171-90 361t90 361zm678 407q190 0 361-90l-194-194q-82 28-167 28t-167-28l-194 194q171 90 361 90zm0-384q159 0 271.5-112.5t112.5-271.5-112.5-271.5-271.5-112.5-271.5 112.5-112.5 271.5 112.5 271.5 271.5 112.5zm484-217l194 194q90-171 90-361t-90-361l-194 194q28 82 28 167t-28 167z"></path>
                       </svg>
                        Contact Support
                     </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>
      
<Footer />
    </>
  );
};

export default Orders;
