import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import 'overlayscrollbars/styles/overlayscrollbars.css';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { CategoryProps } from "../../types/Types";

const ProductCategoryFilter = () => {
    const [categories, setCategories] = useState<CategoryProps[]>([]);

    // calling the categories api
    useEffect(() => {
        axios
        .get(`${process.env.REACT_APP_Base_url}/utils/get_product_categories`)
        .then((res) => {
            //console.log(res.data);
            if (res.data.status === 200) {
            setCategories(res.data.product_categories);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }, []);

    return (
        <div className="filter-item">
            <h4 className="title">Category</h4>
            <div className="filter-list-container">
                <OverlayScrollbarsComponent
                    defer
                    options={{
                        scrollbars: { autoHide: "leave", theme: "os-theme-dark" },
                    }}
                    style={{ height: '210px' }}
                >
                    <ul className="filter-list filter-custom-scrollbar filter-list-categories">
                        {categories &&
                            categories.map((category: CategoryProps) => (
                                <li key={category.id}>
                                    <Link to="/product-category" state={{ data: category.product_category_name }}>
                                        {category.product_category_name}
                                    </Link>
                                </li>
                            ))
                        }                                                 
                    </ul>
                </OverlayScrollbarsComponent>
            </div>
        </div>                    
    )
};

export default ProductCategoryFilter;