import React from "react";
import GridLoader from "react-spinners/GridLoader";
import "./Loader.css";

const Loader = ({ loading = false, color = "var(--vr-color-main)", size = 15, speedMultiplier = 0.5, }) => {
    return (
        <div className="loader-container">
            {loading && (
                <GridLoader color={color} loading={loading} size={size} />
            )}
        </div>
    );
};

export default Loader;