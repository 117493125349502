// src/components/ReportSellerModal.tsx
import React from 'react';
import Icon from "../icon/Icon";

const ReportSellerModal = () => {
    return (
        <div className="modal fade" id="reportSellerModal" tabIndex={-1} aria-labelledby="reportSellerModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content modal-custom modal-report-abuse">
                    <form id="form_report_seller">
                        <div className="modal-header">
                            <h5 className="modal-title">Report this seller</h5>
                            <button 
                                type="button" 
                                className="close" 
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true"><Icon name="MdClose" /> </span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div id="response_form_report_seller" className="col-12">
                                    {/* error and success messages here */}
                                </div>
                                <div className="col-12">
                                    <div className="form-group m-0">
                                        <label className="control-label">Description</label>
                                        <textarea name="description" className="form-control form-textarea" placeholder="Briefly describe the issue you're facing" minLength={5} maxLength={10000} required></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer text-right">
                            <button type="submit" className="btn btn-md btn-custom">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ReportSellerModal;