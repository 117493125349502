import React from "react";
import { NavLink } from 'react-router-dom';
import user from "../../assets/img/user.png";
import logo from "../../assets/img/logo.png";
import 'overlayscrollbars/styles/overlayscrollbars.css';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Icon from "../icon/Icon";

const Sidebar = () => {
    // get user credentials from session storage
    const SellerName = sessionStorage.getItem("seller_name");
    const ProfilePicture = sessionStorage.getItem("profile_picture");

    return (
        <aside className="main-sidebar">
            <section className="sidebar">
                <OverlayScrollbarsComponent
                    defer
                    options={{
                        scrollbars: { autoHide: "leave", theme: "os-theme-dark" },
                    }}
                    style={{ height: '100vh' }}
                >
                    <div className="logo">
                        <a href="/seller-dashboard">
                            <img src={logo} alt="logo" style={{ width: '25px' }} />
                        </a>
                    </div>
                    <div className="user-panel">
                        <div className="image">
                            <img src={ProfilePicture || user} className="img-circle" alt="" />
                        </div>
                        <div className="username">
                            <p>Hi, {SellerName}</p>
                        </div>
                    </div>
                    
                    <ul className="sidebar-menu tree" data-widget="tree">                
                        <li className="header">Navigation</li>
                        <li className="nav-home">
                            <NavLink to="/seller-dashboard">
                                <Icon name="FaHome" style={{ color: '#687088', fontSize: '16px', width: '24px', position: 'relative', top: '-1px' }} />
                                <span>Dashboard</span>
                            </NavLink>
                        </li>
                        <li className="header">Products</li>
                        <li className="nav-add-product">
                            <NavLink to="post-product">
                                <Icon name="FaFile" style={{ color: '#687088', fontSize: '16px', width: '24px', position: 'relative', top: '-1px' }} />
                                <span>Add Product</span>
                            </NavLink>
                        </li>
                        <li className="nav-add-product">
                            <NavLink to="seller-products">
                                <Icon name="FaShoppingBasket" style={{ color: '#687088', fontSize: '16px', width: '24px', position: 'relative', top: '-1px' }} />
                                <span>Products</span>
                            </NavLink>
                        </li>           
                        <li className="header">Sales</li>
                        <li class="nav-sales">
                            <NavLink to="#">
                                <Icon name="IoCart" style={{ color: '#687088', fontSize: '16px', width: '24px', position: 'relative', top: '-1px' }} />
                                <span>Orders</span>
                            </NavLink>
                        </li>            
                        <li className="header">Messages</li>
                        <li className="nav-comments">
                            <NavLink to="#" activeClassName="active">
                                <Icon name="FaComments" style={{ color: '#687088', fontSize: '16px', width: '24px', position: 'relative', top: '-1px' }} />
                                <span>Messages</span>
                            </NavLink>
                        </li>            
                        <li className="header">Settings</li>
                        <li className="nav-shop-settings">
                            <NavLink to="shop-settings/:sellerId">
                                <Icon name="FaCog" style={{ color: '#687088', fontSize: '16px', width: '24px', position: 'relative', top: '-1px' }} />
                                <span>Shop Settings</span>
                            </NavLink>
                        </li>
                        <li className="header">Help</li>
                        <li className="nav-shop-settings">
                            <NavLink to="/help">
                                <Icon name="FaQuestion" style={{ color: '#687088', fontSize: '16px', width: '24px', position: 'relative', top: '-1px' }} />
                                <span>Help</span>
                            </NavLink>
                        </li>
                    </ul>
                </OverlayScrollbarsComponent>
            </section>
        </aside>
    );
};

export default Sidebar;