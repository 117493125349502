import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, Link } from 'react-router-dom';
import user from "../../assets/img/user.png";
import Footer from "../../components/footer/Footer";
import HeaderCopy from "../../components/header/HeaderCopy";
import { GridLoader } from "react-spinners";

const Settings = () => {
    const [token, setToken] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(''); // State for error messages
    const [successMessage, setSuccessMessage] = useState(''); // State for success messages
    const [formData, setFormData] = useState({
        email: '',
        first_name: '',
        last_name: '',
        contact: '',
        file: null,
        image_cover: null, // for preview
        profile_picture: null, // To store the existing profile picture from the database
    });

    // retrieve seller's credentials from session storage
    useEffect(() => {
        const tk = sessionStorage.getItem('seller_token'); // Retrieve seller's token from session storage
        setToken(tk);
    }, []);

    const handleJwtExpired = () => {
        alert('Your session has expired. Please log in again.');
        sessionStorage.clear();
        navigate("/");
    };
  
    // Fetch the current user details from the backend based on the sellerId
    const fetchUserDetails = async () => {
        setErrorMessage(''); // Reset error message
        setSuccessMessage(''); // Reset success message
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_Base_url}/sellers/get_seller`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            // Check if the response contains a JWT expiration message
            if (response.data && response.data.message === "jwt expired") {
                handleJwtExpired();
                return;
            }

            if (response.data.user) {
                setFormData((prevData) => ({
                    ...prevData,
                    ...response.data.user, // Spread existing user data
                    image_cover: null, // Reset image_cover for new uploads
                }));
            } else {
                console.error('No seller data received');
            }
        } catch (error) {
            console.error('Error fetching seller details:', error);

            // Check for JWT expiration in error response
            if (error.response?.status === 401 && error.response?.data?.message === "jwt expired") {
                handleJwtExpired();
            } else if (error.response) {
                // Handle other API errors
                setErrorMessage(`Error fetching seller details: ${error.response.data.message || error.message}`);
            } else if (error.request) {
                // Handle network errors
                setErrorMessage('Network Error: Unable to reach the server. Please check your internet connection.');
            } else {
                // Handle unexpected errors
                setErrorMessage(`An unexpected error occurred: ${error.message}`);
            }
      } finally {
        setIsLoading(false);
      }
    };

    useEffect(() => {
        if (token) {
            fetchUserDetails();
        }
    }, [token]);

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle file input change and show preview
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData((prevData) => ({
                ...prevData,
                file,
            }));
            showImagePreview(file); // Display image preview
        }
    };

    // display image preview
    const showImagePreview = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            setFormData((prevData) => ({
                ...prevData,
                image_cover: e.target.result,
            }));
        };
        reader.readAsDataURL(file);
    };

    // Handle the removal of profile photo
    const handleRemoveImage = async () => {
        setErrorMessage(''); // Reset error message
        setSuccessMessage(''); // Reset success message

        try {
            // Send request to remove the profile picture from the database
            const response = await axios.post(
                `${process.env.REACT_APP_Base_url}/sellers/remove_profile_picture`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {                
                await fetchUserDetails(); // Call this function to refetch the user details
                setSuccessMessage('Your profile picture has been removed successfully.');
            }
        } catch (error) {
            console.error('Error removing profile picture:', error);
            setErrorMessage('Failed to remove profile picture.');
        }
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage(''); // Reset error message
        setSuccessMessage(''); // Reset success message

        // Validate form fields
        const requiredFields = [
            { field: 'first_name', message: 'First name is required' },
            { field: 'last_name', message: 'Last name is required' },
            { field: 'email', message: 'Email is required' },
            { field: 'contact', message: 'Contact is required' }
        ];

        for (const { field, message } of requiredFields) {
            if (!formData[field]) {
                setLoading(false);
                setErrorMessage(message);
                return;
            }
        }

        try {
            const formDataToSubmit = new FormData();
            formDataToSubmit.append('first_name', formData.first_name);
            formDataToSubmit.append('last_name', formData.last_name);
            formDataToSubmit.append('email', formData.email);
            formDataToSubmit.append('contact', formData.contact);

            if (formData.file) {
                formDataToSubmit.append('file', formData.file);
            }

            const response = await axios.post(
                `${process.env.REACT_APP_Base_url}/sellers/update_seller`,
                formDataToSubmit,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            
            // Check if the response contains a JWT expiration message
            if (response.data && response.data.message === "jwt expired") {
                handleJwtExpired();
                return;
            }

            // check response status
            if (response.status === 200) {
                // Show success message
                setSuccessMessage('Changes successfully saved!');
            }    
        } catch (error) {
            console.error('Error updating seller details:', error);

            // Check for JWT expiration in error response
            if (error.response?.status === 401 && error.response?.data?.message === "jwt expired") {
                handleJwtExpired();
            } else if (error.response) {
                // Handle other API errors
                const errorMessage = error.response.data.message || 'Error updating seller details.';
                setErrorMessage(`Error: ${errorMessage}`); // Set the exact error message from the backend
            } else if (error.request) {
                // Handle network errors
                setErrorMessage('Network Error: Unable to reach the server. Please check your internet connection.');
            } else {
                // Handle unexpected errors
                setErrorMessage(`Error: An unexpected error occurred: ${error.message}`);
            }
        } finally {
            setLoading(false);
        }
    };

    if (isLoading) {
        return (
            <div style={loaderContainerStyle}>
                <GridLoader color="#008b8b" loading={isLoading} />
            </div>
        );
    }

    return (
        <>
            <HeaderCopy />

            {/* Update Seller's information start */}
            <div id="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="nav-breadcrumb" aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/settings">Profile Settings</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Update Profile</li>
                                </ol>
                            </nav>
                            <h1 className="page-title">Profile Settings</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-3">
                            <div className="row-custom">
                                <div className="sidebar-tabs">
                                    <ul className="nav">
                                        <li className="nav-item active">
                                            <Link to="/settings" className="nav-link">
                                                <div class="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <g fill="none" fill-rule="evenodd">
                                                            <path d="M24 0v24H0V0h24ZM12.594 23.258l-.012.002-.071.035-.02.004-.014-.004-.071-.036c-.01-.003-.019 0-.024.006l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.016-.018Zm.264-.113-.014.002-.184.093-.01.01-.003.011.018.43.005.012.008.008.201.092c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.003-.011.018-.43-.003-.012-.01-.01-.184-.092Z"></path>
                                                            <path fill="#333333" d="M18.884 14.469a1 1 0 0 1 1.784.896l-.052.104-.335.58a3.016 3.016 0 0 1 .482.782l.066.169h.671a1 1 0 0 1 .117 1.993L21.5 19h-.671a3 3 0 0 1-.41.776l-.138.174.335.581a1 1 0 0 1-1.668 1.098l-.064-.098-.335-.58c-.293.054-.59.063-.88.03l-.217-.032-.336.582a1 1 0 0 1-1.784-.896l.052-.104.335-.581a3.026 3.026 0 0 1-.482-.78l-.066-.17H14.5a1 1 0 0 1-.117-1.993L14.5 17h.672a3 3 0 0 1 .41-.776l.137-.174-.335-.581a1 1 0 0 1 1.668-1.098l.064.098.335.58c.293-.054.59-.063.88-.03l.217.031.336-.581ZM11 13c.447 0 .887.024 1.316.07a1 1 0 0 1-.211 1.989C11.745 15.02 11.375 15 11 15c-2.023 0-3.843.59-5.136 1.379-.647.394-1.135.822-1.45 1.222-.324.41-.414.72-.414.899 0 .122.037.251.255.426.249.2.682.407 1.344.582C6.917 19.858 8.811 20 11 20l.658-.005a1 1 0 0 1 .027 2L11 22c-2.229 0-4.335-.14-5.913-.558-.785-.208-1.524-.506-2.084-.956C2.41 20.01 2 19.345 2 18.5c0-.787.358-1.523.844-2.139.494-.625 1.177-1.2 1.978-1.69C6.425 13.695 8.605 13 11 13Zm7 4a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM11 2a5 5 0 1 1 0 10 5 5 0 0 1 0-10Zm0 2a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                                Update Profile
                                            </Link>
                                        </li>
                                        <li className="nav-item ">
                                            <Link to="/change-password" className="nav-link">
                                                <div class="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <g fill="none" fill-rule="nonzero">
                                                            <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z"></path>
                                                            <path fill="#333333" d="M12 2a6 6 0 0 1 5.996 5.775L18 8h1a2 2 0 0 1 1.995 1.85L21 10v10a2 2 0 0 1-1.85 1.995L19 22H5a2 2 0 0 1-1.995-1.85L3 20V10a2 2 0 0 1 1.85-1.995L5 8h1a6 6 0 0 1 6-6Zm7 8H5v10h14V10Zm-7 2a2 2 0 0 1 1.134 3.647l-.134.085V17a1 1 0 0 1-1.993.117L11 17v-1.268A2 2 0 0 1 12 12Zm0-8a4 4 0 0 0-4 4h8a4 4 0 0 0-4-4Z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                                Change Password
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        
                                {/* Update Seller information start */}
                                <div className="col-sm-12 col-md-9">
                                    <div className="row-custom">
                                        <div className="profile-tab-content">
                                            {/* Display error and success messages here */}
                                            {errorMessage && (
                                                <div className="m-b-15">
                                                    <div className="alert alert-danger alert-dismissable alert-messages">
                                                        <ul>
                                                            <li>{errorMessage}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            )}
                                            {successMessage && (
                                                <div className="m-b-15">
                                                    <div className="alert alert-success alert-dismissable alert-messages">
                                                        <ul>
                                                            <li>{successMessage}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            )}
                                            <form enctype="multipart/form-data" onSubmit={handleSubmit}>                            
                                                <div className="form-group">
                                                    <div id="edit_profile_cover" className="edit-profile-cover edit-cover-no-image" style={{ backgroundImage: `url(${formData.image_cover || formData.profile_picture || user})`}}>
                                                        <div className="edit-avatar">
                                                            <label className="btn btn-md btn-custom btn-file-upload">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                                                                    <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"></path>
                                                                    <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"></path>
                                                                </svg>
                                                                <input type="file" name="file" size="40" accept=".png, .jpg, .jpeg, .gif" onChange={handleFileChange} />
                                                            </label>
                                                            <img 
                                                                src={formData.image_cover || formData.profile_picture || user} // Check for preview, then profile picture, then default
                                                                alt="Profile" 
                                                                id="img_preview_avatar" 
                                                                className="img-thumbnail" 
                                                                width="180" 
                                                                height="180" 
                                                            />
                                                        </div>
                                                        <div className="btn-container">
                                                            {/* Delete Profile Picture Button */}
                                                            {formData.profile_picture && (
                                                                <a 
                                                                    className="cursor-pointer btn btn-md btn-file-upload btn-edit-cover"
                                                                    onClick={handleRemoveImage}
                                                                    style={{
                                                                            backgroundColor: 'red',
                                                                            borderRadius: '25%',
                                                                            borderColor: 'red'
                                                                    }}
                                                                >
                                                                    <svg 
                                                                        className="w-6 h-6 text-gray-800 dark:text-white" 
                                                                        aria-hidden="true" 
                                                                        xmlns="http://www.w3.org/2000/svg" 
                                                                        width="24" 
                                                                        height="24" 
                                                                        fill="currentColor" 
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <path 
                                                                            fillRule="evenodd" 
                                                                            d="M5 8a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm-2 9a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-1Zm13-6a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-4Z" 
                                                                            clipRule="evenodd"
                                                                        />
                                                                    </svg>
                                                                </a>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <p className="mb-4"><small className="text-muted">*Click on the save changes button after selecting your image</small></p>
                                                </div>
                                                <div className="form-group">
                                                    <label className="control-label">
                                                        Email Address 
                                                        <small className="text-success">(Confirmed)</small>
                                                    </label>
                                                    <input type="email" name="email" className="form-control form-input" value={formData.email} onChange={handleChange} required />
                                                </div>
                                                <div className="form-group">
                                                    <label className="control-label">First Name</label>
                                                    <input type="text" name="first_name" className="form-control form-input" value={formData.first_name} onChange={handleChange} required />
                                                </div>
                                                <div className="form-group">
                                                    <label className="control-label">Last Name</label>
                                                    <input type="text" name="last_name" className="form-control form-input" value={formData.last_name} onChange={handleChange} required />
                                                </div>
                                                <div className="form-group">
                                                    <label className="control-label">Phone Number</label>
                                                    <input type="text" name="contact" className="form-control form-input" value={formData.contact} onChange={handleChange} required />
                                                </div>
                                                <button 
                                                    type="submit" 
                                                    className="btn btn-md btn-custom" 
                                                    disabled={loading}
                                                >
                                                    {loading ? 'Saving...' : 'Save Changes'}
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                          
                    </div>
                </div>
            </div>
            {/* Update Seller's information end */}

            <Footer />
        
        </>
    );
};

const loaderContainerStyle = {
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    height: '50vh', // Full viewport height
};

export default Settings;
