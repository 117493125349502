import React, { useState, useEffect } from 'react';
import axios from 'axios';
import IconDetailsPage from "../icon/IconDetailsPage";
import Icon from "../icon/Icon";

const ShopDetails = ({ sellerId }) => {
    const [sellerInfo, setSellerInfo] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
        const fetchSellerInfo = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_Base_url}/products/get_shop_details/${sellerId}`);
            setSellerInfo(res.data.shop);
            //console.log(res.data);
        } catch (err) {
            setError('Error fetching seller information');
        } finally {
            setLoading(false);
        }
    };

        fetchSellerInfo();
    }, [sellerId]);

	if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!sellerInfo) {
        return <div>Seller information not available</div>;
    }

    return (
        <>
            <div className="tab-pane fade" id="tab_shipping_content" role="tabpanel">
                <div className="card">
                    <div className="card-header">
                        <a className="card-link collapsed" data-toggle="collapse" href="#collapse_shipping_content">
                            Shop Details
                            <IconDetailsPage name="FaPlus" className="plus" style={{ color: '#777', float: 'left', fontSize: '18px', lineHeight: '1.1rem', marginRight: '5px' }} />
                            <IconDetailsPage name="FaMinus" className="minus" style={{ color: '#777', float: 'left', fontSize: '18px', lineHeight: '1.1rem', marginRight: '5px' }} />
                        </a>
                    </div>
                    <div id="collapse_shipping_content" className="collapse-shipping-content collapse" data-parent="#accordion">
                        <div className="description">
                            <ul>
                                <li><strong>Shop Name:</strong> {sellerInfo.company_name}</li>
                                <li><strong>Shop Location:</strong> {sellerInfo.location}</li>
                                <li><strong>Business Category:</strong> {sellerInfo.business_category}</li>
                                <li><strong>Shop Description:</strong> {sellerInfo.shop_description}</li>
                                <li>
                                    <strong>Verified:</strong> {sellerInfo.activated 
                                    ? <Icon name="FcApproval" color="#09b1ba" style={{ position: 'relative', top: '-1.5px' }} />
                                    : <Icon name="FaWindowClose" style={{ color: 'red' }} />}
                                </li>
                                <div className="row-custom text-center">
                                    <a href="/profile" className="link-see-more"><span>View Shop&nbsp;</span><Icon name="BiChevronRight" /></a>
                                </div>
                            </ul>                                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShopDetails;