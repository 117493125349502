import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { TextField, DialogActions, FormControl, Button, IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import Icon from "../icon/Icon";


const UpdateShopperModal = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const [contact, setContact] = useState("");
    const [userData, setUserData] = useState(null);
    const [formError, setFormError] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [initialFormData, setInitialFormData] = useState({
        first_name: '',
        last_name: '',
        location: '',
        contact: '',
        email: '',
    });
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        location: '',
        contact: '',
        email: '',
    });

    // function to handle editing the form fields
    const handleEdit = (fieldName) => {
        setEditMode(true);
    };

    // function to validate contact
    const validateContact = (value) => {
        const regexp = /^\d{10}$/; // Start (^) and end ($) with exactly 10 digits (\d)
        if (!regexp.test(value)) {
            setFormError('Contact must be exactly 10 digits');
            return false;
        }
        setFormError(''); // Clear error message if validation passes
        return true;
    };

    // function to ensure if token has expired, redirects user to login
    const handleJwtExpired = () => {
        alert('Your session has expired. Please log in again.');
        sessionStorage.clear();
        navigate("/login?message=Your session has expired. Please log in again.");
    };
  
    // get shopper details from database
    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_Base_url}/shoppers/me`, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("shopper_token")}`,
                    },
                });
                setUserData(response.data);
                if (response.data.user) {
                    setFormData(response.data.user);
                    setInitialFormData(response.data.user);
                } else if (response.data.message === "jwt expired" && response.data.status === 401) {
                    handleJwtExpired();
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        };
        fetchUserDetails();
    }, []);

    // function to check whether the form has changed or not to trigger the handle submit
    useEffect(() => {
        const isChanged = JSON.stringify(formData) !== JSON.stringify(initialFormData);
        setHasChanges(isChanged);
    }, [formData, initialFormData]);
    
    // function to handle change of form fields
    const handleChange = (event, fieldName) => {
        const { value } = event.target;
        setFormData((prevData) => ({
        ...prevData,
        [fieldName]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        
        try {
            const formDataToSubmit = new FormData();
            formDataToSubmit.append('first_name', formData.first_name);
            formDataToSubmit.append('last_name', formData.last_name);
            formDataToSubmit.append('email', formData.email);
            formDataToSubmit.append('location', formData.location);
            formDataToSubmit.append('contact', formData.contact);
    
        // Make a POST request to update the shopper's details on the backend
        const response = await axios.post(
          `${process.env.REACT_APP_Base_url}/shoppers/update`, 
          formDataToSubmit,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("shopper_token")}`,
            },
          }
        );

        // Handle success, e.g., show a success message, redirect, etc.
        if (response.status === 200) {
            alert(response.data.message);
            setIsSubmitting(false);
            window.location.reload();
            //navigate('/checkout');
        }
      } catch (error) {
            console.error('Error updating shopper:', error);
            setIsSubmitting(false);

            // Handle error, e.g., show an error message to the user
            if (error.response && error.response.status === 400) {
                const errorMessage = error.response.data.message;
                
                // Check for specific error messages from the backend
                if (errorMessage === "The mobile number you entered has already been used. Enter a new number") {
                
                    // Display specific error message for duplicate contact number
                    alert(errorMessage, 400);
                    setIsSubmitting(false);
                } else {

                    // Display a generic error message for other errors
                    alert("An error occurred. Please try again later.", 400);
                    setIsSubmitting(false);
                }
            } else {
                // Display a generic error message for other errors
                alert("An error occurred. Please try again later.", 400);
                setIsSubmitting(false);
            }
        }
    };
    
    return (
        <>  
            <a href="javascript:void(0)" className="text-info link-add-new-shipping-option" data-toggle="modal" data-target="#updateShopperModal">
                {/*<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path>
                </svg>*/}
                <Icon name="IoLocation" />
                Change Delivery Address
            </a>

            {/* Start of shopper update modal */}
            <div className="modal fade" id="updateShopperModal" tabIndex={-1} role="dialog" aria-labelledby="updateShopperModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered login-modal" role="document">
                    <div className="modal-content">
                        <div className="auth-box">
                            <button 
                                type="button" 
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <Icon name="MdClose" size="1em" />
                            </button>
                            <h4 className="title">Update Your Details</h4>
                            <React.Fragment>
                                {userData && (
                                    <form autoComplete="off" onSubmit={handleSubmit}>
                                        <FormControl fullWidth margin="normal">
                                            <TextField 
                                                label="First Name"
                                                onChange={(e) => handleChange(e, 'first_name')}
                                                required
                                                variant="outlined"
                                                color="secondary"
                                                type="text"
                                                size="small"
                                                value={formData.first_name}
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton onClick={() => handleEdit('first_name')}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    )
                                                }}
                                                disabled={!editMode}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth margin="normal">
                                            <TextField 
                                                label="Last Name"
                                                onChange={(e) => handleChange(e, 'last_name')}
                                                required
                                                variant="outlined"
                                                color="secondary"
                                                type="text"
                                                size="small"
                                                value={formData.last_name}
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton onClick={() => handleEdit('last_name')}>
                                                            <EditIcon />
                                                    </IconButton>
                                                    )
                                                }}
                                                disabled={!editMode}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth margin="normal">
                                            <TextField 
                                                label="Email"
                                                onChange={(e) => handleChange(e, 'email')}
                                                required
                                                variant="outlined"
                                                color="secondary"
                                                type="text"
                                                size="small"
                                                value={formData.email}
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton onClick={() => handleEdit('email')}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    )
                                                }}
                                                disabled={!editMode}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth margin="normal">
                                            <TextField 
                                                label="Contact"
                                                error={!!formError}
                                                helperText={formError}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    setFormData((prevData) => ({
                                                        ...prevData,
                                                        contact: value,
                                                    }));
                                                    // Validate the contact number while the user types
                                                    const isValid = validateContact(value);
                                                    setFormError(isValid ? '' : 'Contact must be exactly 10 digits');
                                                }}
                                                required
                                                variant="outlined"
                                                color="secondary"
                                                type="text"
                                                size="small"
                                                value={formData.contact}
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton onClick={() => handleEdit('contact')}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    )
                                                }}
                                                disabled={!editMode}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth margin="normal">
                                            <TextField 
                                                label="Location"
                                                onChange={(e) => handleChange(e, 'location')}
                                                required
                                                variant="outlined"
                                                color="secondary"
                                                type="text"
                                                size="small"
                                                value={formData.location}
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton onClick={() => handleEdit('location')}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    )
                                                }}
                                                disabled={!editMode}
                                            />
                                        </FormControl>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Button 
                                                variant="outlined" 
                                                color="secondary" 
                                                type="submit"
                                                onSubmit={handleSubmit}
                                                disabled={isSubmitting || !!formError || !hasChanges}
                                            >
                                                {isSubmitting ? (
                                                    <>
                                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Saving...</span>
                                                    </>
                                                    ) : (
                                                "Save"
                                                )}
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                type="button" 
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                Close
                                            </Button>
                                        </div>
                                    </form>
                                )}
                            </React.Fragment>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UpdateShopperModal;