import React from 'react';
import { IconContext } from 'react-icons';
import { FaPlus, FaMinus } from "react-icons/fa6";

const iconComponents = {
    FaPlus, FaMinus,
};

const Icon = ({ 
    name, 
    size = '1em', 
    color = 'currentColor', 
    style = {}, 
    className = ''
}) => {
    const IconComponent = iconComponents[name];
  
    if (!IconComponent) {
      console.warn(`Icon ${name} not found`);
      return null;
    }
  
    return (
        <IconContext.Provider value={{ size, color }}>
            <div 
                style={{ ...style }}
                className={className}
            >
                <IconComponent />
            </div>
        </IconContext.Provider>
    );
};
  
export default Icon;

// Usage:
// <Icon name="FaShoppingBag" size="1.5em" color="#333" />
// import Icon from "../icon/Icon";
