import axios from "axios";
import queryString from "query-string";
import { useLocation, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";

const Activation = () => {
    const { search } = useLocation();
    const { type } = useParams<{ type: string }>(); // Get 'shopper' or 'seller' from route
    const { email, token } = queryString.parse(search); // Parse query parameters
    const [display, setDisplay] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true); // To handle loading state
    const [isActivated, setIsActivated] = useState<boolean>(false); // Step 1: Declare the isActivated state

    useEffect(() => {
        // Only proceed if email and token are available
        if (email && token) {
            // Determine endpoint based on type
            const endpoint = type === "seller"
                ? `${process.env.REACT_APP_Base_url}/sellers/activate_user`
                : `${process.env.REACT_APP_Base_url}/shoppers/activate_user`;

            // Make API request
            axios
                .post(endpoint, { email, token })
                .then((res) => {
                    setDisplay(res.data.message); // Success message
                    setIsActivated(true); // Step 2: Set isActivated to true on success
                })
                .catch((err) => {
                    setDisplay("Activation failed. Please try again."); // Error message
                    console.error(err);
                })
                .finally(() => {
                    setLoading(false); // Set loading to false when request is done
                });
        } else {
            setDisplay("Invalid activation link.");
            setLoading(false);
        }
    }, [email, token, type]);

    return (
        <>
            <Header />

            <div id="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-confirm">
                                {/* Loader animation */}
                                {loading ? (
                                    <div className="circle-loader">
                                        <div className="checkmark draw" style={{ display: 'none' }}></div>
                                    </div>
                                ) : (
                                    <div className={`circle-loader ${isActivated ? "load-complete" : ""}`}>
                                        {isActivated && <div className="checkmark draw" style={{ display: 'block' }}></div>}
                                    </div>
                                )}
                                <h1 className="title">
                                    {isActivated ? (
                                        "Your email address has been successfully confirmed!"
                                    ) : (
                                        display
                                    )}
                                </h1>
                                {isActivated && (
                                    <a href="/" className="btn btn-md btn-custom btn-block m-t-30">
                                        Go to the Homepage
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Activation;