import React, { useState } from 'react';
import { IconContext } from 'react-icons';
import { MdOutlineStar, MdOutlineStarBorder } from 'react-icons/md';

const iconComponents = {
    MdOutlineStar,
    MdOutlineStarBorder,
};
  
const Icon = ({ 
    name, 
    size = '1em', 
    color = 'currentColor', 
    style = {}, 
    onClick,
    onMouseEnter,
    onMouseLeave
}) => {
    const IconComponent = iconComponents[name];
  
    if (!IconComponent) {
      console.warn(`Icon ${name} not found`);
      return null;
    }
  
    return (
      <IconContext.Provider value={{ size, color }}>
        <div 
          style={{ ...style, display: 'inline-block', cursor: 'pointer' }}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <IconComponent />
        </div>
      </IconContext.Provider>
    );
};

const StarRating = ({ rating, onRatingChange }) => {
    const [hoverRating, setHoverRating] = useState(0);

    const handleClick = (value) => {
        onRatingChange(value);
    };

    const handleMouseEnter = (value) => {
        setHoverRating(value);
    };

    const handleMouseLeave = () => {
        setHoverRating(0);
    };

    return (
        <div className="rating">
            {[1, 2, 3, 4, 5].map((value) => (
                <Icon
                    key={value}
                    name={value <= (hoverRating || rating) ? "MdOutlineStar" : "MdOutlineStarBorder"}
                    color="#fbc12a"
                    onClick={() => handleClick(value)}
                    onMouseEnter={() => handleMouseEnter(value)}
                    onMouseLeave={handleMouseLeave}
                    style={{ fontSize: '15px', marginRight: '2px' }}
                />
            ))}
        </div>
    );
};

export default StarRating;
