import React, { useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { PuffLoader } from 'react-spinners';
import Icon from "../icon/Icon";

interface PriceFilterProps {
    isLoading: boolean;
    onFilter: (minPrice: number, maxPrice: number) => void;
}

export interface PriceFilterRef {
    resetMinPrice: () => void;
    resetMaxPrice: () => void;
}

const PriceFilter = forwardRef<PriceFilterRef, PriceFilterProps>(({ isLoading, onFilter }, ref) => {
    const [minPrice, setMinPrice] = useState<string>('');
    const [maxPrice, setMaxPrice] = useState<string>('');

    useImperativeHandle(ref, () => ({
        resetMinPrice: () => setMinPrice(''),
        resetMaxPrice: () => setMaxPrice(''),
    }));

    const handlePriceInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        if (id === 'price_min') {
        setMinPrice(value);
        } else if (id === 'price_max') {
        setMaxPrice(value);
        }
    };

    const handleFilterClick = () => {
        try {
        const min = parseFloat(minPrice) || 0;
        const max = parseFloat(maxPrice) || 1000000;
        onFilter(min, max);
        } catch (error) {
        console.error('Error filtering products:', error);
        }
    };

    return (
        <div className="filter-item">
        <h4 className="title">Price</h4>
            <div className="price-filter-inputs">
                <div className="row align-items-baseline row-price-inputs">
                    <div className="col-4 col-md-4 col-lg-5 col-price-inputs">
                        <span>Min</span>
                        <input 
                            type="input" 
                            id="price_min" 
                            value={minPrice} 
                            className="form-control price-filter-input" 
                            placeholder="Min" 
                            onInput={handlePriceInput}
                        />
                    </div>
                    <div className="col-4 col-md-4 col-lg-5 col-price-inputs">
                        <span>Max</span>
                        <input 
                            type="input" 
                            id="price_max" 
                            value={maxPrice} 
                            className="form-control price-filter-input" 
                            placeholder="Max" 
                            onInput={handlePriceInput}
                        />
                    </div>
                    <div className="col-4 col-md-4 col-lg-2 col-price-inputs text-left">
                        <button 
                            type="button" 
                            id="btn_filter_price" 
                            className="btn btn-sm btn-default btn-filter-price float-left"
                            onClick={handleFilterClick}
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <PuffLoader size={20} color="#000" />
                            ) : (
                                <Icon name="BiChevronRight" />
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
});

PriceFilter.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    onFilter: PropTypes.func.isRequired,
};

export default PriceFilter;
