import { Toast } from "primereact/toast";
import { useRef, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

const LoginAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const toast = useRef(null);
  const location_data = useLocation();
  console.log(location_data);
  console.log(location_data.state);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  // todo: show toast
  const show = (message, status) => {
    toast.current.show({
      severity: status === 200 ? "success" : "error",
      summary: status === 200 ? "success" : "error",
      detail: message,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(`${process.env.REACT_APP_Base_url}/admin/login`, {
        email,
        password,
      })
      .then((res) => {
        console.log(res);
        show(res.data.message, res.data.status);
        setLoading(false);

        if (res.data.status === 200) {
          sessionStorage.setItem("token", res.data.token);
          sessionStorage.setItem("first_name", res.data.user.first_name);
          window.location.href = "/admin-dash";
        }
      })
      .catch((error) => {
        if (error.message === 'Login session expired, please log in again') {
          window.location.href = '/login-admin';
        } else {
        console.log('Error:', error);
        }
      });
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="back-to-home rounded d-none d-sm-block">
        <a href="/" className="text-white rounded d-inline-block text-center">
          <i className="mdi mdi-home" />
        </a>
      </div>
      {/* Hero Start */}
      <section
        className="vh-100"
        style={{
          backgroundColor: "lightgray",
        }}>
        <div className="home-center">
          <div className="home-desc-center">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className="login-page bg-white shadow rounded p-4">
                    <div className="text-center">
                      <h4 className="mb-4">Login As Admin</h4>
                    </div>
                    <form className="login-form" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group position-relative">
                            <label>
                              Enter your email <span className="text-danger">*</span>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Email"
                              required
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group position-relative">
                            <label>
                              Enter your password <span className="text-danger">*</span>
                            </label>
                            <input
                               type={showPassword ? "text" : "password"}
                               className="form-control"
                               placeholder="***************"
                               required
                               value={password}
                               onChange={(e) => setPassword(e.target.value)}
                            />
                            <i
                              className={`mdi mdi-eye${showPassword ? '-off' : ''}`}
                              style={{
                                position: 'absolute',
                                top: '50%', // adjust as needed
                                right: '10px', // adjust as needed
                                padding: '5px', // adjust padding as needed
                                color: '#7c7c7c', // adjust color as needed
                                cursor: 'pointer',
                              }}
                              onClick={togglePasswordVisibility}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <p className="float-right forgot-pass">
                            <a
                              href="/ForgotPassword"
                              className="text-dark font-weight-bold">
                              Forgot password?
                            </a>
                          </p>
                          <div className="form-group">
                            <div className="custom-control m-0 custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customCheck1"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customCheck1">
                                Remember me
                              </label>
                            </div>
                          </div>
                        </div>
                        {/* change button to loading when setLoading is true */}
                        {loading === false ? (
                          <div className="col-lg-12 mb-0">
                            <button className="btn btn-primary w-100">
                              Sign in
                            </button>
                          </div>
                        ) : (
                          <div className="col-lg-12 mb-0">
                            <button className="btn btn-primary w-100" disabled>
                              Loading...
                            </button>
                          </div>
                        )}

                        <div className="col-lg-12 mt-4 text-center">
                          <h6>Or Login With</h6>
                          <ul className="list-unstyled social-icon mb-0 mt-3">
                            <li className="list-inline-item">
                              <a href="#/" className="rounded">
                                <i
                                  className="mdi mdi-facebook"
                                  title="Facebook"
                                />
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="#/" className="rounded">
                                <i
                                  className="mdi mdi-google-plus"
                                  title="Google"
                                />
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="#/" className="rounded">
                                <i
                                  className="mdi mdi-github-circle"
                                  title="Github"
                                />
                              </a>
                            </li>
                          </ul>
                          {/*end icon*/}
                        </div>
                        {/*<div className="col-12 text-center">
                          <p className="mb-0 mt-3">
                            <small className="text-dark mr-1">
                              Don't have an account?
                            </small>{" "}
                            <a
                              href="/register-seller"
                              className="text-danger font-weight-bold">
                              Sign up
                            </a>
                          </p>
                        </div>*/}
                      </div>
                    </form>
                  </div>
                  {/**/}
                </div>{" "}
                {/*end col*/}
              </div>
              {/*end row*/}
            </div>{" "}
            {/*end container*/}
          </div>
        </div>
      </section>
      {/*end section*/}
      {/* Hero End */}
    </>
  );
};

export default LoginAdmin;
