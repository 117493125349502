import React, { ReactElement, useEffect, useState } from "react";
import axios from "axios";
import { CategoryProps, SectionProps, PostProductProps } from "../../types/Types";
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';

interface ApiError extends Error {
    response?: {
      data: {
        status: number;
        message: string;
      };
    };
}

function isApiError(error: unknown): error is ApiError {
    return (
      typeof error === 'object' &&
      error !== null &&
      'response' in error &&
      typeof (error as any).response?.data === 'object'
    );
}

interface FormFieldProps {
    label: string;
    id: string;
    error?: string;
    children: ReactElement | ReactElement[];
}

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Form = styled.form`
  display: grid;
  gap: 1.5rem;

  @media (max-width: 768px) {
    gap: 1rem;
  }
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 1rem;
  color: #555;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const Select = styled.select`
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  appearance: none;
  background-image: url("data:image/svg+xml,...");
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.65rem auto;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const Textarea = styled.textarea`
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  resize: vertical;
  min-height: 100px;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const ImagePreview = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;

  @media (max-width: 768px) {
    gap: 0.5rem;
  }
`;

const PreviewImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;

const SubmitButton = styled.button`
  padding: 1rem;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;

  &:hover {
    background-color: #357abd;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 0.8rem;
  margin-top: 0.2rem;

  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;

const FormField: React.FC<FormFieldProps> = ({ label, id, error, children }) => (
    <InputGroup>
      <Label htmlFor={id}>{label}</Label>
      {React.Children.map(children, (child) => 
        React.isValidElement(child)
          ? React.cloneElement(child, {
              id, 
              'aria-describedby': error ? `${id}-error` : undefined,
            } as Partial<typeof child.props>)
          : child
      )}
      {error && <ErrorMessage id={`${id}-error`}>{error}</ErrorMessage>}
    </InputGroup>
);

const PostProduct: React.FC<PostProductProps> = ({ token, companyName }) => {
    const [productName, setProductName] = useState<string>('');
    const [productDescription, setProductDescription] = useState<string>('');
    const [productSection, setProductSection] = useState<string>('');
    const [productCategory, setProductCategory] = useState<string>('');
    const [productQuantity, setProductQuantity] = useState<string>('');
    const [productPrice, setProductPrice] = useState<string>('');
    const [productMode, setProductMode] = useState<string>('In Stock'); // Set default to "In Stock"
    const [productImages, setProductImages] = useState<File[]>([]);
    const [subCategory, setSubCategory] = useState<string>('');
    const [subSection, setSubSection] = useState<string>('');
    const [categories, setCategories] = useState<CategoryProps[]>([]);
    const [sections, setSections] = useState<SectionProps[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [imagePreviewUrls, setImagePreviewUrls] = useState<string[]>([]);
    const [mainImageIndex, setMainImageIndex] = useState<number | null>(null);
    const navigate = useNavigate();

    // Handle addition and changing of images
    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const files = Array.from(e.target.files);
            setProductImages((prevImages) => [...prevImages, ...files]); // Append new images
            const previewUrls = files.map(file => URL.createObjectURL(file));
            setImagePreviewUrls((prevUrls) => [...prevUrls, ...previewUrls]); // Append new preview URLs
        }
    };

    // set the main image
    const handleSetMainImage = (index: number) => {
        setMainImageIndex(index); // Set the selected image as the main image
    };

    // handle deletion of images
    const handleImageDelete = (index: number) => {
        // Remove the image from the preview URLs
        const updatedPreviewUrls = imagePreviewUrls.filter((_, i) => i !== index);
        setImagePreviewUrls(updatedPreviewUrls);
    
        // Remove the image from the product images
        const updatedProductImages = productImages.filter((_, i) => i !== index);
        setProductImages(updatedProductImages);
    };

    // handles image previews before posting
    useEffect(() => {
        return () => {
          imagePreviewUrls.forEach(url => URL.revokeObjectURL(url));
        };
    }, [imagePreviewUrls]);

    // fetch the product sections and categories from database
    useEffect(() => {
        const fetchCategoriesAndSections = async () => {
            try {
                const [categoriesResponse, sectionsResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_Base_url}/utils/get_product_category`),
                    axios.get(`${process.env.REACT_APP_Base_url}/utils/get_product_sections`)
                ]);
    
                if (categoriesResponse.data.status === 200) {
                    setCategories(categoriesResponse.data.product_categories);
                }
    
                if (sectionsResponse.data.status === 200) {
                    setSections(sectionsResponse.data.product_sections);
                }
            } catch (error) {
                console.error('Error fetching categories and sections:', error);
            }
        };
    
        fetchCategoriesAndSections();
    }, []);

    // validate the form fields before sending to backend
    const validateForm = () => {
        const newErrors: Record<string, string> = {};
      
        if (!productName.trim()) newErrors.productName = "Product name is required";
        if (!productMode) newErrors.productMode = "Product availability is required";
        if (!productQuantity || parseInt(productQuantity) <= 0) newErrors.productQuantity = "Valid quantity is required";
        if (!productPrice || parseFloat(productPrice) <= 0) newErrors.productPrice = "Valid price is required";
        if (!productCategory) newErrors.productCategory = "Product category is required";
        if (!subCategory) newErrors.subCategory = "Sub category is required";
        if (!productSection) newErrors.productSection = "Product section is required";
        if (!subSection.trim()) newErrors.subSection = "Sub section is required";
        if (!productDescription.trim()) newErrors.productDescription = "Product description is required";
        if (productImages.length === 0) newErrors.productImages = "At least one product image is required";
      
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // reset form fields after posting a product
    const resetForm = () => {
        setProductName('');
        setProductDescription('');
        setProductSection('');
        setProductCategory('');
        setProductQuantity('');
        setProductPrice('');
        setProductMode('');
        setProductImages([]);
        setSubCategory('');
        setSubSection('');
        setImagePreviewUrls([]);
        setErrors({});
        setMainImageIndex(null); // Reset main image index if applicable
    };

    // handles submitting of forms to backend (posting of product)
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validateForm()) {
            // If the form is not valid, don't proceed with submission
            return;
        }
        setLoading(true);
        
        // Check if at least one image is selected
        if (productImages.length === 0) {
            alert('Please select at least one product image.');
            setLoading(false);
            return;
        }

        if (!token) {
            alert("Please login to post a product");
            navigate('/');
            return;
        } else {
            // Validate quantity
            const parsedQuantity = parseInt(productQuantity, 10);
            if (isNaN(parsedQuantity) || parsedQuantity <= 0) {
            alert('Please enter a valid quantity');
            setLoading(false);
            return;
            }

            // Validate price (assuming product_price is also a string)
            const parsedPrice = parseFloat(productPrice);
            if (isNaN(parsedPrice) || parsedPrice <= 0) {
            alert('Please enter a valid price');
            setLoading(false);
            return;
            }

            const formData = new FormData();
            formData.append("product_name", productName);
            formData.append("product_category", productCategory);
            formData.append("product_description", productDescription);
            formData.append("product_price",  parsedPrice.toFixed(2));
            formData.append("product_quantity", parsedQuantity.toString());
            formData.append("product_section", productSection);
            formData.append("product_mode", productMode);
            formData.append("company_name", companyName || '');
            formData.append("sub_category", subCategory);
            formData.append("sub_section", subSection);

            // Append multiple images
            productImages.forEach((image, index) => {
                formData.append(`productImages`, image);
            });

            // Check if mainImageIndex is null and handle accordingly
            if (mainImageIndex === null) {
                alert("Please select a main image before submitting the form.");
                setLoading(false);
                return; // Prevent form submission
            }
            
            formData.append('main_image_index', mainImageIndex.toString()); // Append the main image index

            try {
                const response = await axios.post (
                    `${process.env.REACT_APP_Base_url}/products/post_product`,
                    formData,
                    {
                        headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                        },
                    }
                );

                    alert(response.data.message);
                    setLoading(false);

                if (response.data.status === 200) {
                    resetForm();
                }
            } catch (error: unknown) {
                console.error(error);
                setLoading(false);
            
                if (isApiError(error)) {
                    const { status, message } = error.response!.data;
                    if (status === 401 && message === "jwt expired") {
                        sessionStorage.clear();
                        alert("Your session has expired. Please log in again.");
                        navigate("/");
                    }
                }
            }
        }
    };

    return (
        <>
            <PageContainer>
                <Title>Add Product</Title>
                <Form onSubmit={handleSubmit}>
                    <FormField label="Product Name" id="productName" error={errors.productName}>
                        <Input
                            type="text"
                            required
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                        />
                    </FormField>

                    <FormField label="Product Availability" id="productMode" error={errors.productMode}>
                        <Select
                            required
                            value={productMode}
                            onChange={(e) => setProductMode(e.target.value)}
                        >
                            <option value="">Choose...</option>
                            <option value="In Stock">In Stock</option>
                            <option value="On Preorder">On Preorder</option>
                            <option value="Out of Stock">Out of Stock</option>
                        </Select>
                    </FormField>

                    <FormField label="Product Quantity" id="productQuantity" error={errors.productQuantity}>
                        <Input
                            type="number"
                            required
                            value={productQuantity}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                setProductQuantity(inputValue);
                            }}
                            title="Please enter a positive number for quantity."
                        />
                    </FormField>

                    <FormField label="Product Price" id="productPrice" error={errors.productPrice}>
                        <Input
                            type="number"
                            step="0.01" // Allows for decimal prices
                            min="0.01" // Minimum price of 0.01
                            placeholder="Enter price without the currency eg: 100"
                            required
                            value={productPrice}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                setProductPrice(inputValue);
                            }}
                            title="Please enter a positive number for product price"
                        />
                    </FormField>

                    {categories && (
                        <FormField label="Product Category" id="productCategory" error={errors.productCategory}>
                            <Select
                                required
                                value={productCategory}
                                onChange={(e) => setProductCategory(e.target.value)}
                            >
                                <option value="">Choose...</option>
                                {categories.map((category: CategoryProps) => (
                                <option
                                    value={category.product_category_name}
                                    key={category.id}
                                >
                                    {category.product_category_name}
                                </option>
                                ))}
                            </Select>
                        </FormField>
                    )}

                    <FormField label="Sub Category" id="subCategory" error={errors.subCategory}>
                        <Select
                            required
                            value={subCategory}
                            onChange={(e) => setSubCategory(e.target.value)}
                        >
                            <option value="">Choose...</option>
                            <option value="MEN">MEN</option>
                            <option value="WOMEN">WOMEN</option>
                            <option value="UNISEX">UNISEX</option>
                            <option value="BRANDS">BRANDS</option>
                            <option value="HOME APPLIANCES">HOME APPLIANCES</option>
                            <option value="ELECTRONIC WEARABLES">ELECTRONIC WEARABLES</option>
                            <option value="KIDS ARENA">KIDS ARENA</option>
                        </Select>
                    </FormField>

                    {sections && (
                        <FormField label="Product Section" id="productSection" error={errors.productSection}>
                            <Select
                                required
                                value={productSection}
                                onChange={(e) => setProductSection(e.target.value)}
                            >
                                <option value="">Choose...</option>
                                {sections.map((section: SectionProps) => (
                                    <option
                                        value={section.product_section_name}
                                        key={section.id}
                                    >
                                        {section.product_section_name}
                                    </option>
                                ))}
                            </Select>
                        </FormField>
                    )}                   

                    <FormField label="Product Tags" id="subSection" error={errors.subSection}>
                        <Input
                            type="text"
                            placeholder="Add relevant keywords for your product to increase visibility in search results"
                            required
                            value={subSection}
                            onChange={(e) => setSubSection(e.target.value)}
                        />
                    </FormField>

                    <FormField label="Product Description" id="productDescription" error={errors.productDescription}>
                        <Textarea
                            required
                            value={productDescription}
                            onChange={(e) => setProductDescription(e.target.value)}
                        />
                    </FormField>

                    <FormField label="Add Product Images" id="productImages" error={errors.productImages}>
                        <Input
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={handleImageChange}
                        />
                        <ImagePreview>
                            {imagePreviewUrls.map((url, index) => (
                                <div key={index} style={{ position: 'relative' }}>
                                    <PreviewImage src={url} alt={`Preview ${index + 1}`} />
                                    <button
                                        type="button"
                                        onClick={() => handleImageDelete(index)} 
                                        style={{
                                            position: 'absolute',
                                            top: '5px',
                                            right: '5px',
                                            background: 'red',
                                            color: 'white',
                                            border: 'none',
                                            borderRadius: '50%',
                                            cursor: 'pointer',
                                            padding: '5px',
                                        }}
                                    >
                                        &times; {/* Delete button */}
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => handleSetMainImage(index)} 
                                        style={{
                                            position: 'absolute',
                                            bottom: '5px',
                                            left: '5px',
                                            background: mainImageIndex === index ? 'green' : 'grey', // Change color based on selection
                                            color: 'white',
                                            border: 'none',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                            padding: '5px',
                                        }}
                                    >
                                        Main {/* Set as main image button */}
                                    </button>
                                </div>
                            ))}
                        </ImagePreview>
                    </FormField>

                    <SubmitButton type="submit" disabled={loading}>
                        {loading ? 'Submitting...' : 'Submit'}
                    </SubmitButton>
                </Form>
            </PageContainer>
        </>
    );
};

export default PostProduct;
