import React from "react";
import A from "../../assets/img/hero/A.png";
import B from "../../assets/img/hero/B.png";
import C from "../../assets/img/hero/C.png";
import D from "../../assets/img/hero/D.png";
import E from "../../assets/img/hero/E.png";

const Banner = () => {
    return (
        <>
            <div id="wrapper" className="index-wrapper">
                <div className="container">
                    <div className="row">
                        <h1 className="index-title">Bxtbuy - Online shopping made easy.</h1>
                        <div className="col-12 section section-index-bn">
                            <div className="row">
                                <div className="col-6 col-index-bn index_bn_7">
                                    <a href="javascript:void(0)">
                                        <img src={A} alt="banner" className="img-fluid ls-is-cached lazyloaded" />
                                    </a>
                                </div>
                                <div className="col-6 col-index-bn index_bn_8">
                                    <a href="javascript:void(0)">
                                        <img src={B} alt="banner" className="img-fluid lazyloaded" />
                                    </a>
                                </div>
                                <div className="col-6 col-index-bn index_bn_9">
                                    <a href="javascript:void(0)">
                                        <img src={C} alt="banner" className="img-fluid ls-is-cached lazyloaded" />
                                    </a>
                                </div>
                                <div className="col-6 col-index-bn index_bn_12">
                                    <a href="javascript:void(0)">
                                        <img src={D} alt="banner" className="img-fluid ls-is-cached lazyloaded" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 section section-index-bn">
                            <div className="row">
                                <div className="col-6 col-index-bn index_bn_13">
                                    <a href="/orders">
                                        <img src={E} alt="banner" className="img-fluid ls-is-cached lazyloaded" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>      
        </>
    );
};

export default Banner;