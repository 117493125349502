import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ShopperRegistrationForm from './ShopperRegistrationForm';
import SellerRegistrationForm from './SellerRegistrationForm';
import queryString from 'query-string';

const Register = () => {
    const location = useLocation();
    const { role } = queryString.parse(location.search); // Check for role in the query params
    const [currentRole, setCurrentRole] = useState(role || 'shopper'); // Default to shopper

    useEffect(() => {
        if (role) {
            setCurrentRole(role);
        }
    }, [role]);

    return (
        <>
            {/* Render appropriate form based on currentRole (seller or shopper) */}
            {currentRole === 'seller' ? (
                <SellerRegistrationForm />
            ) : (
                <ShopperRegistrationForm />
            )}
        </>
    );
};

export default Register;