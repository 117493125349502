import React from "react";
import Footer from "../../components/footer/Footer";
import HeaderCopy from "../../components/header/HeaderCopy";


const About = () => {
    return (
        <>
            <HeaderCopy />
    
            <div id="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="blog-content">
                                <nav className="nav-breadcrumb" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">About</li>
                                    </ol>
                                </nav>
                                <h1 className="page-title">About</h1>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="page-text-content post-text-responsive">
                                            <p style={{ textAlign: 'center' }}>&nbsp;</p>
                                            {/*<p><img src="https://Vincee.com/uploads/blog/202404/img_661f49b5892fc3-25772666-41451246.png" alt=""></p>*/}
                                            <h1 className="MsoTitle" style={{textAlign: 'center'}}>
                                                <strong>
                                                    <span style={{fontFamily: 'Arial, sans-serif'}}>
                                                        <span style={{fontFamily: 'arial black, sans-serif'}}>Executive Summary</span>
                                                    </span>
                                                </strong>
                                            </h1>
                                            <p style={{textAlign: 'center'}}>
                                                <strong>
                                                    <span style={{fontFamily: 'Arial, sans-serif'}}>
                                                    <span style={{fontFamily: 'arial black, sans-serif'}}></span>
                                                    </span>
                                                </strong>
                                            </p>
                                            <p className="MsoNormal" style={{lineHeight: 2, textAlign: 'center'}}>
                                                <span style={{fontFamily: 'arial, helvetica, sans-serif'}}>
                                                    Vincee is an exciting start-up business that is registered as a C-corporation in Accra, Ghana. Vincee is an online store for multivendor of different brands. Vincee stands apart from the competition by offering a complete line of scopes from all of the popular brands known in all corners of the globe. Vincee is able to offer an extensive product selection through its arranged drop shipping agreement with an international distributor, eliminating their inventory overhead. Vincee was founded in Ghana as an e-commerce business which is aimed to create a swift barrier between Vendors and their customers without having Fraudulent issues.
                                                </span>
                                            </p>
                                            <p className="MsoNormal" style={{lineHeight: 2, textAlign: 'center'}}>&nbsp;</p>
                                            <div style={{border: '2px solid rgb(252, 252, 252)', background: 'rgb(250, 250, 250)', borderRadius: '8px', textAlign: 'center'}}></div>
                                            <div style={{border: '2px solid rgb(252, 252, 252)', background: 'rgb(250, 250, 250)', borderRadius: '8px', textAlign: 'center'}}>
                                                <span style={{fontSize: '24pt', fontFamily: 'helvetica, arial, sans-serif'}}></span>
                                                <span style={{fontSize: '24pt', fontFamily: 'helvetica, arial, sans-serif'}}><strong>About Us&nbsp;</strong></span>
                                                <p style={{textAlign: 'justify'}}>
                                                    <span style={{fontFamily: 'book antiqua, palatino, serif'}}>
                                                        Welcome to Vincee! We are a passionate team of enthusiasts dedicated to bringing you the latest trends and high-quality products from different vendors all accross the Globe.
                                                    </span>
                                                </p>
                                                <p style={{textAlign: 'justify'}}></p>
                                                <p style={{textAlign: 'center'}}>
                                                    <strong>
                                                    <span style={{fontSize: '24pt', fontFamily: 'helvetica, arial, sans-serif'}}>Our Mission</span>
                                                    </strong>
                                                </p>
                                                <p style={{textAlign: 'justify'}}>
                                                    <span style={{fontFamily: 'book antiqua, palatino, serif'}}>
                                                    At Vincee, our mission is to inspire and empower individuals to buy and sell their products with ease and free of fraudulent activties. We believe that the art of buying and selling should be easy and seamless. We also aim to bridge the difficulties vendors have in connecting with their customers and also the reduction of fraudulent activities in the buying and selling space.
                                                    </span>
                                                </p>
                                            </div>
                                            <p></p>
                                            <p style={{textAlign: 'center'}}>
                                                <span style={{fontSize: '24pt', fontFamily: 'helvetica, arial, sans-serif'}}><strong>Our Collections</strong></span>
                                            </p>
                                            <p style={{textAlign: 'center'}}>
                                                <span style={{fontFamily: 'book antiqua, palatino, serif'}}>
                                                    We carefully select each item in our collection to ensure that it meets our high standards of quality and affordability. Our team is constantly exploring the e-commerce industry to discover emerging products, brands, and trends. From classic essentials to on-trend statement pieces, we aim to offer a diverse range of products, accessories, and clothing to cater to various styles and preferences.
                                                </span>
                                            </p>
                                            <p style={{textAlign: 'center'}}></p>
                                            <p style={{textAlign: 'center'}}>
                                                <span style={{fontFamily: 'book antiqua, palatino, serif'}}>&nbsp;<span style={{fontFamily: 'helvetica, arial, sans-serif'}}><strong style={{fontSize: '24pt'}}>Sustainability and Ethical Practices&nbsp;</strong></span></span>
                                            </p>
                                            <p style={{textAlign: 'center'}}>
                                                <span style={{fontFamily: 'book antiqua, palatino, serif'}}>
                                                    We are committed to promoting sustainable and ethical practices within the e-commerce industry. We believe in responsible sourcing and work with suppliers who share our values of fair labor, environmental consciousness, and ethical manufacturing processes. We prioritize quality craftsmanship and materials to ensure that our products are durable and made to last.
                                                </span>
                                            </p>
                                            <p style={{textAlign: 'center'}}></p>
                                            <p style={{textAlign: 'center'}}>
                                                <span style={{fontSize: '24pt', fontFamily: 'book antiqua, palatino, serif'}}>&nbsp;<span style={{fontFamily: 'helvetica, arial, sans-serif'}}><strong style={{fontSize: '24pt'}}>Customer Satisfaction&nbsp;</strong></span></span>
                                            </p>
                                            <p style={{textAlign: 'center'}}>
                                                <span style={{fontFamily: 'book antiqua, palatino, serif'}}>
                                                    Customer satisfaction is at the heart of everything we do. We strive to provide an exceptional shopping experience for our customers. Our dedicated customer support team is here to assist you with any inquiries, concerns, or assistance you may need. We value your feedback and continually work to improve our products and services.
                                                </span>
                                            </p>
                                            <p style={{textAlign: 'center'}}></p>
                                            <p style={{textAlign: 'center'}}>
                                                <span style={{fontFamily: 'book antiqua, palatino, serif'}}>
                                                    <span style={{fontSize: '24pt'}}><strong>&nbsp;<span style={{fontFamily: 'helvetica, arial, sans-serif'}}>Stay Connected</span></strong></span>
                                                </span>
                                            </p>
                                            <p style={{textAlign: 'center'}}>
                                                <span style={{fontFamily: 'book antiqua, palatino, serif'}}>
                                                    We want to stay connected with you! Follow us on our social media channels to get inspired by our latest trends, tips, and exclusive promotions. Sign up for our newsletter to receive updates on new arrivals, special offers, and upcoming events.
                                                </span>
                                            </p>
                                            <p style={{textAlign: 'center'}}></p>
                                            <p style={{textAlign: 'center'}}>
                                                <span style={{fontSize: '24pt', fontFamily: 'helvetica, arial, sans-serif'}}>
                                                    <strong>&nbsp;Contact Us&nbsp;</strong>
                                                </span>
                                            </p>
                                            <p style={{textAlign: 'center' }}>
                                                <span style={{ fontFamily: 'book antiqua, palatino, serif' }}>
                                                    Have a question or need assistance? Our customer support team is ready to help. You can reach us through&nbsp; +233542059074 or on all social media pages as @Vincee. We value your feedback and appreciate your support as we continue to grow and evolve.
                                                </span>
                                            </p>
                                            <p style={{textAlign: 'center'}}>
                                                <span style={{fontFamily: 'book antiqua, palatino, serif'}}>
                                                    Thank you for visiting vincee. We look forward to being a part of your journey!
                                                </span>
                                            </p>
                                            <p style={{ textAlign: 'center'}}>
                                                <span style={{ fontFamily: 'book antiqua, palatino, serif' }}>
                                                    &nbsp;
                                                </span>
                                            </p>
                                            <p style={{ textAlign: 'center' }}>&nbsp;</p>                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};
      
export default About;