import React from 'react';

const HeartAnimation = ({ width = 29.20, height = 29.20, strokeColor = 'red', fillColor = 'red' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" style={{ verticalAlign: 'middle', marginRight: '5px' }}>
      <path
        className="heart-path"
        d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
        stroke={strokeColor}
        strokeWidth="2"
        fill="none"
      />
      <style>{`
        .heart-path {
          stroke-dasharray: 68;
          stroke-dashoffset: 68;
          animation: draw 2s linear forwards, fill 0.5s linear 2s forwards;
        }

        @keyframes draw {
          to {
            stroke-dashoffset: 0;
          }
        }

        @keyframes fill {
          to {
            fill: ${fillColor};
          }
        }
      `}</style>
    </svg>
  );
};

export default HeartAnimation;