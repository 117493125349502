import React from 'react';
import Icon from "../../components/icon/Icon";

const RegistrationFormFields = ({ 
    first_name,
    setFirstName,
    last_name,
    setLastName,                                                                                           
    location,
    setLocation,
    email,
    setEmail,
    contact,
    setContact,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    showPassword,
    setShowPassword,
    isCheckboxChecked,
    setIsCheckboxChecked,
    showIcon,
    setShowIcon,
    togglePasswordVisibility,
    handleSubmit,
    loading,
    formError,
    currentStep,
    setCurrentStep,
    errorMessage,
    inputValid,
    setInputValid,
    passwordError,
    setPasswordError
}) => {

    // Function to check if input is valid and determine button color
    const checkInputValidity = () => {
        // Check if both password and confirm password are equal and valid
        if (password === confirmPassword && password.length >= 6) {
            setInputValid(true); // Valid input
        } else {
            setInputValid(false); // Invalid input
        }
    };

    // Function to determine if the Next button is disabled
    const isNextButtonDisabled = () => {
        return !!passwordError; // Disable the button if there's a password error
    };

    // Function to validate the password
    const validatePassword = (password) => {
        const minLength = 8;
        const hasNumber = /\d/;
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

        // Check password length
        if (password.length < minLength) {
            return `Password must be at least ${minLength} characters long.`;
        }
        // Check if it contains at least one number
        if (!hasNumber.test(password)) {
            return 'Password must contain at least one number.';
        }
        // Check if it contains at least one special character
        if (!hasSpecialChar.test(password)) {
            return 'Password must contain at least one special character.';
        }
        return ''; // No errors, password is valid
    };

    // function to check password validity and show eye icon when user starts typing
    const handleChange = (e) => {
        const value = e.target.value;
        setPassword(value);
        setShowIcon(value.length > 0); // Show icon when user starts typing

        // Validate the password and set error message if invalid
        const validationError = validatePassword(value);
        setPasswordError(validationError); // Set the error message if any
    };

    // Handle password change and validity check
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        checkInputValidity();
    };

    // Handle confirm password change and validity check
    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        checkInputValidity();
    };

    // Function to handle checkbox changes
    const handleCheckboxChange = (e) => {
        setIsCheckboxChecked(e.target.checked); // Update the checkbox state
    };

    // Function to determine the final next button (submit)
    const getNextButtonClass = () => {
        if (!isCheckboxChecked || loading) {
            return 'c-form__next--disabled'; // Button is disabled if the checkbox is not checked or loading
        } else if (errorMessage) {
            return 'c-form__next--error'; // Button turns red when there's an error
        } else if (inputValid && isCheckboxChecked) {
            return 'c-form__next--valid'; // Button turns green when valid
        }
        return ''; // Default state
    };

    switch (currentStep) {
        case 1:
            return (
                <div className="c-form__group">
                    <label className="c-form__label" htmlFor="firstname">
                        <input
                            type="text"
                            id="firstname"
                            className="c-form__input"
                            placeholder=" "
                            pattern="^[a-zA-Z]+(?:[- ][a-zA-Z]+)*$"
                            required
                            value={first_name}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                        
                        {/* NEXT Button - Moves to the next step */}
                        <label
                            className="c-form__next"
                            role="button"
                            onClick={() => setCurrentStep(2)} // Moves to the next step
                        >
                            <span className="c-form__nextIcon"></span>
                        </label>
                            
                        <span className="c-form__groupLabel">Enter your first name.</span>
                        <b className="c-form__border"></b>
                    </label>
                </div>
            );
        
        case 2:
            return (
                <div className="c-form__group">
                    <label className="c-form__label" htmlFor="surname">
                        <input
                            type="text"
                            id="surname"
                            className="c-form__input"
                            placeholder=" "
                            pattern="^[a-zA-Z]+(?:[- ][a-zA-Z]+)*$"
                            required
                            value={last_name}
                            onChange={(e) => setLastName(e.target.value)}
                        />

                        {/* PREVIOUS Button - Moves back to the previous step */}
                        <label
                            className="c-form__prev"
                            role="button"
                            onClick={() => setCurrentStep(1)} // Moves to the previous step
                        >
                            <span className="c-form__prevIcon"></span>
                        </label>
    
                        {/* NEXT Button - Moves to the next step */}
                        <label
                            className="c-form__next"
                            role="button"
                            onClick={() => setCurrentStep(3)} // Moves to the next step
                        >
                            <span className="c-form__nextIcon"></span>
                        </label>
                        
                        <span className="c-form__groupLabel">Enter your surname.</span>
                        <b className="c-form__border"></b>
                    </label>
                </div>
            );
                
        case 3:
            return (
                <div className="c-form__group">
                    <label className="c-form__label" htmlFor="location">
                        <input
                            type="text"
                            id="location"
                            className="c-form__input"
                            placeholder=" "
                            pattern="^[a-zA-Z]+(?:[- ][a-zA-Z]+)*$" //"[^\s]+"
                            required
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                        />
                        
                        {/* PREVIOUS Button - Moves back to the previous step */}
                        <label
                            className="c-form__prev"
                            role="button"
                            onClick={() => setCurrentStep(2)} // Moves to the previous step
                        >
                            <span className="c-form__prevIcon"></span>
                        </label>
    
                        {/* NEXT Button - Moves to the next step */}
                        <label
                            className="c-form__next"
                            role="button"
                            onClick={() => setCurrentStep(4)} // Moves to the next step
                        >
                            <span className="c-form__nextIcon"></span>
                        </label>
                            
                        <span className="c-form__groupLabel">Where are you located?</span>
                        <b className="c-form__border"></b>
                    </label>
                </div>
            );

        case 4:
            return (
                <div className="c-form__group">
                    <label className="c-form__label" htmlFor="femail">
                        <input
                            type="email"
                            id="femail"
                            className="c-form__input"
                            placeholder=" "
                            pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                                    
                        {/* PREVIOUS Button - Moves back to the previous step */}
                        <label
                            className="c-form__prev"
                            role="button"
                            onClick={() => setCurrentStep(3)} // Moves to the previous step
                        >
                            <span className="c-form__prevIcon"></span>
                        </label>
    
                        {/* NEXT Button - Moves to the next step */}
                        <label
                            className="c-form__next"
                            role="button"
                            onClick={() => setCurrentStep(5)} // Moves to the next step
                        >
                            <span className="c-form__nextIcon"></span>
                        </label>
                        
                        <span className="c-form__groupLabel">What's your email?</span>
                        <b className="c-form__border"></b>
                    </label>
                </div>
            );

        case 5:
            return (
                <div className="c-form__group">
                    <label className="c-form__label" htmlFor="contact">
                        <input
                            type="tel"
                            id="contact"
                            className={`c-form__input ${formError ? 'is-invalid' : ''}`} //"c-form__input"
                            placeholder=" "
                            pattern="^0\d{9}$"
                            required
                            value={contact}
                            onChange={(e) => setContact(e.target.value)}
                        />
                        {formError && <p className="text-danger">{formError}</p>}

                        {/* PREVIOUS Button - Moves back to the previous step */}
                        <label
                            className="c-form__prev"
                            role="button"
                            onClick={() => setCurrentStep(4)} // Moves to the previous step
                        >
                            <span className="c-form__prevIcon"></span>
                        </label>
    
                        {/* NEXT Button - Moves to the next step */}
                        <label
                            className={`c-form__next ${isNextButtonDisabled() ? 'c-form__next--disabled' : ''}`} // Disable if password is invalid
                            role="button"
                            onClick={() => setCurrentStep(6)} // Moves to the next step
                        >
                            <span className="c-form__nextIcon"></span>
                        </label>

                        <span className="c-form__groupLabel">Enter your mobile number.</span>
                        <b className="c-form__border"></b>
                    </label>
                </div>
            );

        case 6:
            return (
                <div className="c-form__group">
                    <label className="c-form__label" htmlFor="fpass">
                        <input
                            type={showPassword ? "text" : "password"}
                            id="fpass"
                            className="c-form__input"
                            placeholder=" "
                            required
                            value={password}
                            onChange={handleChange}
                            style={{ paddingRight: '2em' }} // Adjust padding for icon space
                        />
                        {/* Conditionally render the eye icon if showIcon is true */}
                        {showIcon && (
                            <span 
                                onClick={togglePasswordVisibility} 
                                style={{ 
                                    cursor: 'pointer', 
                                    position: 'absolute',
                                    right: '10px', 
                                    top: '50%', 
                                    transform: 'translateY(-50%)',
                                }}
                            >
                                {showPassword ? <Icon name="VscEyeClosed" size="1.5em" /> : <Icon name="VscEye" size="1.5em" />}
                            </span>
                        )}
                        
                        {/* Show password validation errors */}
                        {passwordError && <p className="text-danger">{passwordError}</p>}

                        {/* PREVIOUS Button - Moves back to the previous step */}
                        <label
                            className="c-form__prev"
                            role="button"
                            onClick={() => setCurrentStep(5)} // Moves to the previous step
                        >
                            <span className="c-form__prevIcon"></span>
                        </label>
    
                        {/* NEXT Button - Moves to the next step */}
                        <label
                            className={`c-form__next ${isNextButtonDisabled() ? 'c-form__next--disabled' : ''}`} // Disable if password is invalid
                            role="button"
                            onClick={() => setCurrentStep(7)} // Moves to the next step
                        >
                            <span className="c-form__nextIcon"></span>
                        </label>

                        <span className="c-form__groupLabel">Create your password.</span>
                        <b class="c-form__border"></b>
                    </label>
                </div>
            );
            
        case 7:
            return (
                <div className="c-form__step-container">
                    {/* render error error messages */}
                    {errorMessage && (
                        <div className="c-form__error-container">
                            <div className="c-form__error-message" role="alert">
                                {errorMessage}
                            </div>
                        </div>
                    )}
                    <div className={`c-form__group ${errorMessage ? 'has-error' : ''}`}>
                        <label className="c-form__label" htmlFor="confirm-fpass">
                            <input
                                type="password"
                                id="confirm-fpass"
                                className="c-form__input"
                                placeholder=" "
                                required
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange} // Check confirm password validity
                            />
                            {password !== confirmPassword && (
                                <p className="text-danger password-mismatch">Password mismatch.</p>
                            )}

                            {/* PREVIOUS Button - Moves back to the previous step */}
                            <label
                                className="c-form__prev"
                                role="button"
                                onClick={() => setCurrentStep(6)} // Moves to the previous step
                            >
                                <span className="c-form__prevIcon"></span>
                            </label>

                            {/* NEXT Button - finish button */}
                            <label 
                                className={`c-form__next ${getNextButtonClass()}`} // Use dynamic button class
                                role="button"
                                onClick={handleSubmit}
                            >
                                {loading ? (
                                    <span className="c-form__nextIcon spinner"></span>
                                ) : (
                                    <span className="c-form__nextIcon"></span>
                                )}
                            </label>

                            <span className="c-form__groupLabel">Confirm your password.</span>
                            <b class="c-form__border"></b>
                        </label>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group stb m-t-5 m-b-15">
                            <div className="custom-control custom-checkbox custom-control-validate-input">
                                <input
                                    type="checkbox"
                                    name="terms"
                                    className="custom-control-input"
                                    id="checkbox_terms"
                                    required
                                    checked={isCheckboxChecked} // Ensure checkbox stays checked
                                    onChange={handleCheckboxChange}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="checkbox_terms">
                                    I have read and agree to the&nbsp;
                                    <a href="/terms" className="link-terms" target="_blank">
                                        <strong>Terms &amp; Conditions</strong>
                                    </a>
                                </label>
                            </div>
                        </div>
                    </div>   
                </div>
            );

        // Add other cases for more form steps...
        default:
            return <div>Form Step {currentStep} is not yet implemented.</div>;
    }
};

export default RegistrationFormFields;