import React, { useEffect, useState } from 'react';
import axios from 'axios';
import StarRating from '../rating/StarRating';
import AddReview from './AddReview';
import IconDetailsPage from "../icon/IconDetailsPage";

const Reviews = ({ productId }) => {
    const [reviews, setReviews] = useState([]);
    const [averageRating, setAverageRating] = useState(0);
    const userId = sessionStorage.getItem("user_id"); // Get user_id from sessionStorage

    const fetchReviews = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_Base_url}/products/get_reviews?productId=${productId}`);
            setReviews(res.data.reviews);
            setAverageRating(res.data.averageRating);
        } catch (error) {
            console.error('Error fetching reviews:', error);
        }
    };

    useEffect(() => {
        fetchReviews();
    }, [productId]);

    const handleReviewSubmitted = () => {
        fetchReviews(); // Refresh the reviews list after a new review is submitted
    };

  return (
        <>
            <div className="tab-pane fade" id="tab_comments_content" role="tabpanel">
                <div className="card">
                    <div className="card-header">
                        <a className="card-link collapsed" data-toggle="collapse" href="#collapse_comments_content">
                            Comments
                            <IconDetailsPage name="FaPlus" className="plus" style={{ color: '#777', float: 'left', fontSize: '18px', lineHeight: '1.1rem', marginRight: '5px' }} />
                            <IconDetailsPage name="FaMinus" className="minus" style={{ color: '#777', float: 'left', fontSize: '18px', lineHeight: '1.1rem', marginRight: '5px' }} />
                        </a>
                    </div>
                    <div id="collapse_comments_content" className="collapse-description-content collapse" data-parent="#accordion">
                        <input type="hidden" value="5" id="product_comment_limit" />
                        <div className="comments-container">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div id="comment-result">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="comments">
                                                    <div className="row-custom row-comment-label">
                                                        <label className="label-comment">Comments&nbsp;({reviews.length})</label>
                                                    </div>
                                                    {reviews.length === 0 ? (
                                                        <p className="no-comments-found">No comments found for this product. Be the first to comment!</p>
                                                    ) : (
                                                        reviews.map((review) => (
                                                            <div key={review.id} className="comment">
                                                                <div className="row-custom">
                                                                    <div className="comment-author">
                                                                        <strong>{review.name}</strong> - <small>{new Date(review.created_at).toLocaleDateString()}</small>
                                                                    </div>
                                                                    <div className="rating">
                                                                        <StarRating rating={review.rating} onRatingChange={() => {}} />
                                                                    </div>
                                                                </div>
                                                                <p>{review.comment}</p>
                                                            </div>
                                                        ))
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="col-comments-inner">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="row-custom row-comment-label">
                                                    <label className="label-comment">Add a comment</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <AddReview productId={productId} onReviewSubmitted={handleReviewSubmitted} shopperId={userId} />
                                                <div id="message-comment-result" className="message-comment-result"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Reviews;