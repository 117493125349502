import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { GridLoader } from "react-spinners";
import Icon from "../../components/icon/Icon";


const ShopSettings = ({ token }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState({
        company_name: '',
        location: '',
        business_category: '',
        shop_description: '',
    });

    // Check for JWT expiration and redirect to homepage
    const handleJwtExpired = () => {
        alert('Your session has expired. Please log in again.');
        sessionStorage.clear();
        navigate("/");
    };
  
   // Fetch the current shop settings based on sellerId
    useEffect(() => {
        const fetchShopDetails = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_Base_url}/sellers/get_shop_details`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });

                // Check if the response contains a JWT expiration message
                if (response.data && response.data.message === "jwt expired") {
                    handleJwtExpired();
                    return;
                }

                if (response.data.shop) {
                    // Update form data with the fetched user details
                    setFormData(response.data.shop);
                } else {
                    console.error('No shop data received');
                }
            } catch (error) {
                console.error('Error fetching shop settings:', error);
        
                // Check for JWT expiration in error response
                if (error.response?.status === 401 && error.response?.data?.message === "jwt expired") {
                    handleJwtExpired();
                } else if (error.response) {
                    // Handle other API errors
                    setErrorMessage(error.response.data.message || 'Error fetching shop settings');
                } else if (error.request) {
                    // Handle network errors
                    setErrorMessage('Network Error: Unable to reach the server. Please check your internet connection.');
                } else {
                    // Handle unexpected errors
                    setErrorMessage(error.message || 'An unexpected error occurred');
                }
          } finally {
            setIsLoading(false);
          }
        };

        fetchShopDetails();
    }, [token]);

    // Handle form field changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle form submission to update shop details
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage(''); // Reset error message
        setSuccessMessage(''); // Reset success message

        // Validate form fields
        const requiredFields = [
            { field: 'company_name', message: 'Company name is required' },
            { field: 'location', message: 'Shop location is required' },
            { field: 'shop_description', message: 'Shop description is required' },
            { field: 'business_category', message: 'Business category is required' }
        ];

        for (const { field, message } of requiredFields) {
            if (!formData[field]) {
                setErrorMessage(message);
                setLoading(false);
                return;
            }
        }

        try {
            // Send relevant shop fields to the backend
            const response = await axios.post(
            `${process.env.REACT_APP_Base_url}/sellers/update_shop`, 
            {
                company_name: formData.company_name,
                location: formData.location,
                shop_description: formData.shop_description,
                business_category: formData.business_category,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            // Check if the response contains a JWT expiration message
            if (response.data && response.data.message === "jwt expired") {
                handleJwtExpired();
                return;
            }

            if (response.status === 200) {
                setSuccessMessage('Shop settings updated successfully!');
            } 
        } catch (error) {
            console.error('Error updating shop settings:', error);

            // Check for JWT expiration in error response
            if (error.response?.status === 401 && error.response?.data?.message === "jwt expired") {
                    handleJwtExpired();
            } else if (error.response) {
                    // Handle other API errors
                    setErrorMessage(`Error updating shop settings: ${error.response.data.message || error.message}`);
            } else if (error.request) {
                    // Handle network errors
                    setErrorMessage('Network Error: Unable to reach the server. Please check your internet connection.');
            } else {
                    // Handle unexpected errors
                    setErrorMessage(`An unexpected error occurred: ${error.message}`);
            }
        } finally {
            setLoading(false);
        }
    };

    if (isLoading) {
        return (
            <div style={loaderContainerStyle}>
                <GridLoader color="var(--vr-color-main)" loading={isLoading} />
            </div>
        );
    }

    return (
        <>
            {/* Conditionally render error/success messages */}
            {errorMessage && (
                <div className="row">
                    <div className="col-sm-12">
                        <div className="m-b-15">
                            <div className="alert alert-danger alert-dismissable">
                                <button aria-hidden="true" data-dismiss="alert" className="close" type="button">×</button>
                                <h4>
                                    <Icon name="MdErrorOutline" />
                                    {errorMessage}          
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {successMessage && (
                <div className="row">
                    <div className="col-sm-12">
                        <div className="m-b-15">
                            <div className="alert alert-success alert-dismissable">
                                <button aria-hidden="true" data-dismiss="alert" className="close" type="button">×</button>
                                <h4>
                                    <Icon name="FaCheck" />
                                    {successMessage}          
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* Update Shop information start */}
            <div className="row">
                <div className="col-sm-7">
                    <div className="box">
                        <div className="box-header with-border">
                            <div className="left">
                                <h3 className="box-title">Shop Settings</h3>
                            </div>
                        </div>
                        <div className="box-body">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label className="control-label">Shop Name</label>
                                    <input
                                        type="text"
                                        name="company_name"
                                        className="form-control form-input"
                                        value={formData.company_name}
                                        onChange={handleChange}
                                        placeholder="Shop Name"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="control-label">Business Category</label>
                                    <input
                                        type="text"
                                        name="business_category"
                                        className="form-control form-input"
                                        value={formData.business_category}
                                        onChange={handleChange}
                                        placeholder="Business Category"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="control-label">Shop Description</label>
                                    <textarea
                                        name="shop_description"
                                        className="form-control form-textarea"
                                        value={formData.shop_description}
                                        onChange={handleChange}
                                        placeholder="Shop Description"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="control-label">Shop Location</label>
                                    <input
                                        type="text"
                                        name="location"
                                        className="form-control form-input"
                                        value={formData.location}
                                        onChange={handleChange}
                                        placeholder="Shop Location"
                                        required
                                    />
                                </div> 
                                <div className="form-group">
                                    <div id="map-result">
                                        <div className="map-container">
                                            <iframe src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=Greater Accra, Ghana&amp;ie=UTF8&amp;t=&amp;z=8&amp;iwloc=B&amp;output=embed&amp;disableDefaultUI=true" id="IframeMap" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group text-right">
                                    <button 
                                        type="submit"
                                        className="btn btn-md btn-success"
                                        disabled={loading}
                                    >
                                        {loading ? 'Saving...' : 'Save Changes'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>                              
            {/* Update Shop's information end */}
        
        </>
    );
};

const loaderContainerStyle = {
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    height: '50vh', // Full viewport height
};

export default ShopSettings;