import "./register.scss";
import react, { useState, useEffect } from "react";
import axios from "axios";
import HeaderCopy from "../../components/header/HeaderCopy";
import MobileResponsiveRegister from "../../components/mobile responsive register/MobileResponsiveRegister";
import SellerRegistrationFormFields from './SellerRegistrationFormFields';

const TOTAL_STEPS = 11;

const Register = () => {
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [location, setLocation] = useState("");
    const [email, setEmail] = useState("");
    const [company_name, setCompanyName] = useState("");
    const [shop_description, setShopDescription] = useState("");
    const [contact, setContact] = useState("");
    const [business_category, setBusinessCategory] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [inputValid, setInputValid] = useState(false); // Track input validity
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [currentStep, setCurrentStep] = useState(1); // Step 1 as default
    const [passwordError, setPasswordError] = useState(''); // Track password validation errors
    const [formError, setFormError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showSignUp, setShowSignUp] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');
    const [resendEmailLoading, setResendEmailLoading] = useState(false);
    const [resendEmailSuccess, setResendEmailSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showIcon, setShowIcon] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [submissionSuccess, setSubmissionSuccess] = useState(false); 

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Move to the next step
    const nextStep = () => {
        if (currentStep < TOTAL_STEPS) setCurrentStep((prev) => prev + 1);
    };

    // Move to the previous step
    const prevStep = () => {
        if (currentStep > 1) setCurrentStep((prev) => prev - 1);
    };

    // hides the "or signup with" on the start of the registration page
    const handleStartHereClick = () => {
        setShowSignUp(false); // Hide the sign-up section when "Start Here" is clicked
    };

    // function to validate contact
    const validateContact = (contact) => {
        return contact.length === 10 && /^[0-9]+$/.test(contact); // Basic validation for contact number
    };

    // resend activation email
    const sendActivationEmail = async (email) => {
        setResendEmailLoading(true);
        setResendEmailSuccess(false); // Reset success state
    
        try {
            const res = await axios.post(`${process.env.REACT_APP_Base_url}/sellers/resend_activation`, {
                email,
            });
    
            if (res.status === 200) {
                setResendEmailSuccess(true); // Display success message
            } else {
                setErrorMessage('Failed to resend activation email. Please try again later.');
            }
        } catch (err) {
            setErrorMessage(err.response?.data?.message || 'Failed to resend activation email.');
        } finally {
            setResendEmailLoading(false); // Stop loading spinner
        }
    };

    // handles submitting of registration forms
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage(''); // Clear previous error message
        setSuccessMessage(''); // Clear previous success message


        // Handle potential errors in user input
        if (!validateContact(contact)) {
            setLoading(false);
            setErrorMessage("Please enter a valid 10-digit contact number.");
            return;
        }

        if (!isCheckboxChecked) {
            setLoading(false);
            setErrorMessage("Please accept the terms and conditions to proceed.");
            return;
        }

        if (password !== confirmPassword) {
            setLoading(false);
            setErrorMessage("Please confirm your password correctly.");
            return;
        } 
        
        // Make the API call to submit the registration form
        axios
            .post(`${process.env.REACT_APP_Base_url}/sellers/register`, {
                first_name,
                last_name,
                email,
                password,
                company_name,
                location,
                contact,
                business_category,
                shop_description,
            })
            .then((res) => {
                setLoading(false);

                if (res.data.status === 201) {
                    setSubmissionSuccess(true);
                
                // Inform the user to check their email for activation instructions
                setSuccessMessage(res.data.message);
                } else {
                    setErrorMessage(res.data.message || "An unexpected error occurred.");
                }
            })
            .catch((err) => {
            
                setLoading(false);
            
            if (err.response && err.response.status === 400) {
                const errorMessage = err.response.data.message;
                
                // Check for specific error messages from the backend
                setErrorMessage(errorMessage);
            } else {
                  setErrorMessage("An error occurred. Please try again later.");
            }
        });
    };

    return (
        <>
            <HeaderCopy />

            {successMessage && ( 
                <div className={`c-register ${resendEmailSuccess ? 'resendEmailSuccess' : ''}`}>
                    <div className={`auth-box ${resendEmailSuccess ? 'resendEmailSuccess' : ''}`}>
                        <div className="row">
                            <div className="col-12">
                                <h2 className="title text-success">Your account has been created successfully!</h2>
                                <p className="text-center" style={{ fontSize: '15px' }}>
                                    {successMessage}. Please confirm your account.
                                </p>
                                <div className="form-group m-t-15">
                                    <button
                                        type="button"
                                        className={`btn btn-custom btn-block ${resendEmailLoading ? 'disabled' : ''}`}
                                        onClick={() => sendActivationEmail(email)}
                                    >
                                        {resendEmailLoading ? 'Sending...' : 'Resend Activation Email'}
                                    </button>
                                </div>
                                {resendEmailSuccess && (
                                <div id="confirmation-result-register">
                                    <div className="text-success text-center m-b-15">
                                        Activation email has been sent!
                                    </div>
                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}                     
            
            <section className={`vh-75 d-flex justify-content-center align-items-center" ${submissionSuccess ? 'submission-success' : ''}`}>
                <div className="home-center">
                    <div className="home-desc-center">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="register-seller-container">
                                    <input className="c-checkbox" type="checkbox" id="start" />
                                    {/* Progress Bar */}
                                    <MobileResponsiveRegister 
                                        submissionSuccess={submissionSuccess}
                                        currentStep={currentStep}
                                        TOTAL_STEPS={TOTAL_STEPS}
                                    />
                                        
                                    <div className="c-formContainer">
                                        {/* Show welcome message if registration is successful */}
                                        <div className={`c-welcome ${submissionSuccess ? 'submission-success' : ''}`}> Welcome, {first_name}!</div>
                                                                                                                               
                                        <form className={`c-form ${submissionSuccess ? 'submission-success' : ''}`} onSubmit={handleSubmit}>
                                            {/* Render the current form based on the index / Pass props to the FormStep component */}
                                            <SellerRegistrationFormFields
                                                first_name={first_name}
                                                setFirstName={setFirstName}
                                                last_name={last_name}
                                                setLastName={setLastName}                                                                                           
                                                location={location}
                                                setLocation={setLocation}
                                                email={email}
                                                setEmail={setEmail}
                                                company_name={company_name}
                                                setCompanyName={setCompanyName}
                                                shop_description={shop_description}
                                                setShopDescription={setShopDescription}
                                                contact={contact}
                                                setContact={setContact}
                                                business_category={business_category}
                                                setBusinessCategory={setBusinessCategory}
                                                password={password}
                                                setPassword={setPassword}
                                                confirmPassword={confirmPassword}
                                                setConfirmPassword={setConfirmPassword}
                                                showPassword={showPassword}
                                                setShowPassword={setShowPassword}
                                                isCheckboxChecked={isCheckboxChecked}
                                                setIsCheckboxChecked={setIsCheckboxChecked}
                                                currentStep={currentStep}
                                                setCurrentStep={setCurrentStep}
                                                showIcon={showIcon}
                                                setShowIcon={setShowIcon}
                                                togglePasswordVisibility={togglePasswordVisibility}
                                                handleSubmit={handleSubmit}
                                                loading={loading}
                                                formError={formError}
                                                setFormError={setFormError}
                                                errorMessage={errorMessage}
                                                inputValid={inputValid}
                                                setInputValid={setInputValid}
                                                passwordError={passwordError}
                                                setPasswordError={setPasswordError}
                                            />

                                                
                                            <label className="c-form__toggle" htmlFor="start" onClick={handleStartHereClick}>
                                                Start Here
                                                <span className="c-form__toggleIcon"></span>
                                            </label>

                                            {showSignUp && (  // Render the sign-up section only if showSignUp is true
                                                <div className="mt-4 text-center">
                                                    <h6>Or</h6>
                                                    <ul className="list-unstyled social-icon mb-0 mt-3">
                                                        <a href="/connect-with-facebook" class="btn btn-social btn-social-facebook">
                                                            <svg width="24" height="24" viewBox="0 0 14222 14222">
                                                                <circle cx="7111" cy="7112" r="7111" fill="#ffffff"></circle>
                                                                <path d="M9879 9168l315-2056H8222V5778c0-562 275-1111 1159-1111h897V2917s-814-139-1592-139c-1624 0-2686 984-2686 2767v1567H4194v2056h1806v4969c362 57 733 86 1111 86s749-30 1111-86V9168z" fill="#1877f2"></path>
                                                            </svg>
                                                            <span>Connect with Facebook</span>
                                                        </a>
                                                        <a href="/connect-with-google" className="btn btn-social btn-social-google">
                                                            <svg width="24" height="24" viewBox="0 0 128 128">
                                                                <rect clip-rule="evenodd" fill="none" fill-rule="evenodd" height="128" width="128"></rect>
                                                                <path clip-rule="evenodd" d="M27.585,64c0-4.157,0.69-8.143,1.923-11.881L7.938,35.648    C3.734,44.183,1.366,53.801,1.366,64c0,10.191,2.366,19.802,6.563,28.332l21.558-16.503C28.266,72.108,27.585,68.137,27.585,64" fill="#FBBC05" fill-rule="evenodd"></path>
                                                                <path clip-rule="evenodd" d="M65.457,26.182c9.031,0,17.188,3.2,23.597,8.436L107.698,16    C96.337,6.109,81.771,0,65.457,0C40.129,0,18.361,14.484,7.938,35.648l21.569,16.471C34.477,37.033,48.644,26.182,65.457,26.182" fill="#EA4335" fill-rule="evenodd"></path>
                                                                <path clip-rule="evenodd" d="M65.457,101.818c-16.812,0-30.979-10.851-35.949-25.937    L7.938,92.349C18.361,113.516,40.129,128,65.457,128c15.632,0,30.557-5.551,41.758-15.951L86.741,96.221    C80.964,99.86,73.689,101.818,65.457,101.818" fill="#34A853" fill-rule="evenodd"></path>
                                                                <path clip-rule="evenodd" d="M126.634,64c0-3.782-0.583-7.855-1.457-11.636H65.457v24.727    h34.376c-1.719,8.431-6.397,14.912-13.092,19.13l20.474,15.828C118.981,101.129,126.634,84.861,126.634,64" fill="#4285F4" fill-rule="evenodd"></path>
                                                            </svg>
                                                            <span>Connect with Google</span>
                                                        </a>
                                                    </ul>
                                                </div>
                                            )}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Register;