import React from "react";

const Following = () => {
    return (
        <>
            <div className="row row-follower">
                <div className="col-12">
                    <p className="text-center text-muted">No records found!</p>
                </div>
                <div className="col-3 col-sm-2">
                    <div className="follower-item">
                        <a href="#">
                            <img src="" alt="" className="img-fluid img-profile ls-is-cached lazyloaded" />
                            <span className="username"></span>
                        </a>
                    </div>
                </div>
            </div>
            <div className="row-custom"></div>
        </>
    );
};

export default Following;